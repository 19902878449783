import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

export default function Graph(props) {
    const chart = useRef(null);
    const [id, setId] = useState(uuid());


    const [height, setHeight] = useState(400);

    // useEffect(() => {
    //     let graph = props.applyStyle(chart, id, setHeight);

    //     return () => {
    //         graph.dispose();
    //     };
    // }, [])
    useLayoutEffect(() => {
        let graph = props.applyStyle(chart, id, setHeight);

        return () => {
            graph.dispose();
        };
    }, [props.data, props.graphRefresh]);

    return (
        <div id={id} style={{ height: height }} ></div>
    );

}


