import React from "react";
import Graph from "./Graph";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";


export default function LineGraph(prop) {

    // chart and ID are both coming from the graph.js
    const volumeGraphStyle = (chart, id) => {
        let graph = am4core.create(id, am4charts.XYChart);
        am4core.options.autoSetClassName = true;
        graph.responsive.enabled = true;
        // Enable chart cursor
        graph.cursor = new am4charts.XYCursor();
        graph.cursor.lineX.disabled = true;
        graph.cursor.lineY.disabled = true;
        graph.cursor.behavior = "none"

        // Enable scrollbar
        // volumeLine.scrollbarX = new am4core.Scrollbar();

        // Add data
        graph.data = prop.data;
        // Create axes
        const data = prop.data;
        graph.maskBullets = false;



        /* Create axes */
        var categoryAxis = graph.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Timeline";
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.strokeWidth = 0;
        categoryAxis.id = "myCategoryAxis";

        /* Create value axis */
        var valueAxis = graph.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeWidth = 1;

        let valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#1b1a27");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.fontSize = 16;
        valueAxisTooltip.dy = 5;
        valueAxis.cursorTooltipEnabled = true;
        valueAxis.id = "myValueAxis";

        let categoryAxisTooltip = categoryAxis.tooltip;
        categoryAxisTooltip.background.fill = am4core.color("#1b1a27");
        categoryAxisTooltip.background.strokeWidth = 0;
        categoryAxisTooltip.background.cornerRadius = 3;
        categoryAxisTooltip.background.pointerLength = 0;
        categoryAxisTooltip.fontSize = 16;
        categoryAxisTooltip.dy = 5;



        var forecastSeries = graph.series.push(new am4charts.LineSeries());
        forecastSeries.name = "Forecast";
        forecastSeries.dataFields.valueY = "forecast";
        forecastSeries.dataFields.categoryX = "Timeline";
        forecastSeries.stroke = am4core.color("#ffd300");
        forecastSeries.strokeWidth = 3;
        forecastSeries.strokeDasharray = "3,3";
        forecastSeries.propertyFields.strokeDasharray = "lineDash";
        forecastSeries.fill = am4core.color("#fff");
        forecastSeries.tooltipText = `[#fff font-size: 15px]{name}\n[/] [#fff font-size: 15px]${prop.timeUnit}{categoryX}:\n[/][#fff font-size: 20px] {valueY} ${prop.units} \n[/][#fff font-size: 15px]Cumulative`;
        forecastSeries.tooltipX = am4core.percent(50);
        forecastSeries.tooltipY = am4core.percent(50);
        forecastSeries.tooltip.background.cornerRadius = 5;
        forecastSeries.tooltip.getFillFromObject = false
        forecastSeries.tooltip.background.fill = am4core.color("#ffd300")
        forecastSeries.tooltip.background.strokeOpacity = 0;
        // plannedSeries.tooltip.pointerOrientation = "left";
        forecastSeries.tooltip.label.minWidth = 20;
        forecastSeries.tooltip.label.minHeight = 20;
        forecastSeries.tooltip.label.textAlign = "middle";
        forecastSeries.tooltip.label.TextValign = "middle";
        forecastSeries.tooltip.label.paddingLeft = "10"
        forecastSeries.tooltip.label.paddingRight = "10"
        forecastSeries.tooltip.label.paddingTop = "10"
        forecastSeries.tooltip.label.paddingBottom = "10"
        // plannedSeries.tooltip.ignoreBounds = true
        forecastSeries.tooltip.dx = 25;

        var bulletforecast = forecastSeries.bullets.push(new am4charts.CircleBullet());
        bulletforecast.circle.strokeWidth = 2;
        bulletforecast.circle.radius = 3;
        bulletforecast.circle.fill = am4core.color("#fff");
        var bulletforecasthover = bulletforecast.states.create("hover");
        bulletforecasthover.properties.scale = 1.3;








        var plannedSeries = graph.series.push(new am4charts.LineSeries());
        plannedSeries.name = "Planned";
        plannedSeries.dataFields.valueY = "planned";
        plannedSeries.dataFields.categoryX = "Timeline";
        plannedSeries.stroke = am4core.color("#6b66da");
        plannedSeries.strokeWidth = 3;
        plannedSeries.propertyFields.strokeDasharray = "lineDash";
        plannedSeries.fill = am4core.color("#6b66da");
        plannedSeries.tooltipText = `[#fff font-size: 15px]{name}\n[/] [#fff font-size: 15px]${prop.timeUnit}{categoryX}:\n[/][#fff font-size: 20px] {valueY} ${prop.units} \n[/][#fff font-size: 15px]Cumulative`;
        plannedSeries.tooltipX = am4core.percent(50);
        plannedSeries.tooltipY = am4core.percent(50);
        plannedSeries.tooltip.background.cornerRadius = 5;
        plannedSeries.tooltip.getFillFromObject = false
        plannedSeries.tooltip.background.fill = am4core.color("#6b66da")
        plannedSeries.tooltip.background.strokeOpacity = 0;
        // plannedSeries.tooltip.pointerOrientation = "left";
        plannedSeries.tooltip.label.minWidth = 20;
        plannedSeries.tooltip.label.minHeight = 20;
        plannedSeries.tooltip.label.textAlign = "middle";
        plannedSeries.tooltip.label.TextValign = "middle";
        plannedSeries.tooltip.label.paddingLeft = "10"
        plannedSeries.tooltip.label.paddingRight = "10"
        plannedSeries.tooltip.label.paddingTop = "10"
        plannedSeries.tooltip.label.paddingBottom = "10"
        // plannedSeries.tooltip.ignoreBounds = true
        plannedSeries.tooltip.dx = 25;

        var bulletPlanned = plannedSeries.bullets.push(new am4charts.CircleBullet());
        bulletPlanned.circle.strokeWidth = 2;
        bulletPlanned.circle.radius = 4;
        bulletPlanned.circle.fill = am4core.color("#fff");
        var bullethover = bulletPlanned.states.create("hover");
        bullethover.properties.scale = 1.3;

















        var actualSeries = graph.series.push(new am4charts.LineSeries());
        actualSeries.name = "Actual";
        actualSeries.dataFields.valueY = "actual";
        actualSeries.dataFields.categoryX = "Timeline";
        actualSeries.stroke = am4core.color("#ff4c29");
        actualSeries.strokeWidth = 4;
        actualSeries.propertyFields.strokeDasharray = "lineDash";
        actualSeries.tooltip.label.textAlign = "middle";

        actualSeries.tooltipText = `[#fff font-size: 15px]{name}\n[/] [#fff font-size: 15px]${prop.timeUnit}{categoryX}:\n[/][#fff font-size: 20px] {valueY} ${prop.units} \n[/][#fff font-size: 15px]Cumulative`;
        actualSeries.tooltipX = am4core.percent(50);
        actualSeries.tooltipY = am4core.percent(50);
        actualSeries.tooltip.background.cornerRadius = 5;
        actualSeries.tooltip.getFillFromObject = false
        actualSeries.tooltip.background.fill = am4core.color("#ff4c29")
        actualSeries.tooltip.background.strokeOpacity = 0;
        // actualSeries.tooltip.pointerOrientation = "right";
        actualSeries.tooltip.label.minWidth = 20;
        actualSeries.tooltip.label.minHeight = 20;
        actualSeries.tooltip.label.textAlign = "middle";
        actualSeries.tooltip.label.TextValign = "middle";
        actualSeries.tooltip.label.paddingLeft = "10"
        actualSeries.tooltip.label.paddingRight = "10"
        actualSeries.tooltip.label.paddingTop = "10"
        actualSeries.tooltip.label.paddingBottom = "10"
        actualSeries.tooltip.dx = -25;
        // actualSeries.tooltip.ignoreBounds = true

        // Shade under line
        actualSeries.fill = am4core.color("#ff4c29");
        actualSeries.fillOpacity = 0.15;


        let bullet4 = actualSeries.bullets.push(new am4charts.CircleBullet());
        bullet4.fill = am4core.color("#98E000");
        bullet4.circle.radius = 4.5;
        bullet4.circle.fill = am4core.color("#fff");
        bullet4.circle.strokeWidth = 2;


        let bullet3 = actualSeries.bullets.push(new am4charts.CircleBullet());

        var actcircle = bullet3.createChild(am4core.Circle);
        actcircle.radius = 9;
        actcircle.fill = am4core.color("#fff");
        actcircle.strokeWidth = 3;


        bullet3.fill = am4core.color("#98E000");
        bullet3.circle.radius = 10;
        bullet3.circle.fill = am4core.color("#fff");
        bullet3.circle.strokeWidth = 3;


        bullet3.propertyFields.disabled = "disabled";
        bullet3.propertyFields.showTooltipOn = "showTooltip";

        var bulletActual = actualSeries.bullets.push(new am4charts.CircleBullet());
        bulletActual.circle.strokeWidth = 2;
        bulletActual.circle.radius = 5;
        bulletActual.circle.fill = am4core.color("#fff");
        bulletActual.propertyFields.disabled = "disabled";
        bulletActual.propertyFields.showTooltipOn = "showTooltip";

        // bullet3.tooltipText = "[#fff font-size: 15px]Latest Milestone Achieved \n [/][#fff font-size: 20px font-weight: 700]{Title}"

        bullet3.events.on("inited", function (event) {
            animateBullet3(event.target.circle);
        })

        function animateBullet3(bullet3) {
            var animation = bullet3.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
            animation.events.on("animationended", function (event) {
                animateBullet3(event.target.object);
            })
        }








        //add legend
        // graph.legend = new am4charts.Legend();
        // plannedSeries.legendSettings.labelText = "[font-size: 16px]Planned";
        // actualSeries.legendSettings.labelText = "[font-size: 16px]Actual";


        chart.current = graph;
        return graph;
    }

    return (
        <Graph applyStyle={volumeGraphStyle} graphStyle={prop.graphStyle} graphRefresh={prop.graphRefresh} graphRefresh2={prop.graphRefresh2} />
    )
}