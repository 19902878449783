import React from 'react'

const Info = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Fill">
                <path d="m13.9997,24.648c-5.8711,0-10.6475-4.7769-10.6475-10.6479S8.1286,3.3522,13.9997,3.3522s10.6484,4.7769,10.6484,10.6479-4.7773,10.6479-10.6484,10.6479Zm0-20.5459c-5.457,0-9.8975,4.4404-9.8975,9.8979s4.4404,9.8979,9.8975,9.8979,9.8984-4.4404,9.8984-9.8979-4.4404-9.8979-9.8984-9.8979Z" fill="#ff4c29" />
            </g>
            <g id="Content">
                <g>
                    <path d="m13.9997,10.9835c-.9766,0-1.7705-.7944-1.7705-1.771s.7939-1.771,1.7705-1.771,1.7715.7944,1.7715,1.771-.7949,1.771-1.7715,1.771Zm0-3.042c-.7002,0-1.2705.5703-1.2705,1.271s.5703,1.271,1.2705,1.271c.7012,0,1.2715-.5703,1.2715-1.271s-.5703-1.271-1.2715-1.271Z" fill="#ff4c29" />
                    <path d="m15.069,20.5582h-2.1377c-.1377,0-.25-.1118-.25-.25v-7.9517c0-.1382.1123-.25.25-.25h2.1377c.1377,0,.25.1118.25.25v7.9517c0,.1382-.1123.25-.25.25Zm-1.8877-.5h1.6377v-7.4517h-1.6377v7.4517Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Info