import React from 'react'

const Kpis = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m18.5,6h-9c-.2764,0-.5-.2236-.5-.5v-1c0-1.3784,1.1211-2.5,2.5-2.5h5c1.3789,0,2.5,1.1216,2.5,2.5v1c0,.2764-.2236.5-.5.5Zm-8.5-1h8v-.5c0-.8271-.6729-1.5-1.5-1.5h-5c-.8271,0-1.5.6729-1.5,1.5v.5Z" fill="#ff4c29" />
                    <path d="m22.5,26H5.5c-.2764,0-.5-.2236-.5-.5V4.5c0-.2764.2236-.5.5-.5h2c.2764,0,.5.2236.5.5s-.2236.5-.5.5h-1.5v20h16V5h-1.5c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h2c.2764,0,.5.2236.5.5v21c0,.2764-.2236.5-.5.5Z" fill="#ff4c29" />
                    <path d="m9.5,14c-.1279,0-.2559-.0488-.3535-.1465l-2-2c-.1953-.1953-.1953-.5117,0-.707s.5117-.1953.707,0l1.6465,1.6465,2.6465-2.6465c.1953-.1953.5117-.1953.707,0s.1953.5117,0,.707l-3,3c-.0977.0977-.2256.1465-.3535.1465Z" fill="#ff4c29" />
                    <path d="m19.5,13h-4c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h4c.2764,0,.5.2236.5.5s-.2236.5-.5.5Z" fill="#ff4c29" />
                    <path d="m9.5,20c-.1279,0-.2559-.0488-.3535-.1465l-2-2c-.1953-.1953-.1953-.5117,0-.707s.5117-.1953.707,0l1.6465,1.6465,2.6465-2.6465c.1953-.1953.5117-.1953.707,0s.1953.5117,0,.707l-3,3c-.0977.0977-.2256.1465-.3535.1465Z" fill="#ff4c29" />
                    <path d="m19.5,19h-4c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h4c.2764,0,.5.2236.5.5s-.2236.5-.5.5Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Kpis