import React, { useContext } from 'react'

import { FirebaseDataContext } from '../../Context/FirebaseDataContext'
import moment from 'moment'

import { Link } from 'react-router-dom';
import Edit from '../../assets/BtnIcon/BI-_Btn-Edit.svg'

const UpdateStatus = () => {

    const data = useContext(FirebaseDataContext)

    const { projectData } = data

    function updateStatusList() {
        let list = [];
        projectData.forEach((project, index) => {
            if (moment(project["Commissioning Date"]).isSameOrBefore(moment()) && project["Status"] === "Outstanding") {
                list.push(project)
            }
        })
        return list.sort((a, b) => moment(b["Commissioning Date"]) - moment(a["Commissioning Date"]))
    }

    const updateList = updateStatusList(projectData)

    return (
        <div className='statusIssue-main-container '>

            {updateList.length > 0 ? <>

                {updateList.map((issue, index) => {
                    return (
                        <div className='statusIssue-issue-container' key={index + "feedid"}>
                            {issue.OPNumber ? <div className='statusIssue-date-box status-date'>{issue.OPNumber}</div> : <></>}
                            <div className='statusIssue-title-box issue-detail-container'>
                                <div className=' status-text-header-colored  '>{issue.projectName
                                }  </div>
                                <div className=' issue-text-details summary-text-fs1 '>Commission - P{issue["Commissioning Period"]} {issue["Commissioning Year"]}</div>
                                <div className=' issue-text-details summary-text-fs1 '>Planned Commissioning Date in the past but set as Not started </div>
                            </div>


                            <Link to={`/app/main/projectpage/${issue.id}`} className="statusIssue-edit-box">
                                <img className=' statusIssue-edit-image' src={Edit} alt="" />
                            </Link>


                        </div>
                    )
                })}
            </>
                :
                <>
                    <div className='no-issue-text-header-center summary-text-fs1p3'>
                        All Projects are up to date
                    </div></>

            }

        </div>
    )
}

export default UpdateStatus