import React from 'react'

import CompleteIcon from "../../assets/graphIcons/MileStoneCompleteCircle.svg"

import ReforecastArrow from "../../assets/graphIcons/ReforecastArrow.svg"

import Blank from "../../assets/graphIcons/Blank.svg"


const ProjectList = ({ selectedPeriodData }) => {


    selectedPeriodData.sort((a, b) => b["workType"] > a["workType"] ? -1 : 1)


    const statusIconDisplay = (comment) => {

        switch (comment) {
            case "Actual":
                return CompleteIcon
                break;
            case "Forecast":
                return ReforecastArrow
                break;
            case "Planned":
                return Blank
                break;
            default:
                break;
        }

    }



    return (
        <div className='summ-top-summary-details-container'>
            <div className='summ-top-summary-details'>
                {selectedPeriodData.map((item, index) => (
                    <div className='details-row' key={index} >
                        <div className="detail-row-title-container">
                            <img src={item.bullet} alt="bullet" className='list-bullet' />
                            <div className="summary-text-fs1 proj-title-col">{item.projectName} </div>
                        </div>

                        <div className="summary-text-fs1 proj-worktype-col">{item.workType}</div>
                        <div className='detail-icon-container'>

                            <img src={statusIconDisplay(item["dateStatusComment"])} alt="" className='comp-icon-table' />


                        </div>


                    </div>

                ))}
            </div>
        </div>
    )
}

export default ProjectList