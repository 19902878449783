import React from 'react'

const Actions = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m15.9761,25.2981h-3.9518v-2.9937c-.8874-.2091-1.727-.5558-2.5015-1.0337l-2.1151,2.1151-2.7941-2.7941,2.1151-2.1151c-.4787-.776-.8253-1.6141-1.0337-2.5007h-2.9929v-3.9518h2.9929c.2083-.8866.555-1.7254,1.0337-2.5007l-2.1151-2.1151,2.7941-2.7949,2.1151,2.1158c.7745-.4779,1.6125-.8246,2.5015-1.0345v-2.9929h3.9518v2.9929c.8874.2099,1.7254.5566,2.4999,1.0345l2.1151-2.1158,2.7941,2.7949-2.1151,2.1151c.4771.7721.8238,1.6109,1.0353,2.5007h2.9929v3.9518h-2.9929c-.2115.889-.5582,1.7278-1.0353,2.5007l2.1151,2.1151-2.7941,2.7941-2.1151-2.1151c-.7745.4779-1.6125.8246-2.4999,1.0337v2.9937Zm-3.1376-.8142h2.3234v-2.8434l.3292-.0636c1.013-.198,1.9592-.59,2.8132-1.1641l.2783-.1869,2.0085,2.0085,1.6427-1.6427-2.0085-2.0085.1877-.2783c.5725-.8516.9653-1.7986,1.1641-2.814l.0636-.3292h2.8434v-2.3234h-2.8434l-.0636-.3292c-.1988-1.0162-.5916-1.9632-1.1641-2.814l-.1877-.2783,2.0085-2.0085-1.6427-1.6435-2.0085,2.0093-.2783-.1869c-.8524-.5741-1.8002-.9653-2.8132-1.1649l-.3292-.0644v-2.8418h-2.3234v2.8418l-.3292.0644c-1.0146.1988-1.9608.5908-2.8148,1.1649l-.2783.1869-2.0085-2.0093-1.6427,1.6435,2.0085,2.0085-.1877.2783c-.5741.8548-.9653,1.8018-1.1625,2.814l-.0636.3292h-2.8434v2.3234h2.8434l.0636.3292c.1972,1.0114.5884,1.9584,1.1641,2.8148l.1861.2775-2.0085,2.0085,1.6427,1.6427,2.0085-2.0085.2783.1869c.854.5741,1.8002.9661,2.8148,1.1633l.3292.0644v2.8434Z" fill="#ff4c29" />
                    <path d="m13.9994,19.314c-2.9293,0-5.3131-2.3838-5.3131-5.3139s2.3838-5.3147,5.3131-5.3147c2.9309,0,5.3147,2.3838,5.3147,5.3147s-2.3838,5.3139-5.3147,5.3139Zm0-9.8143c-2.4808,0-4.4989,2.0188-4.4989,4.5005s2.018,4.4997,4.4989,4.4997,4.5005-2.0188,4.5005-4.4997-2.0196-4.5005-4.5005-4.5005Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Actions