import React, { useContext, useEffect, useState } from 'react'

import { FirebaseDataContext } from '../../Context/FirebaseDataContext';

import PersonItem from './assets/PeopleItemMain';

import './dashboardContent.css'

const PeopleContent = () => {

    const data = useContext(FirebaseDataContext)

    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (data.keyPeople) {
            setContacts(data.keyPeople)
        }
    }, [data, data.keyPeople])

    return (

        <div className='contact-main-container'>

            {contacts.length > 0 ?
                <>

                    {contacts.map((person, index) => {
                        return (
                            <PersonItem person={person} index={index} key={index} />

                        )
                    })

                    }

                </>
                :
                <>
                    <div className='no-issue-text-header-center summary-text-fs1p3'>
                        No People added for this project
                    </div>
                </>}

        </div>


    )
}

export default PeopleContent