
import React, { useState, useEffect } from "react";
import moment from "moment";

import workTypeDetails from '../constants/workTypeDetails'

import { collection, getDocs } from "firebase/firestore";
import { db, uploadDataToFireBase } from "../firebase";
import { UserAuth } from "./AuthContext";
import { calendarDateFinderLastDateInPeriod, railDateFinder } from '../constants/dateConversion/dateConversion'
import DataTemp from '../DataLatest/DataTemp.json'

export const FirebaseDataContext = React.createContext({});

// Adds styling specifics based on the work type ie icons, colors etc....
const combineWorkTypeDetailsWithData = (data) => {
    return data.map((item) => {
        const defaultKey = Object.keys(workTypeDetails)[0]
        let workTypeDetail = workTypeDetails[item.workType]
        if (!workTypeDetail) {
            workTypeDetail = workTypeDetails[defaultKey]
        }
        const combine = { color: workTypeDetail.color, bullet: workTypeDetail.bullet, mapDot: workTypeDetail.dotIcon, mapIcon: workTypeDetail.mapIcon, thick: workTypeDetail.thick }
        return { ...item, ...combine }
    })
}

export const getAllRoutesFromData = (data) => {
    let routes = [];
    data.forEach((item) => {
        if (!routes.includes(item.route)) {

        }
        routes.push(item.route);
    })
    return routes.sort();
}


export const getAllYearsFromData = (data) => {
    let years = [];
    data.forEach((item) => {
        if (!years.includes(item["Commissioning Year"]))
            years.push(item["Commissioning Year"]);
    })
    return years.sort();
}

export const getAllPeriodsFromData = (data) => {
    let periods = [];
    data.forEach((item) => {
        if (!periods.includes(item["Commissioning Period"]))
            periods.push(item["Commissioning Period"]);
    })
    return periods
}

export const getAllOwnersFromData = (data) => {
    let owners = [];
    data.forEach((item) => {
        if (!owners.includes(item.workOwner))
            owners.push(item.workOwner);
    })
    return owners.sort((a, b) => a - b);
}

// Extracts the different work types from the data for sidebar filters
// Orders it by alphabetical order
export const workTypeListGenerator = (data) => {
    let workTypesArray = []
    data.forEach((listItem) => {
        workTypesArray.push(listItem["workType"])
    })
    let workTypeFiltered = [...new Set(workTypesArray)]
    let workTypeList = workTypeFiltered.map((item) => {
        return { name: item }
    })
    return workTypeList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
}

//  Filter function to filter the data based on the sidebar filter options
export const getFilteredChartData = (data, tickedWorkTypeNames, selectedYear, selectedStatus, selectedPeriod) => {
    return data.filter((item) => {
        const yearcheck = selectedYear.length > 0 ? item["Commissioning Year"] === selectedYear : true
        const statuscheck = selectedStatus === "" ? true : item.Status === selectedStatus
        const name = (item?.workType ?? "other")
        const periodCheck = selectedPeriod === "" ? true : item["Commissioning Period"] === selectedPeriod
        return tickedWorkTypeNames.includes(name) &&
            yearcheck && statuscheck && periodCheck
    })
}

export const getSummaryYearFiltered = (data, selectedYear) => {
    return data.filter((item) => {
        const yearcheck = item["Commissioning Year"] === selectedYear;
        return yearcheck
    })
}

export const getSummaryFilteredChartData = (data, selectedYear, selectedPeriod) => {
    return data.filter((item) => item["Commissioning Year"] === selectedYear && item["Commissioning Period"] === selectedPeriod)
}

export const geoFeature = (data) => {
    let restrucData = data.map((item) => {
        let restructuredObject = { properties: {}, geometry: { type: 'Point', coordinates: [0, 0] } }
        restructuredObject["id"] = item.id
        restructuredObject["geometry"]["coordinates"] = [item["lon"], item["lat"]]
        restructuredObject["properties"] = {
            "Commissioning Period": item["Commissioning Period"],
            "Commissioning Year": item["Commissioning Year"],
            mapIcon: item["mapIcon"],
            subCategory: item["subCategory"],
            route: item["route"],
            programmeManager: item["programmeManager"],
            OPNumber: item["OPNumber"],
            bullet: item["bullet"],
            color: item["color"],
            projectName: item["projectName"],
            workOwner: item["workOwner"],
            workType: item["workType"],
            "Commissioning Date": item["Commissioning Date"],
            Status: item["Status"],
            Period: item["Period"],
            Year: item["Year"],
            ELR: item["ELR"],
            Mileage: item["Mileage"],
        }
        return restructuredObject;
    })

    return {
        type: 'FeatureCollection',
        features: restrucData
    }
}

const today = moment(new Date()).format("YYYY-MM-DD");
const railDateInfo = railDateFinder(today)

export const btnWriteStatusComponentVisibility = (userData, data, setButtonStatus) => {
    const currentUserId = userData.user.uid
    const userWriteList = data.userMetaData

    if (userWriteList.filter(e => e.id === currentUserId).length > 0) {
        setButtonStatus(true)
    } else {
        setButtonStatus(false)
    }
}

export const FirebaseDataContextProvider = ({ children }) => {

    // useEffect(() => {
    //     uploadDataToFireBase(DataTemp, "Workbank")
    // }, [])

    let value = {
        loading: true
    };
    const { user } = UserAuth();
    const [data, setData] = useState(value)


    function projectUpdateHandler(project) {
        if (!project.Status) {
            project.Status = "Outstanding"
        }
        if (!project["Commissioning Period"]) {
            project["Commissioning Period"] = "13"
            project.dateComment = "Date TBC"
        }
        if (!project["Commissioning Year"]) {
            project["Commissioning Year"] = "2023/24"
            project.dateComment = "Date TBC"
        }
        if (project["Commissioning Period"]) {
            project["Commissioning Period"] = project["Commissioning Period"].toString()
        }

        project["Planned_Commissioning Period"] = project["Commissioning Period"]
        project["Planned_Commissioning Year"] = project["Commissioning Year"]
        project["Planned_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Planned_Commissioning Year"], project["Planned_Commissioning Period"]).EndDate

        if (project["Actual_Commissioning Period"]) {
            project["Actual_Commissioning Period"] = project["Actual_Commissioning Period"].toString()
        }

        if (project["Actual_Commissioning Year"] && !project["Actual_Commissioning Period"]) {
            project["Actual_Commissioning Period"] = project["Commissioning Period"].toString()
            project["Actual_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Actual_Commissioning Year"], project["Actual_Commissioning Period"]).EndDate
            project.dateComment = "Check Actual"
        }

        if (!project["Actual_Commissioning Year"] && project["Actual_Commissioning Period"]) {
            project["Actual_Commissioning Year"] = project["Commissioning Year"]
            project["Actual_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Actual_Commissioning Year"], project["Actual_Commissioning Period"]).EndDate
            project.dateComment = "Check Actual"
        }

        if (project["Actual_Commissioning Year"] && project["Actual_Commissioning Period"]) {
            project["Actual_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Actual_Commissioning Year"], project["Actual_Commissioning Period"]).EndDate
        }

        if (project["Forecast_Commissioning Period"]) {
            project["Forecast_Commissioning Period"] = project["Forecast_Commissioning Period"].toString()
        }

        if (project["Forecast_Commissioning Year"] && !project["Forecast_Commissioning Period"]) {
            project["Forecast_Commissioning Period"] = project["Commissioning Period"].toString()
            project["Forecast_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Forecast_Commissioning Year"], project["Forecast_Commissioning Period"]).EndDate
        }

        if (!project["Forecast_Commissioning Year"] && project["Forecast_Commissioning Period"]) {
            project["Forecast_Commissioning Year"] = project["Commissioning Year"]
            project["Forecast_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Forecast_Commissioning Year"], project["Forecast_Commissioning Period"]).EndDate
        }

        if (project["Forecast_Commissioning Year"] && project["Forecast_Commissioning Period"]) {
            project["Forecast_Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Forecast_Commissioning Year"], project["Forecast_Commissioning Period"]).EndDate

        }

        if (!project["Forecast_Commissioning Period"] && !project["Actual_Commissioning Period"]) {
            project["Latest_Commissioning Period"] = project["Commissioning Period"].toString()
            project["Latest_Commissioning Year"] = project["Commissioning Year"]
            project.dateStatusComment = "Planned"
        }

        if (project["Forecast_Commissioning Period"] && !project["Actual_Commissioning Period"]) {
            project["Latest_Commissioning Period"] = project["Forecast_Commissioning Period"].toString()
            project["Latest_Commissioning Year"] = project["Forecast_Commissioning Year"]
            project.dateStatusComment = "Forecast"
        }


        if (project["Actual_Commissioning Period"]) {
            project["Latest_Commissioning Period"] = project["Actual_Commissioning Period"].toString()
            project["Latest_Commissioning Year"] = project["Actual_Commissioning Year"].toString()
            project.dateStatusComment = "Actual"
        }

        // here
        if (project["Actual_Commissioning Period"] && project["Actual_Commissioning Period"] !== project["Planned_Commissioning Period"]) {
            project["Forecast_Commissioning Period"] = project["Actual_Commissioning Period"]
            project["Forecast_Commissioning Year"] = project["Actual_Commissioning Year"]
        }

        if (project["Actual_Commissioning Year"] && project["Actual_Commissioning Year"] !== project["Planned_Commissioning Year"]) {
            project["Forecast_Commissioning Period"] = project["Actual_Commissioning Period"]
            project["Forecast_Commissioning Year"] = project["Actual_Commissioning Year"]
        }


        project["Commissioning Date"] = calendarDateFinderLastDateInPeriod(project["Latest_Commissioning Year"], project["Latest_Commissioning Period"]).EndDate


        project["Actual_Commissioning Date"] = project["Status"] === "Complete" ? calendarDateFinderLastDateInPeriod(project["Actual_Commissioning Year"], project["Actual_Commissioning Period"]).EndDate : ""
        project.fromDate = "2019-01-01"
    }

    function updateWorkBank(project, log) {
        projectUpdateHandler(project);
        const newData = { ...data };
        const index = newData.workbank.findIndex((item) => item.id === project.id);
        newData.updateLog.unshift(log)
        newData.workbank[index] = project;
        console.log("updating workbank")
        setData({ ...newData, ...workbankHandler(newData.workbank) });

    }
    function createWorkBank(project, log) {
        projectUpdateHandler(project);
        const newData = { ...data };
        newData.updateLog.unshift(log)
        newData.workbank.unshift(project);
        console.log("updating workbank")
        setData({ ...newData, ...workbankHandler(newData.workbank) });

    }
    function workbankHandler(projects) {
        const dateOrderData = projects.sort((a, b) => {
            const dateCompareResult = new Date(a["Commissioning Date"]) - new Date(b["Commissioning Date"]);
            return dateCompareResult;
        });

        const combinedData = combineWorkTypeDetailsWithData(dateOrderData);

        return {
            railDateInfo,
            projectData: combinedData,
            featureData: geoFeature(combinedData),
            workTypeList: workTypeListGenerator(combinedData),
            yearsList: getAllYearsFromData(combinedData),
            periodList: getAllPeriodsFromData(combinedData),
            workOwners: getAllOwnersFromData(combinedData),
        }
    }

    async function getAllData() {
        const snapshots = await Promise.all([
            getDocs(collection(db, "UserMetaData")),
            getDocs(collection(db, "KeyPeople")),
            getDocs(collection(db, "UpdateLog")),
            getDocs(collection(db, "Workbank"))
        ])
        const updatedData = {
            userMetaData: snapshots[0].docs.map((doc) => ({ ...doc.data(), id: doc.id, })),
            keyPeople: snapshots[1].docs.map((doc) => ({ ...doc.data(), id: doc.id, })),
            updateLog: snapshots[2].docs.map((doc) => ({ ...doc.data(), id: doc.id, })),
        }

        const workbank = snapshots[3].docs.map((doc) => {
            const project = { ...doc.data(), id: doc.id, };
            projectUpdateHandler(project);
            return project
        })

        setData({
            ...updatedData,
            ...workbankHandler(workbank),
            workbank,
        })
    }

    useEffect(() => {
        getAllData()

    }, [user]);

    console.log("projectData", data.projectData)

    return <FirebaseDataContext.Provider value={{ ...data, updateWorkBank, createWorkBank, getAllData }}>{children}</FirebaseDataContext.Provider>
}

export const useDataContext = () => React.useContext(FirebaseDataContext);