import React, { useContext, useState, useEffect } from 'react'

import {
    FirebaseDataContext,
    getAllPeriodsFromData,
    getSummaryFilteredChartData,
    getSummaryYearFiltered
} from '../Context/FirebaseDataContext';

import moment from "moment/moment"
import { useNavigate } from 'react-router-dom';

// Card components
import DbCard from '../Components/cards/DbCard'
import StatsCard from '../Components/cards/StatsCard'

import { headerContent } from '../constants/appStructure.js';
import useWindowWidth from '../hooks/useWindowWidth';
import { cardIcons2 } from '../constants/appStructure.js'


import LiveFeedContent from '../Components/dashboardComponents/LiveFeedContent';
import PeopleContent from '../Components/dashboardComponents/PeopleContent';
import UpdateStatus from '../Components/dashboardComponents/UpdateStatus';
import FrontPageMap from './dashboardComponents/frontPageMap/FrontPageMap'
import ProjectList from './dashboardComponents/ProjectList';
import StatsCardDouble from '../Components/cards/StatsCardDouble';
import ProjectBarGraph from './dashboardComponents/ProjectBarGraph';
import ProjectLineGraph from './dashboardComponents/ProjectLineGraph';
import BarChart from '../Components/Graph/bartSortPt/BarChart'

import '../CssGeneral/dashboard.css'
import '../CssGeneral/dashboardContent.css'
import './SummaryStyle.css'

const SummaryStyle = () => {

    const data = useContext(FirebaseDataContext)
    const navigate = useNavigate()

    // To control rendering on mobile devices
    const width = useWindowWidth();
    const breakpoint = headerContent["mobile-breakpoint"]

    // For Programme Overview Section
    const [workbankData, setWorkbankData] = useState(data.projectData.filter((item) => item["Commissioning Year"] === "2023/24"))
    const [workbankDateTitle, setWorkbankDateTitle] = useState("Year 5")
    const [barTitle, setBarTitle] = useState("years")
    const [graphTimeUnit, setGraphTimeUnit] = useState("")
    const [projectListItems, setProjectListItems] = useState(data.workTypeList.map((item) => item.name))
    const [selectedProject, setSelectedProject] = useState("All projects")


    // For Period Section
    let foundProject = findNextActivePeriod()
    const [summaryYearSelected, setSummaryYearSelected] = useState(foundProject["Commissioning Year"])
    const [summaryPeriodList, setSummaryPeriodList] = useState([])
    const [summaryPeriodSelected, setSummaryPeriodSelected] = useState(foundProject["Commissioning Period"])
    const summaryYearsFiltered = getSummaryYearFiltered(data.projectData, summaryYearSelected)
    const [summaryFilteredData, setSummaryFilteredData] = useState([])

    // For Period Section
    useEffect(() => {
        setSummaryFilteredData(getSummaryFilteredChartData(data.projectData, summaryYearSelected, summaryPeriodSelected))
    }, [summaryPeriodSelected, summaryYearSelected])

    useEffect(() => {
        setSummaryPeriodList(getAllPeriodsFromData(summaryYearsFiltered))
    }, [summaryYearSelected])

    useEffect(() => {
        setSummaryPeriodSelected(summaryPeriodList[0])
    }, [summaryPeriodList])

    function setWorkbankFilter(selectedProject, workbankDateTitle) {
        const getFilteredProjectsList = (filteredData) => {
            let filteredProjectList = [];
            filteredData.forEach((item) => {
                if (!filteredProjectList.includes(item["workType"])) {
                    filteredProjectList.push(item["workType"])
                }
            })
            return filteredProjectList;
        }
        const selectedProjectDataFilter = (item) => {
            if (selectedProject == "All projects") {
                return true
            } else {
                return item["workType"] === selectedProject
            }
        }
        switch (workbankDateTitle) {
            case "Workbank":
                setWorkbankDateTitle("Workbank")
                const filteredData1 = data.projectData
                setWorkbankData(filteredData1.filter(selectedProjectDataFilter))
                setBarTitle("year")
                setGraphTimeUnit("")
                setProjectListItems(getFilteredProjectsList(filteredData1))
                break;
            case "Year 5":
                setWorkbankDateTitle("Year 5")
                const filteredData = data.projectData.filter((item) => item["Commissioning Year"] === "2023/24")
                setWorkbankData(filteredData.filter(selectedProjectDataFilter))
                setBarTitle("period")
                setGraphTimeUnit("P")
                setProjectListItems(getFilteredProjectsList(filteredData))
                break;
            default:
                break

        }
    }

    function workBankDateToggle() {
        const workbankDateTitles = ["Workbank", "Year 5"];
        const currentIndex = workbankDateTitles.indexOf(workbankDateTitle);
        const nextIndex = currentIndex === workbankDateTitles.length - 1 ? 0 : currentIndex + 1;
        setWorkbankFilter(selectedProject, workbankDateTitles[nextIndex])
    }

    useEffect(() => {
        setWorkbankFilter(selectedProject, workbankDateTitle)

    }, [selectedProject])
    // For Period Overview section
    function findNextActivePeriod() {
        let foundProject = data.projectData.filter((item, index) => moment(item["Commissioning Date"]).isSameOrAfter(moment()))[0]
        return foundProject
    }

    // For page redirect
    function projectRedirect() {
        navigate(`/app/main/table`)
    }

    return (<>
        <div className='dashboard-container'>
            <div className='graph-card-wide summary-sub-section-top'>
                <div className='summary-sub-section-contents'>
                    <div className='summary-sub-title'> Programme Overview</div>
                    <div className='summary-sub-section-top-right'>
                        <select className="project-select" value={selectedProject} onChange={({ target }) => { setSelectedProject(target.value) }}>
                            <option value="All projects">All projects</option>
                            {projectListItems.map((projectItem, index) => <option key={index} value={projectItem}>{projectItem}</option>)}
                        </select>
                        <button className="card-top-btn-sum-main" onClick={workBankDateToggle}>
                            <div className='top-btn-sum-main-text'>{workbankDateTitle}</div></button>
                    </div>

                </div>
            </div>


            <StatsCardDouble
                cardType={'pie-card-top-row-double'}
                title={`${workbankDateTitle} - ${selectedProject} status`}
                btnDisplayBool={false}
                data={workbankData}
            >
            </StatsCardDouble>

            <StatsCard
                cardType={'pie-card-top-row-double'}
                title={selectedProject === "All projects" ? `${workbankDateTitle} - ${selectedProject} disciplines` : `${workbankDateTitle} - ${selectedProject} Volumes`}
                btnDisplayBool={false}
                btnIcon={""}
            >
                <BarChart
                    data={workbankData}
                    dataType={selectedProject === "All projects" ? "ProjectQty" : "VolumeTotal"} />
            </StatsCard>

            <StatsCard
                cardType={'pie-card-top-row-double'}
                title={`${workbankDateTitle} - ${selectedProject} by ${barTitle}`}
                btnDisplayBool={false}
            >
                <ProjectBarGraph
                    workbankData={workbankData}
                    workbankDateTitle={workbankDateTitle}
                    units="Sites"
                    timeUnit={graphTimeUnit}
                />
            </StatsCard>

            <StatsCard
                cardType={'pie-card-top-row-double'}
                title={`${workbankDateTitle} - ${selectedProject} cumulative`}
                btnDisplayBool={false}
            >
                <ProjectLineGraph
                    workbankData={workbankData}
                    units="Sites"
                    timeUnit={graphTimeUnit}
                    workbankDateTitle={workbankDateTitle}
                />
            </StatsCard>



            <div className='graph-card-wide summary-sub-section'>
                <div className='summary-sub-section-contents'>
                    <div className='summary-sub-title'> Period Overview</div>
                    <div className='summary-select-container'>
                        <div className='summary-select-sub-container'>
                            <div>Year</div>
                            <select className='summary-select' value={summaryYearSelected} onChange={(e) => setSummaryYearSelected(e.target.value)}>
                                {data.yearsList.map((item, index) => {
                                    return <option value={item} key={index}>{item}</option>
                                })}
                            </select>
                        </div>

                        <div className='summary-select-sub-container'>
                            <div>Period</div>
                            <select className='summary-select' value={summaryPeriodSelected} onChange={(e) => setSummaryPeriodSelected(e.target.value)}>
                                {summaryPeriodList.map((item, index) => {
                                    return <option value={item} key={index}>{item}</option>
                                })}
                            </select> </div>
                    </div>

                </div>

            </div>

            <StatsCardDouble
                cardType={'pie-card-top-row-double'}
                title={`P${summaryPeriodSelected}-${summaryYearSelected} Status`}
                btnDisplayBool={false}
                data={summaryFilteredData}

            >
            </StatsCardDouble>

            <StatsCard
                cardType={'pie-card-top-row-double'}
                title={`P${summaryPeriodSelected}-${summaryYearSelected} Work types`}
                btnDisplayBool={false}
                data={summaryFilteredData}
            >

                {summaryFilteredData.length > 0 && <BarChart
                    data={summaryFilteredData} dataType={"ProjectQty"} />}

            </StatsCard>

            <DbCard
                cardType={' card card-map-33'}
                title={`P${summaryPeriodSelected}-${summaryYearSelected} Locations`}
                btnDisplayBool={false}
                OnClickFunc={projectRedirect}
                btnIcon={""}
                iconDisplayBool={false}
                icon={cardIcons2.pedcap}
                comparisonDisplayBool={false}
            >
                {summaryFilteredData.length > 0 && <FrontPageMap
                    data={summaryFilteredData}
                />}
            </DbCard>

            <DbCard
                cardType={'table-card card'}
                title={`P${summaryPeriodSelected}-${summaryYearSelected} Details`}
                btnDisplayBool={false}
                OnClickFunc={projectRedirect}
                btnIcon={""}
                iconDisplayBool={false}
                icon={cardIcons2.pedcap}
                comparisonDisplayBool={false}
            >
                <ProjectList
                    selectedPeriodData={summaryFilteredData}
                />
            </DbCard>

            <DbCard
                cardType={'graph-card-wide card  card-height-10 '}
                icon={""}
                title={"Key People"}
                cardContent={""}
            >
                <PeopleContent />
            </DbCard>

            <DbCard
                cardType={'details-card card  card-height-10 '}
                icon={cardIcons2.liveUpdates}
                title={"Update Status"}
                cardContent={""}
            >
                <UpdateStatus />
            </DbCard>
            <DbCard
                cardType={'details-card card  card-height-10 '}
                icon={cardIcons2.liveUpdates}
                title={"Live Updates"}
                cardContent={""}
            >
                <LiveFeedContent />
            </DbCard>

        </div>
        <>{width > breakpoint ? <></> : <div className='summary-bottm-btn-container'><button className='summary-btn-main' onClick={projectRedirect}>Find Project</button></div>}</>

    </>
    )
}

export default SummaryStyle