import React from 'react'




import StatusHalfPie from './StatusHalfPie'


import '../../displayStyles/SummaryStyle.css'


const StatsDetailProjectInfo = ({ percentage }) => {



    const statusColorSet = (value) => {
        if (value > 66) {
            // return "#98e000"
            return "#f84f39"
        }
        else if (value >= 34 && value < 66) {
            // return "#ffe605"
            return "#f84f39"
        }
        else if (value < 34) {
            return "#f84f39"
        }
    }


    // const totalPercentageAverage = Math.round((kPIPercent + costPercent + safetyPercent + programmePercent) / 4)

    return (
        <>
            <div className='proj-status-main-container '>

                <StatusHalfPie percentage={percentage} circleColor={statusColorSet(percentage)} />

            </div>
            <div className='proj-status-text-container'>Complete</div>

        </>

    )
}

export default StatsDetailProjectInfo