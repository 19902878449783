import React, { useContext } from 'react'

import LineChart from '../../Components/Graph/line-graph';

import '../../CssGeneral/dashboard.css'
import '../../CssGeneral/dashboardContent.css'
import '../../displayStyles/SummaryStyle.css'


import { FirebaseDataContext } from '../../Context/FirebaseDataContext';



const ProjectLineGraph = ({ workbankData, workbankDateTitle, units, timeUnit }) => {

    const data = useContext(FirebaseDataContext)

    const { periodList, yearsList } = data

    function barChartDataGenerator(data, workbankDateTitle) {
        let output = []
        let timeline = yearsList
        let timeType = ""

        if (workbankDateTitle == "Workbank") {
            timeline = yearsList
            timeType = "Year"
        } else {
            timeline = periodList
            timeType = "Period"
        }

        timeline.forEach((timePeriod) => {
            // time period is the year or period
            let forecastCheck = (data.filter((dataItem) => dataItem[`Forecast_Commissioning ${timeType}`] === timePeriod).length) + (data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod && dataItem[`Forecast_Commissioning ${timeType}`] === "").length)

            output.push({
                Timeline: timePeriod,
                reforecast: data.filter((dataItem) => dataItem[`Forecast_Commissioning ${timeType}`] === timePeriod).length,
                planned: data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod).length,
                actual: data.filter((dataItem) => dataItem[`Actual_Commissioning ${timeType}`] === timePeriod).length,
                unchanged: data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod && dataItem[`Forecast_Commissioning ${timeType}`] === "").length,
                forecast: forecastCheck
            })
        })
        console.log(output)
        return output
    }

    function cumulativeCalculator(data) {
        let plannedSum = 0;
        let actualSum = 0;
        let forecastSum = 0;
        let output = [];
        let indexOfFirstEmptyActual = null
        data.forEach((item, index) => {
            if (!item.actual && indexOfFirstEmptyActual === null) {
                indexOfFirstEmptyActual = index
            }
        })
        data.forEach((item, index) => {
            if (item.actual) {
                let itemData = {
                    Timeline: item.Timeline,
                    planned: plannedSum + item.planned,
                    forecast: forecastSum + item.forecast,
                    actual: actualSum + item.actual,
                    test: "test",
                    "disabled": true
                }

                if (indexOfFirstEmptyActual != 0 && index == indexOfFirstEmptyActual - 1) {
                    itemData.disabled = false;
                    itemData.additional = "Latest period"
                    // itemData.showTooltip = "always"
                }
                output.push(itemData)
            } else {
                let itemData = {
                    Timeline: item.Timeline,
                    planned: plannedSum + item.planned,
                    forecast: forecastSum + item.forecast,
                    actual: null,
                    else: "testt",
                    "disabled": false
                }

                output.push(itemData)

            }
            plannedSum = plannedSum + item.planned
            actualSum = actualSum + item.actual
            forecastSum = forecastSum + item.forecast

        })

        return output
    }

    return (
        <div className='summ-top-graph-container'>
            <div className='graph-container'>
                <LineChart
                    data={cumulativeCalculator(barChartDataGenerator(workbankData, workbankDateTitle))}
                    units={units}
                    timeUnit={timeUnit}
                    graphRefresh={workbankData}


                />
            </div>

        </div>
    )
}

export default ProjectLineGraph