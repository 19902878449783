import React from 'react'

const SoftReport = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m22.5,26H5.5c-.2764,0-.5-.2236-.5-.5V4.5c0-.2764.2236-.5.5-.5h2c.2764,0,.5.2236.5.5s-.2236.5-.5.5h-1.5v20h16V5h-1.5c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h2c.2764,0,.5.2236.5.5v21c0,.2764-.2236.5-.5.5Z" fill="#ff4c29" />
                    <path d="m18.5,7h-9c-.2764,0-.5-.2236-.5-.5v-2c0-1.3784,1.1211-2.5,2.5-2.5h5c1.3789,0,2.5,1.1216,2.5,2.5v2c0,.2764-.2236.5-.5.5Zm-8.5-1h8v-1.5c0-.8271-.6729-1.5-1.5-1.5h-5c-.8271,0-1.5.6729-1.5,1.5v1.5Z" fill="#ff4c29" />
                    <path d="m18.5,12h-9c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h9c.2764,0,.5.2236.5.5s-.2236.5-.5.5Z" fill="#ff4c29" />
                    <path d="m18.5,17h-9c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h9c.2764,0,.5.2236.5.5s-.2236.5-.5.5Z" fill="#ff4c29" />
                    <path d="m18.5,21.6233h-9c-.2764,0-.5-.2236-.5-.5s.2236-.5.5-.5h9c.2764,0,.5.2236.5.5s-.2236.5-.5.5Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default SoftReport