import React from 'react'

const Comms = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m23.6309,21.1287c-.1231,0-.2462-.0159-.3694-.0487-.0139-.004-.0298-.0089-.0437-.0139l-11.6842-4.2773c-.2006-.0735-.3336-.2641-.3336-.4776v-8.2528c0-.2254.1489-.425.3654-.4875l11.7279-3.4304c.4647-.1023.8976.003,1.2292.276.3376.2711.5302.6752.5302,1.1081v14.1773c0,.4468-.2025.8588-.556,1.1309-.2542.1946-.556.2959-.8658.2959Zm-.0894-1.0266c.1172.0218.2363-.002.3356-.0755.1013-.0774.1589-.1966.1589-.3247V5.5246c0-.1698-.0953-.272-.1509-.3177-.0576-.0467-.1807-.1132-.3416-.0814l-11.3268,3.3142v7.5161l11.3248,4.1463Z" fill="#ff4c29" />
                    <path d="m8.9621,23.8978c-.1172,0-.2343-.0139-.3495-.0427h0l-1.8348-.4587c-.6354-.1599-1.0803-.7288-1.0803-1.3831v-5.7021c0-.281.2284-.5084.5084-.5084h3.6677c.28,0,.5084.2274.5084.5084v6.1608c0,.4428-.2006.8519-.5481,1.1239-.2542.1986-.56.3018-.8717.3018Zm-2.2479-7.0782v5.1937c0,.1877.1271.3505.3098.3962l1.8348.4587h0c.1251.0318.2482.003.3495-.0735.0993-.0784.1569-.1956.1569-.3227v-5.6525h-2.651Z" fill="#ff4c29" />
                    <path d="m11.7084,16.8196h-5.5025c-1.7971,0-3.2586-1.4625-3.2586-3.2596v-2.7503c0-1.7971,1.4615-3.2596,3.2586-3.2596h5.5025c.28,0,.5084.2274.5084.5084v8.2528c0,.281-.2284.5084-.5084.5084Zm-5.5025-8.2528c-1.2371,0-2.2419,1.0058-2.2419,2.2429v2.7503c0,1.2371,1.0048,2.2429,2.2419,2.2429h4.9942v-7.2361h-4.9942Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Comms