import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import Login from './pages/Login';

import ProtectedRoute from './pages/ProtectRoute';
import Loading from './pages/Loading';

import { UserAuth } from './Context/AuthContext';

import DataLoadedPage from './pages/DataLoadedPage';

function App() {

  const { user } = UserAuth();
  const isUserNotLoading = (user == null || !user.loading)

  return (
    <>
      {(isUserNotLoading) ? <>


        <Routes>
          <Route exact path='/' element={<Login />} />

          <Route path="/app" element={<ProtectedRoute />}>
            <Route path='main/*' element={<DataLoadedPage></DataLoadedPage>} />


          </Route>
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />

        </Routes>

      </> : <><Loading /></>}
    </>
  );
}

export default App;

