import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DisplayIcon from './floatBtnsvg/FBIcondisplay';
import MapIcon from './floatBtnsvg/FBIconMap';
import SummaryIcon from './floatBtnsvg/FBIconSummary';
import TableIcon from './floatBtnsvg/FBIconTable';
import TimelineIcon from './floatBtnsvg/FBIconTimeline';


import './Options.css';

const Options = () => {

    const navigate = useNavigate()

    const summaryRedirect = () => {
        navigate('/app/main/summary')
    }
    const tableRedirect = () => {
        navigate('/app/main/table')
    }
    const mapRedirect = () => {
        navigate('/app/main/map')
    }
    const timelineRedirect = () => {
        navigate('/app/main/timeline')
    }


    // const fill = "#373e40";
    const fill = "#fff";



    const [btn, setBtn] = useState(1)


    function arrangeIcons(btn) {
        let st = (+48) + (-(48) * btn)
        document.querySelectorAll('.float-icon-container').forEach(
            icon => {
                icon.style.left = `${st}px`;
                st += 48;
            }
        )
    }

    useEffect(() => {


        arrangeIcons(btn)
    }, [btn])



    return (
        <div className="options-container" onMouseOver={arrangeIcons.bind(null, 1)} onMouseOut={arrangeIcons.bind(null, btn)}>

            {/* <DisplayIcon fill={fill} /> */}



            <SummaryIcon onClick={() => {
                setBtn(1)
                summaryRedirect()
            }} fill={fill} />
            {/* <span>Summary</span> */}


            <TableIcon fill={fill} onClick={() => {
                setBtn(2)
                tableRedirect()
            }} />
            {/* <span>Table</span> */}


            <MapIcon fill={fill} onClick={() => {
                setBtn(3)
                mapRedirect()
            }} />
            {/* <span>Map</span> */}


            <TimelineIcon fill={fill} onClick={() => {
                setBtn(4)
                timelineRedirect()
            }} />
            {/* <span>Timeline</span> */}



        </div >
    );
};

export default Options;
