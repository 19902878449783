// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage"
import { onSnapshot, collection, addDoc, setDoc, doc, getFirestore, getDoc, getDocs, updateDoc } from 'firebase/firestore';



const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage = getStorage(app);

export const uploadDataToFireBase = (data, collectionName) => {
  const generateBasicAuditFields = () => ({
    dateCreated: new Date(),
    dateUpdated: new Date(),
    modifiedBy: "Administrator"
  });
  data.forEach((documentData, index) => {
    const collectionReference = collection(db, collectionName);
    addDoc(collectionReference, { ...documentData, ...generateBasicAuditFields() });
  })
}


export const uploadFile = (destinationPath, file) => {

  const uploadFilePromise = new Promise((resolve) => {
    const storageRef = ref(storage, `${destinationPath}/${file.name}`);
    uploadBytes(storageRef, file).then(snapshot => {
      getDownloadURL(snapshot.ref).then((downloadUrl) => {
        resolve({ url: downloadUrl, ref: snapshot.ref })
      })
    })
  })
  return uploadFilePromise
}


export default app;
