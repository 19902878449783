import './Sidebar.css'
import React, { useEffect, useState } from 'react';

import RadioButtonList from '../SideNavLists/RadioButtonList/RadioButtonList';
import CheckboxList from '../SideNavLists/CheckboxList/CheckboxList';

import PeriodIcon from '../../assets/UIcons/__UIcon-Period.svg'
import StatusIcon from '../../assets/UIcons/__UIcon-Status.svg'
import YearIcon from '../../assets/UIcons/__UIcon-Year.svg'
import WorkTypeIcon from '../../assets/UIcons/__UIcon-WorkType.svg'

import LogoutIcon from '../../assets/UIcons/__Btn-LogOut-white.svg'

import { UserAuth } from '../../Context/AuthContext'
import { useNavigate } from 'react-router-dom';

export const clearMenuSelections = (container = document) => {
    const sideBarParentActiveButtons = container.getElementsByClassName("sidebar-btn-selected");

    if (sideBarParentActiveButtons.length) {
        [...sideBarParentActiveButtons].forEach((element) => {
            element.className = "sidebar-btn"
        })
    }
    const sideBarChildActiveMenus = container.getElementsByClassName("list-container");
    if (sideBarChildActiveMenus.length) {
        [...sideBarChildActiveMenus].forEach((element) => {
            element.className = "list-container-hide";
        })
    }
}

export default function Sidebar({
    displayStyles, setDisplayStyle, displayStyle, dateList, selectedStatus, setSelectedStatus, yearsList, setSelectedYear, selectedYear, selectedPeriod, setSelectedPeriod, workTypeList, setTickedWorkTypeNames, tickedWorkTypeNames, containerClassName, toggleSidebarWidth, periodList }) {

    const { user, logout } = UserAuth();
    const userName = user && user.email?.split(/[@]/)

    // const split = userName


    const navigate = useNavigate()
    const [selectedListItem, setSelectedListItem] = useState("");

    // let containerClassName = "side-bar-container";
    // if (isCollapsed) {
    //     containerClassName += " collapsed";
    // }

    const toggleMenuItem = (menuRef) => {
        const activateSelectedMenu = (container) => {
            const targetMenuButton = container.getElementsByClassName("sidebar-btn")[0];
            const targetMenuContainer = container.getElementsByClassName("list-container-hide")[0];
            targetMenuButton.className = "sidebar-btn-selected"
            targetMenuContainer.className = "list-container"
        }

        const isCurrentMenuActive = (menuRef) => {
            const sideBarParentActiveButtons = menuRef.getElementsByClassName("sidebar-btn-selected");

            return sideBarParentActiveButtons.length > 0
        }

        if (!isCurrentMenuActive(menuRef.current)) {
            clearMenuSelections();
            activateSelectedMenu(menuRef.current)
        } else {
            clearMenuSelections(menuRef.current);
        }
    }


    const handleLogOut = () => {
        navigate("logoutreview")
    }
    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/);
    const len = urlArray.length

    const [sideDisplay, setSideDisplay] = useState(true)

    useEffect(() => {
        if (urlArray[len - 1] === "summary" || urlArray[len - 1] === "form" || urlArray[len - 2] === "form") {
            setSideDisplay(false)
        } else {
            setSideDisplay(true)
        }


    }, [latestURL])

    const statusList = ["Outstanding", "Complete",]


    return (

        <div className={containerClassName}>

            <div className='sidenav-menu'>
                {/* <div className="sidenav-menu-title">
                <p >Menu</p>
                <div className="sidenav-menu-title-underline"></div>
            </div>

            <div className="side-bar-content">
                <DisplayList listTitle={"Display Style"} isSelected={"Display Style" === selectedListItem}
                    toggleSidebarWidth={toggleSidebarWidth}
                    toggleMenuItem={toggleMenuItem} listData={displayStyles} setSelectedItem={setDisplayStyle} selectedItem={displayStyle}
                    buttonImage={DisplayIcon}
                />
            </div> */}

                {sideDisplay ? <>
                    <div className="sidenav-menu-title2">
                        <p >Filters</p>
                        <div className="sidenav-menu-title-underline"></div>
                    </div>

                    <div className="side-bar-content">


                        <CheckboxList listTitle={"Work Type"}
                            toggleSidebarWidth={toggleSidebarWidth} isSelected={"Work Type" === selectedListItem} toggleMenuItem={toggleMenuItem} listData={workTypeList}
                            setTickedItems={setTickedWorkTypeNames}
                            tickedItems={tickedWorkTypeNames}
                            buttonImage={WorkTypeIcon}
                        />

                        <RadioButtonList listTitle={"Year"}
                            toggleSidebarWidth={toggleSidebarWidth}
                            isSelected={"Year" === selectedListItem} toggleMenuItem={toggleMenuItem} listData={yearsList}
                            setSelectedItem={setSelectedYear}
                            selectedItem={selectedYear}
                            allItemsSpecificTitle={"All Years"}
                            buttonImage={YearIcon}
                        />
                        {selectedYear.length === 0 ? <></> : <RadioButtonList listTitle={"Period"}
                            toggleSidebarWidth={toggleSidebarWidth} isSelected={"Period" === selectedListItem} toggleMenuItem={toggleMenuItem} listData={periodList}
                            setSelectedItem={setSelectedPeriod}
                            selectedItem={selectedPeriod}
                            allItemsSpecificTitle={"All"}

                            buttonImage={PeriodIcon}
                        />}



                        <RadioButtonList listTitle={"Status"}
                            toggleSidebarWidth={toggleSidebarWidth} isSelected={"Status" === selectedListItem} toggleMenuItem={toggleMenuItem} listData={statusList}
                            setSelectedItem={setSelectedStatus}
                            selectedItem={selectedStatus}
                            allItemsSpecificTitle={"All"}

                            buttonImage={StatusIcon}
                        />





                    </div></> : <></>
                }



                <div className='user-details-container'>
                    <button onClick={handleLogOut} className='btn-clear'>
                        <div className='btn-clear-group-container '>
                            <img className='user-details-icon' src={LogoutIcon} alt="button" />
                            <p className='user-details'>User:{userName && userName[0]}</p>
                        </div>

                    </button>

                </div>
            </div>


        </div>
    )


}
