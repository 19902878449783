import React from 'react'

const People = ({ fill }) => {
    return (
        <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <path d="m14,13c-2.4814,0-4.5-2.0186-4.5-4.5v-2c0-2.4814,2.0186-4.5,4.5-4.5s4.5,2.0186,4.5,4.5v2c0,2.4814-2.0186,4.5-4.5,4.5Zm0-10c-1.9297,0-3.5,1.5703-3.5,3.5v2c0,1.9297,1.5703,3.5,3.5,3.5s3.5-1.5703,3.5-3.5v-2c0-1.9297-1.5703-3.5-3.5-3.5Z" fill="#ff4c29" />
            <path d="m22.9697,26H5.0293c-.8428,0-1.5293-.6865-1.5293-1.5303v-2.8101c0-3.6724,2.9873-6.6597,6.6602-6.6597h.001c.3906,0,.752.1929.9902.5293.6533.9209,1.7178,1.4707,2.8486,1.4707s2.1953-.5498,2.8477-1.4702c.2373-.3364.5986-.5298.9902-.5298,3.6738,0,6.6621,2.9873,6.6621,6.6597v2.8101c0,.8438-.6865,1.5303-1.5303,1.5303Zm-12.8086-10c-3.1211,0-5.6611,2.5391-5.6611,5.6597v2.8101c0,.2925.2373.5303.5293.5303h17.9404c.292,0,.5303-.2378.5303-.5303v-2.8101c0-3.1206-2.5391-5.6597-5.6602-5.6597-.0898,0-.1523.0747-.1758.1069-.8398,1.1855-2.21,1.8931-3.6641,1.8931s-2.8242-.7075-3.6641-1.8921c-.0225-.0322-.0859-.1079-.1748-.1079Z" fill="#ff4c29" />
        </svg>
    )
}

export default People