import moment from "moment"
import data from "./railCalendar.json"



// Add railway week and year to find the calendar date in YYYY-MM-DD format
export const calendarDateFinderWithWkPeriod = (year, period) => {

    let date = data.find((item) => {
        let itemRailPeriod = item.DateInfo.split(" ")[1]

        if (item.Year === year && itemRailPeriod === period) {

            return true
        } else {
            return false
        }

    })

    return date
}

export const calendarDateFinderLastDateInPeriod = (year, period) => {

    let date = data.find((item) => {
        let itemRailPeriod = item.DateInfo.split(" ")[1]
        let itemRailPeriodWeek = item.DateInfo.split(" ")[3]

        if (item.Year === year && itemRailPeriod === period && itemRailPeriodWeek === "4") {

            return true
        } else {
            return false
        }

    })

    return date
}

// Add calendar date to find the railway week and year must be YYYY-MM-DD
export const railDateFinder = (date) => {
    let foundDate = data.find((item) => {
        return moment(date).isBetween(item.StartDate, item.EndDate) || moment(date).isSame(item.StartDate) || moment(date).isSame(item.EndDate)
    })



    return {
        ...foundDate,
        latestWk: ((foundDate.DateInfo.split(" ")[5]).replace(")", "")),
        latestPeriod: foundDate.DateInfo.split(" ")[1],
        latestYear: foundDate.Year
    }

}





export const CP6yearsConverter = (year) => {
    let foundYear = data.find((item) => {
        return item.year === year
    })
    return foundYear.yearNumber

}
