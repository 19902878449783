import React, { useContext } from "react";

import DbCard from "../Components/cards/DbCard";


import './pages.css'
import '../App.css'

import { db } from '../firebase'
import { deleteDoc, doc } from 'firebase/firestore'
import { useParams, useNavigate } from 'react-router-dom';

import { FirebaseDataContext } from "../Context/FirebaseDataContext";

import '../CssGeneral/dashboardContent.css'
import { addUpdateLog } from "../constants/UpdateLog";
import { UserContext } from "../Context/AuthContext";
import moment from "moment";

const DeleteCheck = () => {

    const userData = useContext(UserContext)
    const data = useContext(FirebaseDataContext)
    const newData = data.projectData;

    const navigate = useNavigate()
    const params = useParams();
    const selectedItemId = params.id;

    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`

    const getProjectById = newData.filter(item => {
        return item.id === params.id;

    })

    const latestProjectName = getProjectById[0].projectName
    const latestProjectworkType = getProjectById[0].workType

    const handleDelete = async () => {
        await deleteDoc(doc(db, 'Workbank', selectedItemId));
        addUpdateLog({
            name: logInUser,
            // date: moment().unix(),
            title: "Deleted Project",
            update: `${userName} deleted ${latestProjectName} from ${latestProjectworkType}`,
        })
        data.getAllData()
        navigate('/app/main/table')

    };

    const handleBackButton = () => {
        navigate('/app/main/table')

    }


    return (

        <div className='dashboard-container'>

            <DbCard
                cardType={'card graph-card-wide card-height-min'}
                icon={""}
                title={`Delete Asset`}
                cardContent={""}
                btnText={"Go back"}
                mainBtnText={`Delete`}
                btnDisplayBool={true}
                btnMainDisplayBool={true}
                OnClickFuncMainBtn={handleDelete}
                OnClickFunc={handleBackButton}>

                {params.id ? <div className="delete-details-container">
                    <div className="delete-text summary-text-fs1p3">Are You sure you want to Delete?</div>
                    <h1 className=" delete-text summary-text-fs1p3 orange-name">{`${latestProjectName} - ${latestProjectworkType} Works!`}</h1>


                </div> : <></>}


            </DbCard>

        </div>
    )
}

export default DeleteCheck