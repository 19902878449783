import React from 'react'

const FBIconTimeline = ({ fill, onClick }) => {
    return (
        <div className='float-icon-container' onClick={onClick}><svg id="new_icons" data-name="new icons" xmlns="http://www.w3.org/2000/svg" width="22mm" height="22mm" viewBox="0 0 62.3622 62.3622">
            <path d="m31.2001,4.3086c14.8404,0,26.8711,12.0311,26.8711,26.8718s-12.0307,26.8712-26.8711,26.8712S4.3291,46.0208,4.3291,31.1804,16.3598,4.3086,31.2001,4.3086m-.019-4.1466c17.1315,0,31.019,13.8879,31.019,31.0187s-13.8875,31.0194-31.019,31.0194S.1621,48.3119.1621,31.1808,14.0496.1621,31.1811.1621Z" fill={fill} fill-rule="evenodd" />
            <path d="m32.6125,28.7671c1.0203.3808,1.8256,1.199,2.189,2.2254h12.3322c.7501,0,1.362.6115,1.362,1.3613s-.6119,1.3617-1.362,1.3617h-12.4343c-.5854,1.3256-1.9045,2.1989-3.3761,2.1989-2.0379,0-3.6898-1.652-3.6898-3.6895,0-1.4944.9004-2.8292,2.2557-3.4003V11.8442c0-.7497.6119-1.3613,1.362-1.3613.7494,0,1.3613.6116,1.3613,1.3613v16.9229Z" fill={fill} fill-rule="evenodd" />
        </svg></div>
    )
}

export default FBIconTimeline