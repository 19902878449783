import { MapSymbol } from './MapSymbol'




import _ from 'lodash';



// This gives visual properties based on the worktype such as icons etc..
const runIcons = (data, workTypes) => {
    const workTypeGroup = []
    data.features.forEach(project => {
        if (project.properties.workType === workTypes) { workTypeGroup.push(project) }
    })
    return {
        type: 'FeatureCollection',
        features: workTypeGroup
    }
}


export const mapIconDisplayed = (map, isVisible, createPopUp, featureData, pathname, tableContent) => {

    const routePath = ""

    // const symbolsData = []

    const symbolsData = featureData.features.map((item, index) => {



        return (


            {
                data: { type: 'FeatureCollection', features: [item] },
                source: `dot-symbol-${index}`,
                imageSource: item.properties.mapIcon,
                imageName: `dot-symbol-image-${item.projectName + item.id}`,
                map: map,
                isVisible,
                createPopUp: createPopUp,
                minZoom: 6.7,
                maxZoom: 19,
                iconSize: 0.1,
                rotation: 0,
                relatedPathNames: routePath,
                tableValues: tableContent,
                text: "",
                textOffset: [0, 0],
                paint: {},
                type: "symbol",
                currentPathName: pathname,

            }



        )

    })

    symbolsData.forEach((symbolData) => MapSymbol(symbolData));




}

