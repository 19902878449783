
// Icons for the header
import HamburgerButton from "../assets/GeneralIcons/CLP-_IconHamburger.svg";

import LogoNew from '../assets/headerLogos/svg/NRLogo.svg'

import AccessCalendar from '../assets/cardIcons/components/AccessCalendar'
import Actions from '../assets/cardIcons/components/Actions'
import Comms from '../assets/cardIcons/components/Comms'
import Info from '../assets/cardIcons/components/Info'
import Issues from '../assets/cardIcons/components/Issues'
import LiveUpdates from '../assets/cardIcons/components/LiveUpdates'
import Location from '../assets/cardIcons/components/Location'
import Logistics from '../assets/cardIcons/components/Logistics'
import Milestones from '../assets/cardIcons/components/Milestones'
import MilestoneStatus from '../assets/cardIcons/components/MilestoneStatus'
import SoftReport from '../assets/cardIcons/components/SoftReport'
import StationReports from '../assets/cardIcons/components/StationReports'
import SummaryI from '../assets/cardIcons/components/Summary'
import PeopleI from '../assets/cardIcons/components/People'

import Performance from '../assets/cardIcons/components/Performance'
import KPIs from '../assets/cardIcons/components/Kpis'
import Programme from '../assets/cardIcons/components/Programme'
import Safety from '../assets/cardIcons/components/Safety'


// Header content
export const headerContent = {

    RightLogo: LogoNew,
    Hamburger: HamburgerButton,
    title: "SCD Kent",
    "mobile-breakpoint": 700

}


export const cardIcons2 = {

    accessCalendar: <AccessCalendar />,
    comms: <Comms />,
    actions: <Actions />,
    info: <Info />,
    issues: <Issues />,
    liveUpdates: <LiveUpdates />,
    location: <Location />,
    logistics: <Logistics />,
    milestones: <Milestones />,
    milestoneStatus: <MilestoneStatus />,
    softReport: <SoftReport />,
    stationReports: <StationReports />,
    summary: <SummaryI />,
    people: <PeopleI />,
    performance: <Performance />,
    kpis: <KPIs />,
    programme: <Programme />,
    safety: <Safety />,

}
