import React from "react";
import { useState, useRef, useEffect, useContext } from 'react';

import { mapStyles } from "../../constants/mapStyles";

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax


import "mapbox-gl/dist/mapbox-gl.css";


import { FirebaseDataContext } from '../../Context/FirebaseDataContext';
import { useNavigate, useParams, Link } from 'react-router-dom'

import './mapbox.css'

mapboxgl.accessToken = mapStyles.accessToken;



const ProjectMap = (props) => {

    const navigate = useNavigate()

    const data = useContext(FirebaseDataContext)


    const params = useParams();
    const selectedItemId = params.id;
    // const [coordinates, setCoordinates] = useState([0.09464420655139119, 52.21581857944906])



    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.forEach((item) => {
            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }

    const currentItemName = selectedItemId ? selectCurrentItem(data.projectData)[0].projectName : ""

    const currentItemData = selectCurrentItem(data.projectData)[0]

    const lonData = selectCurrentItem(data.projectData)[0].lon
    const latData = selectCurrentItem(data.projectData)[0].lat

    const coordinates = [lonData, latData]
    const address = currentItemName



    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(0.09464420655139119);
    const [lat, setLat] = useState(52.21581857944906);
    const [zoom, setZoom] = useState(16);

    // 52.291581857944906, 0.09464420655139119
    function createPopUp(liftDataItem) {

        const popUps = document.getElementsByClassName('mapboxgl-popup');
        if (popUps[0]) popUps[0].remove();

        const popup = new mapboxgl.Popup({ closeOnClick: true })
            .setLngLat([coordinates[0], coordinates[1] - 0.0008])
            .setHTML(`<h3>${currentItemName}</h3>
        <h4>${address}</h4>
            `
            )
            .addTo(map.current);
        popup._update()
    }



    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyles.mapStyle,
            center: coordinates,
            zoom: zoom,
            preserveDrawingBuffer: true
        });

    }, []);

    useEffect(() => {
        if (map.current) {
            map.current.flyTo({
                center: coordinates,
                zoom: 13.5
            })
            let marker = new mapboxgl.Marker().setLngLat({ lng: coordinates[0], lat: coordinates[1] }).addTo(map.current);

            // createPopUp(currentItemData)
        }
        setLng(coordinates[0])
        setLat(coordinates[1])


    }, [currentItemData, map.current]);

    return (
        <div className="map-card">

            <div ref={mapContainer} className="map-container-project" />

        </div>
    );

}

export default ProjectMap;