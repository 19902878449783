import React, { useEffect, useState } from 'react';

import './bar.css';
import BarComponent from './BarComponent';

const HorizontalBarChart = ({ data, dataType }) => {

  function workTypeGenerator(data) {
    const workType = data.map((item) => item["workType"])
    const workTypeUnique = [...new Set(workType)]
    return workTypeUnique
  }

  function workTypeCombined(data) {
    let workTypeFiltered = workTypeGenerator(data)
    let workTypeList = workTypeFiltered.map((item) => {
      return { discipline: item }
    })
    let output = workTypeList.map((item) => {
      let quantity = data.filter((dataItem) => dataItem.workType === item.discipline).length
      let complete = data.filter((dataItem) => dataItem.workType === item.discipline && dataItem.Status === "Complete").length

      return {
        ...item,
        quantity: quantity,
        complete: complete,
        outstanding: quantity - complete

      }
    })
    return output
  }

  function volumeTypeGenerator(data) {
    const volumeType = data.map((item) => item["Volume KVL"])
    const volumeTypeUnique = [...new Set(volumeType)]
    return volumeTypeUnique
  }

  function volumeCalc(data) {
    let sum = 0
    data.forEach(item => {
      sum += item.volumes
    })
    return sum
  }

  function volumesCombined(data) {
    let volumeTypes = volumeTypeGenerator(data)
    let volumeTypeList = volumeTypes.map((item) => {
      return { discipline: item }
    })

    let output = volumeTypeList.map((item) => {
      let dataFiltered = data.filter((dataItem) => dataItem["Volume KVL"] === item.discipline)
      let quantity = Math.round(volumeCalc(dataFiltered))
      let complete = volumeCalc(dataFiltered.filter((item) => item.Status === "Complete"))

      return {
        ...item,
        quantity: quantity,
        complete: complete,
        outstanding: quantity - complete

      }
    })
    return output

  }
  const dataFunctions = {
    ProjectQty: workTypeCombined,
    VolumeTotal: volumesCombined
  }

  const [summarydata, setSummaryData] = useState(dataFunctions[dataType](data))

  useEffect(() => {
    setSummaryData(dataFunctions[dataType](data))

  }, [data, dataType])

  const longestWord = summarydata.reduce((acc, obj) => {
    const word = obj.discipline

    if (word.length > acc.length) {
      return word;
    } else {
      return acc;
    }
  }, '');

  const containerHeight = heightCalculator(summarydata);
  const titleTextwidth = Math.round(longestWord.length / 1.7);


  function heightCalculator(data) {
    let output = 0;
    if (data.length === 1) {
      output = 100
    }
    else if (data.length > 1 && data.length < 8) {
      output = 200 / data.length
    }
    else if (data.length > 7) { output = 30 }
    return output;
  }

  function dataManipulation(data) {
    let highestValue = 0;
    let output = data.map((item, index) => {
      return {
        discipline: item.discipline,
        outstanding: item.outstanding,
        complete: item.complete,
        total: item.outstanding + item.complete,
      };
    });
    output.sort((a, b) => b.total - a.total);
    highestValue = output[0].total;
    output = output.map((item, index) => {
      return {
        discipline: item.discipline,
        outstanding: item.outstanding,
        complete: item.complete,
        total: item.total,
        containerwidth: (item.total / highestValue) * 100,
      };
    });
    return output;
  };

  const filteredData = dataManipulation(summarydata);


  return (
    <>
      <div className='bar-chart'>
        {filteredData.map((item, index) => (
          <BarComponent
            key={index}
            value1={item.complete}
            value2={item.outstanding}
            title={item.discipline}
            containerwidth={item.containerwidth}
            containerHeight={containerHeight}
            titleTextwidth={titleTextwidth}
          />
        ))}
      </div>
      <div className='bar-btn-main-container'>
        <div className='bar-btn-container'>
          <div className='clr-sq completed-clr'></div>
          <div className='bar-btn-text'>Complete</div>
        </div>
        <div className='bar-btn-container'>
          <div className='clr-sq outstanding-clr'></div>
          <div className='bar-btn-text'>Not started</div>
        </div>
      </div>
    </>
  );
};

export default HorizontalBarChart;
