import React from 'react'

const StationReports = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Fill">
                <path d="m24.0768,24.4303H3.9235c-.6162,0-1.168-.3184-1.4756-.8516s-.3086-1.1699-.001-1.7036L12.5241,4.4215h0c.3086-.5332.8604-.8516,1.4766-.8516.6152,0,1.167.3184,1.4746.8516l10.0771,17.4536c.3076.5332.3076,1.1699,0,1.7031-.3076.5337-.8594.8521-1.4756.8521ZM13.1745,4.7965L3.0974,22.2501c-.1729.2983-.1729.6553,0,.9536s.4814.4766.8262.4766h20.1533c.3447,0,.6533-.1782.8262-.4771.1719-.2983.1719-.6548-.001-.9531L14.8259,4.7965c-.3438-.5977-1.3076-.5957-1.6514,0h0Z" fill="#ff4c29" />
            </g>
            <g id="Content">
                <g>
                    <g>
                        <path d="m14.1497,15.9686l1.6063-6.0017c.0263-.0984-.0478-.195-.1497-.195h-3.2126c-.1019,0-.176.0966-.1497.195l1.6063,6.0017c.041.1532.2584.1532.2994,0Z" fill="#ff4c29" />
                        <path d="m14.0007,16.3336c-.1865,0-.3438-.1206-.3916-.2998l-1.6064-6.0024c-.0332-.1226-.0078-.2505.0693-.3511s.1943-.1582.3213-.1582h3.2129c.127,0,.2441.0576.3213.1582.0771.1001.1025.2285.0693.3511l-1.6055,6.0024c-.0479.1792-.2051.2998-.3906.2998Zm-.0918-.4302v.0005-.0005Zm-1.3916-5.8813l1.4824,5.54,1.4824-5.54h-2.9648Z" fill="#ff4c29" />
                    </g>
                    <g>
                        <circle cx="14" cy="19.3305" r="1.3116" fill="#ff4c29" />
                        <path d="m13.9997,20.8922c-.8613,0-1.5615-.7007-1.5615-1.5615,0-.8613.7002-1.562,1.5615-1.562s1.5615.7007,1.5615,1.562c0,.8608-.7002,1.5615-1.5615,1.5615Zm0-2.6235c-.585,0-1.0615.4766-1.0615,1.062s.4766,1.0615,1.0615,1.0615,1.0615-.4761,1.0615-1.0615-.4766-1.062-1.0615-1.062Z" fill="#ff4c29" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default StationReports