import './Header.css';

import Hamburger from '../../assets/GeneralIcons/Hamburger';
import { headerContent } from "../../constants/appStructure";

function Header({ hamburgerButtonHandler, titleAddition }) {
    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/);
    let fill = "#fff"

    return (
        <>
            {urlArray[urlArray.length - 1] === "form"

                || urlArray[urlArray.length - 2] === "form"
                || urlArray[urlArray.length - 2] === "deletereview"
                || urlArray[urlArray.length - 1] === "logoutreview"
                || urlArray[urlArray.length - 1] === "nomobiledisplay" ?


                <></>
                :

                <header className="header">

                    <>
                        <div className="header-left">
                            {urlArray[urlArray.length - 1] === "form"
                                || urlArray[urlArray.length - 2] === "projectpage"
                                || urlArray[urlArray.length - 2] === "form"
                                || urlArray[urlArray.length - 2] === "deletereview"
                                || urlArray[urlArray.length - 1] === "logoutreview"
                                || urlArray[urlArray.length - 1] === "nomobiledisplay" ?
                                <></>
                                :

                                urlArray[urlArray.length - 1] !== "summary" && <Hamburger fill={fill}
                                    onClick={hamburgerButtonHandler}
                                    className={"header-left-hamburger"} />


                            }


                        </div>
                        <div className="header-title">
                            <div className='header-title-main-text white-text'>{headerContent.title}
                            </div>
                            {urlArray[urlArray.length - 1] !== "summary" ?
                                <div className='header-title-extra-text orange-text '>{titleAddition} </div> : <></>
                            }


                        </div>



                        <div className="header-right">
                            <img src={headerContent.RightLogo} alt="rightlogo" />
                        </div>

                    </>



                </header>
            }

        </>
    );

}

export default Header;

