import React, { useContext, useEffect, useState } from 'react'

import { FirebaseDataContext } from '../../Context/FirebaseDataContext'

import { useParams, Link } from 'react-router-dom';
import Delete from '../../assets/BtnIcon/BI-_Btn-Delete.svg'

import './dashboardContent.css'

import moment from "moment/moment"
const LiveFeedContent = () => {
    const data = useContext(FirebaseDataContext)

    const { projectId } = useParams()
    const [liveFeed, setLiveFeed] = useState([]);

    useEffect(() => {
        if (data.updateLog) {
            setLiveFeed(data.updateLog)
        }
    }, [data, data.updateLog])

    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/);

    const topStatusFilter = (list) => {
        let highlightedIssues = [];
        list.forEach((issue, index) => {
            if (index < 3) {
                highlightedIssues.push(issue)
            }
        })
        return highlightedIssues
    }
    const filteredFeed = topStatusFilter(liveFeed).sort((a, b) => b.date - a.date)

    return (
        <div className='livefeed-main-container'>

            {liveFeed.length > 0 ? <>

                {filteredFeed.map((issue, index) => {
                    return (
                        <div className='site-issue-container' key={index + "feedid"}>
                            {issue.date ? <div className='feed-date-box status-date'>{moment.unix(issue.date).format("DD-MM-YYYY")}</div> : <></>}
                            <div className='feed-title-box issue-detail-container'>
                                <div className='status-text-header-colored  '>{issue.title}</div>
                                <div className=' issue-text-details summary-text-fs1 '>{issue.update}</div>
                            </div>

                            {urlArray[urlArray.length - 1] !== "summary" ?
                                <Link to={`/app/dashboard/${projectId}/livefeeds/delete/${issue.id}`} className="contact-edit-box">
                                    <img className=' contact-edit-image ' src={Delete} alt="" />
                                </Link>
                                :
                                <></>
                            }
                        </div>
                    )
                })}
            </>
                :
                <>
                    <div className='no-issue-text-header-center summary-text-fs1p3'>
                        No updates for this project
                    </div></>

            }

        </div>
    )
}

export default LiveFeedContent