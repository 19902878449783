import React, { useState, useContext } from 'react'

// import BarButton from '../../components/Buttons/BarButton/BarButton'

// import ButtonWhite from '../../assets/ComponentIcons/ButtonIcon01_Button_white.svg'

import { mapInfoToDisplay } from '../../../constants/tableFilters';

import './SideBarRight.css'

import { FirebaseDataContext } from '../../../Context/FirebaseDataContext';

const SideBarRight = ({ assetData, sidebarRightDisplayToggle, fixMapSize, flyToSignal, createPopUp, sideBarRightTitle, featureFilteredData }) => {
    const data = useContext(FirebaseDataContext)


    const [isCollapsed, setCollapse] = useState(false)
        ;
    const [filterSearch, setFilterSearch] = useState("");
    let classNameToggle = "sidebar-right-background";

    if (isCollapsed) {
        classNameToggle += " sidebar-right-collapsed";

    }
    const SidebarRightbuttonFunction = () => {
        setCollapse(!isCollapsed);

    }
    const createPopupTableData = (tableData, featureProperties) => {
        let popUpTableData = {}
        let tablePopUpKeyTitles = tableData.filter((tableKey, index) => tableKey.popUpDisplay)
        tablePopUpKeyTitles.forEach((tableKeyTitleData, index) => {


            popUpTableData[tableKeyTitleData.Title] = featureProperties[tableKeyTitleData.property]
        })
        return popUpTableData;
    }
    const onItemClick = (item, tableData) => {

        flyToSignal(item);
        createPopUp(item, null, /*createPopupTableData(tableData, item.properties)*/);
    }

    return (
        <div className={`sidebar-container-main ${isCollapsed ? 'hidden' : ''}`}>
            <div className='sidebar-close-icon' onClick={(prev) => setCollapse(!isCollapsed)}>x</div>
            <div className={`flex-vertical ${classNameToggle}`}>
                <div className='heading flex-horizontal' >

                    {/* <BarButton
                        className={'button-white-img'}
                        image={ButtonWhite}
                        buttonFunction={SidebarRightbuttonFunction}
                        id={'button-sidebar-right'} /> */}
                    <h3 className="side-bar-right-title">{sideBarRightTitle}</h3>
                </div>
                <div id='listings' className='listings'>
                    <input className="search-input" placeholder={"Search for project"} value={filterSearch} onChange={(event) => setFilterSearch(event.target.value)}
                        type="search" />

                    {
                        featureFilteredData.features.filter((feature) => {
                            if (filterSearch == "")
                                return true;
                            return feature.properties.projectName.toLowerCase().includes(filterSearch.toLowerCase());
                        }).map((feature, index) =>
                            <div id={`listing-${feature.properties.projectName}`} className="item" key={index} onClick={() => onItemClick(feature, {})}>
                                <div className="title" id={`link-${index}`} >
                                    <img src={feature.properties.bullet} alt="bullet" />
                                    {feature.properties.projectName}
                                </div>
                                {mapInfoToDisplay.map((item, index) =>
                                (<div className='list-text' key={index}>{item.displayedTitle}: {feature.properties[item.keyProperty]}</div>
                                ))}

                            </div>
                        )}

                </div>
            </div >
        </div>
    )
}

export default SideBarRight