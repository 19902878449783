import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../Context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'

import { db, uploadFile } from '../firebase'

import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'
import DbCard from '../Components/cards/DbCard'


import { addUpdateLog } from '../constants/UpdateLog'

import './form.css'
import { FirebaseDataContext } from '../Context/FirebaseDataContext'

const PeopleForm = () => {

    const params = useParams();
    const navigate = useNavigate()
    const selectedItemId = params.id

    const userData = useContext(UserContext)
    const data = useContext(FirebaseDataContext)



    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`


    let defaultFormData = {}
    // IE CREATE 
    if (!selectedItemId || selectedItemId === undefined) {
        defaultFormData["name"] = ""
        defaultFormData["company"] = ""
        defaultFormData["role"] = ""
        defaultFormData["email"] = ""
        defaultFormData["number"] = ""
        defaultFormData["image"] = ""
        defaultFormData["inCombined"] = "No"

        defaultFormData["dateCreated"] = new Date()
        defaultFormData["createdBy"] = logInUser
    }
    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser


    }

    const [formData, setFormData] = useState(defaultFormData);


    const handleFormInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            [name]: value
        }))

        if (name === 'inCombined' && value === 'Yes') {
            setFormData((curr) => ({ ...curr, 'param': [...curr.param, "combinedworks"] }))
        } else if (name === 'inCombined' && value === 'No') {
            const tempArray = [...formData.param];
            const removeIndex = tempArray.findIndex((item) => item === 'combinedworks');
            if (removeIndex > -1) {
                tempArray.splice(removeIndex, 1);
            }

            setFormData((curr) => ({ ...curr, 'param': tempArray }))
        }
    }

    const handleCheckboxArrayInput = ({ target }, state, stateArrayKey) => {
        const { name, value } = target
        let updatedCheckboxArray = state[stateArrayKey]
        if (state[stateArrayKey].includes(name) && state[stateArrayKey].length > 1) {
            const removeIndex = updatedCheckboxArray.findIndex((item) => item === name)
            updatedCheckboxArray.splice(removeIndex, 1)
        } else {
            updatedCheckboxArray.push(name)
        }
        setFormData((curr) => ({ ...curr, [stateArrayKey]: updatedCheckboxArray }))
    }

    useEffect(() => {
        if (params.id) {
            const docRef = doc(db, 'KeyPeople', selectedItemId)
            getDoc(docRef).then(snapshot => {
                setFormData({ ...snapshot.data(), id: snapshot.id })
            })
        }
    }, [])

    const updateDataOnFireBase = async () => {
        const collectionRef = doc(db, "KeyPeople", selectedItemId)
        await setDoc(collectionRef, formData);
        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "Person Updated",
            update: `${userName} updated ${formData.name}`
        })
        data.getAllData()
        navigate(`/app/main/summary`)
    }

    const CreateDataEntryToFireBase = async () => {
        let updatedFormData = { ...formData }
        if (!updatedFormData["param"].length) {
            updatedFormData["param"].push("combinedworks")
        }

        await addDoc(collection(db, "KeyPeople"), updatedFormData);

        addUpdateLog({
            name: logInUser,
            date: new Date(),
            title: "New Person Added",
            update: `${userName} added ${formData.name}`,
        })
        data.getAllData()
        navigate(`/app/main/summary`)
    }

    let cardTitle = selectedItemId ? `Edit ${formData["name"]}` : "Create Contact"
    let mainBtnTitle = selectedItemId ? "Update" : "Submit"
    let btnFunction = selectedItemId ? updateDataOnFireBase : CreateDataEntryToFireBase


    const uploadImage = (event) => {
        uploadFile(`Avatars/${formData.email}`, event.target.files[0]).then((downloadUrl) => {

            setFormData((curr) => ({
                ...curr,
                "image": downloadUrl.url
            }))
        })

    }

    const options = ["Yes", "No"]

    return (
        <>

            <div className="dashboard-container">
                <DbCard
                    cardType={'graph-card-wide card-height-min'}
                    icon={""}
                    title={`${cardTitle}`}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Contact`}
                    btnDisplayBool={true}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    OnClickFunc={() => navigate(-1)}>

                    <>  <div className='form-boxes-container'>

                        <div class="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Name</p>
                                <input className='input-box' type="text" value={formData["name"]} name={"name"} placeholder={"Input Name..."} onChange={handleFormInputChange} maxLength={30} required /><br />
                            </label>

                        </div>
                        <div class="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Phone Number</p>
                                <input className='input-box' type="tel" value={formData["number"]} name={"number"} placeholder={"Input Number..."} onChange={handleFormInputChange} maxLength={15} minLength={8} required /><br />
                            </label>

                        </div>
                        <div class="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Email</p>
                                <input className='input-box' type="email" value={formData["email"]} name={"email"} placeholder={"Input Email..."} onChange={handleFormInputChange} required /><br />
                            </label>

                        </div>
                        <div class="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Company</p>
                                <input className='input-box' type="text" value={formData["company"]} name={"company"} placeholder={"Input Company..."} onChange={handleFormInputChange} maxLength={30} required /><br />
                            </label>

                        </div>

                        <div class="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Role</p>
                                <input className='input-box' type="text" value={formData["role"]} name={"role"} placeholder={"Input Job title..."} onChange={handleFormInputChange} maxLength={30} required /><br />
                            </label>

                        </div>





                        <div className='image-container'>
                            {formData["param"] ? <img src={formData["image"]} alt="" className='avatar' />
                                : <div className='none'></div>
                            }


                            <div class="input-container-photo">
                                <label className='photo-label-container'>
                                    <p className='label-title'>Upload an image</p>
                                    <input className='input-box' type="file" onChange={uploadImage} /><br />
                                </label>

                            </div>

                        </div>






                    </div>

                        {/* {selectedItemId} */}
                    </>
                </DbCard>


            </div >
        </>
    )
}

export default PeopleForm