import React, { useContext } from 'react'
import Loading from './Loading'
import { FirebaseDataContext } from '../Context/FirebaseDataContext'
import Dashboard from './Dashboard'

export default function DataLoadedPage() {
    const data = useContext(FirebaseDataContext)

    return (
        !data.loading ? <Dashboard /> : <Loading />


    )
}
