import React from 'react'

const Comms = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m6.2176,24.4298c-1.4608,0-2.6478-1.1879-2.6478-2.6487v-6.4844c0-.7456.6068-1.3515,1.3524-1.3515h3.4581c.2682,0,.4869.2178.4869.4869v7.3489c0,1.4608-1.1888,2.6487-2.6497,2.6487Zm-1.2954-9.5107c-.2092,0-.3785.1693-.3785.3776v6.4844c0,.9235.7513,1.6748,1.6739,1.6748.9244,0,1.6758-.7513,1.6758-1.6748v-6.862h-2.9711Z" fill="#ff4c29" />
                    <path d="m21.3491,24.4298H6.6512c-.2682,0-.4869-.2178-.4869-.4869s.2187-.4869.4869-.4869h14.6979c1.1622,0,2.1076-.9454,2.1076-2.1066V4.9214c0-.2083-.1693-.3776-.3785-.3776h-13.8343c-.2073,0-.3766.1693-.3766.3776v16.4279c0,.2692-.2187.4869-.4869.4869s-.4869-.2178-.4869-.4869V4.9214c0-.7456.6068-1.3515,1.3505-1.3515h13.8343c.7456,0,1.3524.6058,1.3524,1.3515v16.4279c0,1.6986-1.3829,3.0805-3.0815,3.0805Z" fill="#ff4c29" />
                    <path d="m20.4836,18.3781h-8.6452c-.2682,0-.4869-.2178-.4869-.4869s.2187-.4869.4869-.4869h8.6452c.2682,0,.4869.2178.4869.4869s-.2187.4869-.4869.4869Z" fill="#ff4c29" />
                    <path d="m20.4836,20.9717h-8.6452c-.2682,0-.4869-.2178-.4869-.4869s.2187-.4869.4869-.4869h8.6452c.2682,0,.4869.2178.4869.4869s-.2187.4869-.4869.4869Z" fill="#ff4c29" />
                    <path d="m21.3491,14.0545h-10.3762c-.2682,0-.4869-.2178-.4869-.4869v-6.0517c0-.2692.2187-.4869.4869-.4869h10.3762c.2682,0,.4869.2178.4869.4869v6.0517c0,.2692-.2187.4869-.4869.4869Zm-9.8892-.9739h9.4023v-5.0778h-9.4023v5.0778Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Comms