import React, { useContext, useEffect, useState } from 'react'
import DbCard from '../Components/cards/DbCard'
import { UserContext } from '../Context/AuthContext'
import { btnWriteStatusComponentVisibility, FirebaseDataContext } from '../Context/FirebaseDataContext';
import '../CssGeneral/dashboard.css'
import '../CssGeneral/dashboardContent.css'
import { useNavigate, useParams } from 'react-router-dom'
import { cardIcons2 } from '../constants/appStructure'

import CompleteIcon from "../assets/graphIcons/MileStoneCompleteCircle.svg"

import ReforecastArrow from "../assets/graphIcons/ReforecastArrow.svg"

import Blank from "../assets/graphIcons/Blank.svg"

import ProjectMap from '../Components/ProjectMap/ProjectMap';

import moment from 'moment';

import './pages.css'

const ProjectPage = () => {

    const navigate = useNavigate()
    const data = useContext(FirebaseDataContext)
    const userData = useContext(UserContext)
    const [buttonStatus, setButtonStatus] = useState(true)

    useEffect(() => {
        btnWriteStatusComponentVisibility(userData, data, setButtonStatus)
    }, [])

    const params = useParams();
    const selectedItemId = params.id;

    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.forEach((item) => {
            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }

    const currentItemName = selectedItemId ? selectCurrentItem(data.projectData)[0].projectName : ""

    const currentItemData = selectCurrentItem(data.projectData)[0]


    const EditRedirect = () => {
        navigate(`/app/main/form/${selectedItemId}`)
    }

    const ReturnRedirect = () => {
        navigate(`/app/main/table`)
    }

    const DeleteRedirect = () => {
        navigate(`/app/main/deletereview/${selectedItemId}`)
    }

    const statusIconDisplay = (comment) => {

        switch (comment) {
            case "Actual":
                return CompleteIcon
                break;
            case "Forecast":
                return ReforecastArrow
                break;
            case "Planned":
                return Blank
                break;
            default:
                break;
        }

    }


    const completionDateOutput = (status) => {
        switch (status) {
            case "Actual":
                return <div className='pp-details-row summary-text-fs1'>
                    <span className='pp-bold-title'>Actual Commissioning:</span>
                    {moment(currentItemData["Commissioning Date"]).format('DD/MM/YYYY')}<img src={CompleteIcon} alt="" className="comp-icon-pp" />
                </div>
                break;
            case "Forecast":
                return <div className='pp-details-row summary-text-fs1'>
                    <span className='pp-bold-title'>Reforecast Commissioning:</span>
                    {moment(currentItemData["Commissioning Date"]).format('DD/MM/YYYY')}<img src={ReforecastArrow} alt="" className="comp-icon-pp" />
                </div>
                break;
            case "Planned":
                return <div className='pp-details-row summary-text-fs1'>
                    <span className='pp-bold-title'>Planned Commissioning:</span>
                    {moment(currentItemData["Commissioning Date"]).format('DD/MM/YYYY')}<img src={Blank} alt="" className="comp-icon-pp" />
                </div>
                break;
            default:
                break;
        }


    }


    return (
        <div className="dashboard-container">
            <DbCard
                cardType={'graph-card-wide card-static'}
                icon={cardIcons2.actions}
                title={currentItemName}
                topRowDisplayBool={true}
                cardContent={""}
                btnText={"Back"}
                mainBtnText={"Edit"}
                btnDisplayBool={true}
                btnMainDisplayBool={buttonStatus}
                OnClickFuncMainBtn={EditRedirect}
                OnClickFunc={ReturnRedirect}
            >

                <div className='project-page-main-container'>

                    <div className='project-page-content-container'>

                        <div className='project-page-details-container'>

                            <div className='pp-details-sub-title '>Details</div>

                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Project Location:</span>
                                {currentItemData.projectName}
                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Route:</span>
                                {currentItemData.route}
                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Project OP Number:</span>
                                {currentItemData.OPNumber
                                }
                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Work Type:</span>
                                {currentItemData.workType}
                            </div>

                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Sub category:</span>
                                {currentItemData["subCategory"] ? currentItemData["subCategory"] : <div>N/A</div>}
                            </div>


                            <div className='pp-details-sub-title pp-details-sub-title-margin '>Project Dates</div>

                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Status:</span>
                                {currentItemData.Status}

                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Period:</span>
                                {currentItemData["Commissioning Period"]}

                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Year:</span>
                                {currentItemData["Commissioning Year"]}

                            </div>

                            <>{completionDateOutput(currentItemData.dateStatusComment)} </>


                        </div>
                        <div className='project-page-details-container'>

                            <div className='pp-details-sub-title '>Volume</div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Volume KVL:</span>
                                {currentItemData["Volume KVL"] ? currentItemData["Volume KVL"] : <div>N/A</div>}

                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Volume:</span>
                                {currentItemData["volumes"] ? `${currentItemData["volumes"]} ${currentItemData["volumeUnit"]}` : <>N/A</>}
                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Sub Contractor:</span>
                                {currentItemData["subcontractor"] ? currentItemData["subcontractor"] : <></>}
                            </div>

                            <div className='pp-details-sub-title '>NR Project Team</div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Route Delivery Director:</span>
                                {currentItemData["routeDeliveryDirector"] ? currentItemData["routeDeliveryDirector"] : <></>}

                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Programme Manager:</span>
                                {currentItemData["programmeManager"] ? currentItemData["programmeManager"] : <></>}
                            </div>
                            <div className='pp-details-row summary-text-fs1'>
                                <span className='pp-bold-title'>Principal Planner:</span>
                                {currentItemData["principalPlanner"] ? currentItemData["principalPlanner"] : <></>}
                            </div>





                        </div>
                        <div className='project-page-map-container'>
                            <ProjectMap />
                        </div>
                    </div>
                    {/* <div className='pp-seperator'></div>
                    <div className='project-page-graph-container'>
                        <ProjectGraph />
                    </div> */}



                    <div className='delete-section'>
                        {buttonStatus ? <button className="card-top-btn-main " onClick={DeleteRedirect}><h4 className="card-top-btn-text" >Delete this location?</h4></button> : <></>}
                    </div>





                </div>

            </DbCard>
        </div >
    )
}

export default ProjectPage