
// CIVILS HEADERS

// Title is what is displayed on the screen
// property is the object key


export const mapInfoToDisplay = [
    {
        displayedTitle: 'Sub Category',
        keyProperty: "subCategory",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    {
        displayedTitle: 'OP number',
        keyProperty: "OPNumber",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        displayedTitle: 'Route',
        keyProperty: "route",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    {
        displayedTitle: 'PM',
        keyProperty: "programmeManager",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },

    {
        displayedTitle: 'Period',
        keyProperty: "Commissioning Period",
        sideBarRightDisplay: true,
        popUpDisplay: false
    },
    {
        displayedTitle: 'Year',
        keyProperty: "Commissioning Year",
        sideBarRightDisplay: true,
        popUpDisplay: false
    },
    {
        displayedTitle: 'Status',
        keyProperty: "Status",
        sideBarRightDisplay: true,
        popUpDisplay: true
    },
    // {
    //     displayedTitle: 'Year',
    //     keyProperty: "Year",
    //     sideBarRightDisplay: true,
    //     popUpDisplay: true
    // },
    // {
    //     displayedTitle: 'Period',
    //     keyProperty: "Period",
    //     sideBarRightDisplay: true,
    //     popUpDisplay: false
    // },


]
