import React from "react";
import Graph from "./Graph";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default function LineGraph(prop) {

    const volumeGraphStyle = (chart, id) => {
        let graph = am4core.create(id, am4charts.XYChart);

        graph.responsive.enabled = true;
        am4core.options.autoSetClassName = true;
        // Enable chart cursor
        graph.cursor = new am4charts.XYCursor();
        graph.cursor.lineX.disabled = true;
        graph.cursor.lineY.disabled = true;
        graph.cursor.behavior = "none"

        // Add data
        graph.data = prop.data;
        // Create axes
        const data = prop.data;

        /* Create axes */
        var categoryAxis = graph.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "TimeLine";
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.strokeWidth = 0;
        categoryAxis.id = "myCategoryAxis";

        /* Create value axis */
        var valueAxis = graph.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeWidth = 1;
        valueAxis.min = 0;
        valueAxis.id = "myValueAxis";
        valueAxis.strictMinMax = true;

        let valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#1b1a27");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.fontSize = 16;
        valueAxisTooltip.dy = 5;
        valueAxis.cursorTooltipEnabled = true;

        let categoryAxisTooltip = categoryAxis.tooltip;
        categoryAxisTooltip.background.fill = am4core.color("#1b1a27");
        categoryAxisTooltip.background.strokeWidth = 0;
        categoryAxisTooltip.background.cornerRadius = 3;
        categoryAxisTooltip.background.pointerLength = 0;
        categoryAxisTooltip.fontSize = 16;
        categoryAxisTooltip.dy = 5;


        var plannedSeries = graph.series.push(new am4charts.ColumnSeries());
        plannedSeries.name = "Planned";
        plannedSeries.dataFields.valueY = "planned";
        plannedSeries.dataFields.categoryX = "TimeLine";
        plannedSeries.strokeWidth = 0;
        plannedSeries.columns.template.width = am4core.percent(50);
        plannedSeries.propertyFields.strokeDasharray = "lineDash";
        plannedSeries.fill = am4core.color("#6b66da");
        plannedSeries.fillOpacity = 0.15;

        plannedSeries.columns.template.tooltipText = `[#fff font-size: 15px]{name} in \n[/] [#fff font-size: 15px]${prop.timeUnit}{categoryX}:\n[/][#fff font-size: 20px] {valueY} ${prop.units}[/]`;
        plannedSeries.columns.template.tooltipX = am4core.percent(50);
        plannedSeries.columns.template.tooltipY = am4core.percent(0);
        plannedSeries.tooltip.background.cornerRadius = 5;
        plannedSeries.tooltip.getFillFromObject = false
        plannedSeries.tooltip.background.fill = am4core.color("#6b66da")
        plannedSeries.tooltip.background.strokeOpacity = 0;
        // plannedSeries.tooltip.pointerOrientation = "right";
        plannedSeries.tooltip.label.minWidth = 20;
        plannedSeries.tooltip.label.minHeight = 20;
        plannedSeries.tooltip.label.textAlign = "middle";
        plannedSeries.tooltip.label.TextValign = "middle";
        plannedSeries.tooltip.label.paddingLeft = "10"
        plannedSeries.tooltip.label.paddingRight = "10"
        plannedSeries.tooltip.label.paddingTop = "10"
        plannedSeries.tooltip.label.paddingBottom = "10"
        plannedSeries.tooltip.dy = -25;
        // plannedSeries.tooltip.ignoreBounds = true


        let forecastSeries = graph.series.push(new am4charts.ColumnSeries());
        forecastSeries.name = "Forecast";
        forecastSeries.dataFields.valueY = "forecast";
        forecastSeries.dataFields.categoryX = "TimeLine";
        forecastSeries.strokeWidth = 0;
        forecastSeries.fill = am4core.color("#ffd300");

        forecastSeries.columns.template.width = am4core.percent(50);
        // forecastSeries.columns.template.tooltipText = `[#fff font-size: 15px]{name} in \n[/] [#fff font-size: 15px]${prop.timeUnit}{categoryX}:\n[/][#fff font-size: 20px] {valueY} ${prop.units}[/]`;
        // forecastSeries.columns.template.tooltipX = am4core.percent(100);
        // forecastSeries.columns.template.tooltipY = am4core.percent(50);
        // forecastSeries.tooltip.background.cornerRadius = 5;
        // forecastSeries.tooltip.background.strokeOpacity = 0;
        // forecastSeries.tooltip.pointerOrientation = "top";
        // forecastSeries.tooltip.label.minWidth = 20;
        // forecastSeries.tooltip.label.minHeight = 20;
        // forecastSeries.tooltip.label.textAlign = "middle";
        // forecastSeries.tooltip.label.TextValign = "middle";
        // forecastSeries.tooltip.label.paddingLeft = "10"
        // forecastSeries.tooltip.label.paddingRight = "10"
        // forecastSeries.tooltip.label.paddingTop = "10"
        // forecastSeries.tooltip.label.paddingBottom = "10"


        let actualSeries = graph.series.push(new am4charts.ColumnSeries());
        actualSeries.name = "Complete";
        actualSeries.dataFields.valueY = "actual";
        actualSeries.dataFields.categoryX = "TimeLine";
        actualSeries.strokeWidth = 0;
        actualSeries.fill = am4core.color("#ff4c29");
        // actualSeries.fill = am4core.color("#ffd300");
        actualSeries.columns.template.width = am4core.percent(50);
        actualSeries.columns.template.tooltipText = `[#fff font-size: 15px]{name} in \n[/] [#fff font-size: 15px]${prop.timeUnit}{categoryX}:\n[/][#fff font-size: 20px] {valueY} ${prop.units}[/]`;
        actualSeries.columns.template.tooltipX = am4core.percent(100);
        actualSeries.columns.template.tooltipY = am4core.percent(50);

        actualSeries.tooltip.background.cornerRadius = 5;
        actualSeries.tooltip.background.strokeOpacity = 0;
        actualSeries.tooltip.pointerOrientation = "top";
        actualSeries.tooltip.label.minWidth = 20;
        actualSeries.tooltip.label.minHeight = 20;
        actualSeries.tooltip.label.textAlign = "middle";
        actualSeries.tooltip.label.TextValign = "middle";
        actualSeries.tooltip.label.paddingLeft = "10"
        actualSeries.tooltip.label.paddingRight = "10"
        actualSeries.tooltip.label.paddingTop = "10"
        actualSeries.tooltip.label.paddingBottom = "10"




        //add legend
        graph.legend = new am4charts.Legend();



        plannedSeries.legendSettings.labelText = "[font-size: 16px]Baseline";
        forecastSeries.legendSettings.labelText = "[font-size: 16px]Reforecast";
        actualSeries.legendSettings.labelText = "[font-size: 16px]Complete";

        chart.current = graph;
        return graph;
    }

    return (
        <Graph applyStyle={volumeGraphStyle} graphStyle={prop.graphStyle} graphRefresh={prop.graphRefresh} graphRefresh2={prop.graphRefresh2} />
    )
}