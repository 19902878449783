import React from 'react'

import '../../CssGeneral/dashboard.css'

const DbCard = ({ cardType, icon, title, cardContent, children, btnText, btnDisplayBool, btnMainDisplayBool, OnClickFuncMainBtn, OnClickFunc, mainBtnText, warningDisplayBool, warningContent, topRowDisplayBool, onSearch, filterDisplayBool, onSearchParam, searchParams, activeSearchParam, searchQuery }) => {

    const btnDisplay = btnDisplayBool
    const btnMainDisplay = btnMainDisplayBool
    const warningDisplay = warningDisplayBool
    const topRowIconDisplay = topRowDisplayBool
    const filterInputDisplay = filterDisplayBool

    return (
        <div className={`grid-item ${cardType}`}>
            <div className='card-top-row'>
                <div className=' card-title-container'>
                    <div className='card-icon-container'>
                        {topRowIconDisplay ? <div className='card-icon'>{icon}
                        </div> : <></>}

                    </div>

                    <h3 className='card-title'>{title}</h3>
                </div>

                {/* {filterInputDisplay ? <div className='filter-option-container'>
                    <div className='filter-title summary-text-fs09'>Filter by:</div>
                    <select onChange={onSearchParam} >
                        {searchParams.map((param, index) => { return <option key={index} value={index}>{param.name}</option> })}
                    </select>
                    <input className='filter-select-box' type="text" value={searchQuery} placeholder={`Search by ${activeSearchParam.name}`} onChange={onSearch} />
                </div> :
                    <></>} */}

                <div className='right-btn-container'>
                    {warningDisplay ? <div className=" summary-text-fs09 warning-text milestone-warning">{warningContent}</div> : <></>}
                    {btnMainDisplay ? <button className="card-top-btn-main " onClick={OnClickFuncMainBtn}><h4 className="card-top-btn-text" >{mainBtnText}</h4></button> : <></>}
                    {btnDisplay ? <button className="card-top-btn " onClick={OnClickFunc}><h4 className="card-top-btn-text" >{btnText}</h4></button> : <></>}
                </div>

            </div>
            <div className={`card-content ${cardContent}`}>
                {children}
            </div>
        </div>
    )
}

export default DbCard