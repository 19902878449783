import React, { useContext, useState, useEffect } from "react";
import { FirebaseDataContext } from "../Context/FirebaseDataContext";

import { db } from "../firebase";

import { deleteDoc, doc } from 'firebase/firestore'
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import DbCard from "../Components/cards/DbCard";

import { cardIcons2 } from '../constants/appStructure.js'

import '../Components/dashboardComponents/dashboardContent.css'


const DeletePeopleCheck = () => {

    const params = useParams();
    const data = useContext(FirebaseDataContext)

    const navigate = useNavigate()
    const projectId = params.projectId;
    const selectedItemId = params.id
    const location = useLocation()
    const contacts = data.keyPeople

    const [selectedUser, setSelectedUser] = useState([])
    useEffect(() => {
        currentPersonFinder(contacts, selectedItemId)
    }, [])


    const currentPersonFinder = (peopleList, personId) => {
        let selectedPerson = []
        peopleList.forEach((person, index) => {

            if (person.id == personId) {
                selectedPerson.push(person)
            }
        })



        setSelectedUser(selectedPerson)
        //return selectedPerson[0].name
    }

    const firebaseFolder = "KeyPeople";
    const navigatedPath = `/app/dashboard/${projectId}/people`;


    const handleDelete = async () => {
        await deleteDoc(doc(db, firebaseFolder, selectedItemId));
        data.getAllData()
        navigate(navigatedPath)

    };


    return (

        <div className="dashboard-container">
            <DbCard
                cardType={'graph-card-wide card-height-min'}
                icon={cardIcons2.issues}
                title={`Delete`}
                cardContent={""}
                btnText={"Go back"}
                mainBtnText={`Delete`}
                btnDisplayBool={true}
                btnMainDisplayBool={true}
                OnClickFuncMainBtn={handleDelete}
                OnClickFunc={() => navigate(-1)}>

                <div className="delete-details-container">
                    <div className="delete-text summary-text-fs1p3">Are you sure you want to delete <span className="orange-name">{selectedUser[0]?.name}</span> ?
                    </div>
                </div>




            </DbCard>


        </div >

    )
}

export default DeletePeopleCheck