import React from 'react'

const LiveUpdates = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m20.1732,24.8873H5.4463c-1.286,0-2.3332-1.0463-2.3332-2.3332V7.8263c0-1.2869,1.0472-2.3342,2.3332-2.3342h7.0188v.9856h-7.0188c-.7431,0-1.3476.6045-1.3476,1.3485v14.7278c0,.7431.6045,1.3476,1.3476,1.3476h14.7268c.745,0,1.3495-.6045,1.3495-1.3476v-7.3644h.9856v7.3644c0,1.2869-1.0472,2.3332-2.3351,2.3332Z" fill="#ff4c29" />
                    <g>
                        <path d="m15.7763,16.6037h-4.3795v-4.3795L20.0942,3.5257c.5486-.5496,1.4477-.5506,2.0002-.001l2.3794,2.3813c.5506.5515.5506,1.4477,0,1.9992l-8.6975,8.6985Zm-3.3939-.9856h2.9858l8.4087-8.4097c.1675-.1665.1675-.4389,0-.6054l-2.3794-2.3804c-.1656-.1656-.4408-.1646-.6064.001l-8.4087,8.4087v2.9858Z" fill="#ff4c29" />
                        <rect x="19.6814" y="5.2221" width=".9855" height="5.2074" transform="translate(.3763 16.56) rotate(-45.0075)" fill="#ff4c29" />
                        <rect x="10.7788" y="16.0782" width="1.3013" height=".9855" transform="translate(-8.3698 12.9354) rotate(-45)" fill="#ff4c29" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default LiveUpdates