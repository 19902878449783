import React, { useState } from 'react'



const StatsCard = ({ cardType, title, cardContent, children, btnDisplayBool, icon, iconDisplayBool }) => {
    const btnDisplay = btnDisplayBool
    const iconDisplay = iconDisplayBool

    return (
        <div className={`grid-item card ${cardType}`}>
            <div className='card-top-row'>
                <div className=' card-title-container'>

                    {iconDisplay ? <div className='card-icon-container'>
                        <div className='card-icon'>{icon}
                        </div>
                    </div> : <></>}

                    <h3 className='card-title-stats'>{title}</h3>

                </div>
                <div className='right-btn-container'>


                    {/* {btnDisplay ? <button className="card-top-btn " onClick={() => setSites(!isSites)}><h4 className="card-top-btn-text" >{isSites ? 'Site' : 'Volume'}</h4></button> : <></>} */}
                </div>
            </div>
            <div className={`card-content ${cardContent}`}>
                {children}
            </div>
        </div>
    )
}

export default StatsCard