import React, { useEffect } from 'react'
import GanttGraph from '../Components/Graph/gantt-graph'

import './graphStyle.css'

const GanttLayout = ({ data, selectedYear, startTime, endTime, graphRefresh }) => {


    return (
        <>

            {data?.length > 0 ? <div className='gantt-graph'>
                <GanttGraph data={data}
                    selectedYear={selectedYear}
                    startTime={startTime}
                    endTime={endTime}
                    graphRefresh={graphRefresh}
                />
            </div> : <div className="no-data-container summary-text-fs1p1 ">No data based on the selected filters!</div>}


        </>


    )
}

export default GanttLayout