import React, { useContext } from 'react'

import BarChart from '../../Components/Graph/bar-graph-v2';

import '../../CssGeneral/dashboard.css'
import '../../CssGeneral/dashboardContent.css'
import '../../displayStyles/SummaryStyle.css'


import { FirebaseDataContext } from '../../Context/FirebaseDataContext';

const ProjectBarGraph = ({ workbankData, workbankDateTitle, units, timeUnit }) => {

    const data = useContext(FirebaseDataContext)

    const { periodList, yearsList } = data


    function barChartDataGenerator(data, workbankDateTitle) {
        let output = []
        let timeLine = yearsList
        let timeType = ""

        if (workbankDateTitle == "Workbank") {
            timeLine = yearsList
            timeType = "Year"
        } else {
            timeLine = periodList
            timeType = "Period"
        }

        timeLine.forEach((timePeriod) => {
            // time period is the year or period
            let forecastCheck = (data.filter((dataItem) => dataItem[`Forecast_Commissioning ${timeType}`] === timePeriod).length) + (data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod && dataItem[`Forecast_Commissioning ${timeType}`] === "").length)

            output.push({
                TimeLine: timePeriod,
                reforecast: data.filter((dataItem) => dataItem[`Forecast_Commissioning ${timeType}`] === timePeriod).length,
                planned: data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod).length,
                actual: data.filter((dataItem) => dataItem[`Actual_Commissioning ${timeType}`] === timePeriod).length,
                unchanged: data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod && dataItem[`Forecast_Commissioning ${timeType}`] === "").length,
                forecast: forecastCheck !== data.filter((dataItem) => dataItem[`Planned_Commissioning ${timeType}`] === timePeriod).length ? forecastCheck : 0
            })
        })
        console.log(output)
        return output
    }
    return (
        <div className='summ-top-graph-container'>
            <div className='graph-container'>
                <BarChart data={barChartDataGenerator(workbankData, workbankDateTitle)}
                    units={units}
                    timeUnit={timeUnit}
                    graphRefresh={workbankData}


                /></div>

        </div>
    )
}

export default ProjectBarGraph