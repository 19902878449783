import React from 'react'

import "./semicircle.css";

const StatusHalfPie = ({ percentage, circleColor }) => {
    return (
        <svg
            id="Semi_circle"
            data-name="Semi circle"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 108.9863 62.515"
        >
            <g id="base-layer">
                <path
                    d="M7.0279,54.9901C7.0279,28.7758,28.2788,7.5249,54.4932,7.5249s47.4652,21.2509,47.4652,47.4652"
                    fill="none"
                    stroke="#e6e5e8b6"
                />
            </g>
            <g id="indicator">
                <path
                    d="M7.0279,54.9901C7.0279,28.7758,28.2788,7.5249,54.4932,7.5249s47.4652,21.2509,47.4652,47.4652"
                    fill="none"
                    id="progress"

                    style={{
                        "--value": 198 - 1.48 * percentage,
                        "--strokeColor": circleColor,
                    }}
                />
                <text
                    x="50%"
                    y="70%"
                    id="percentageText"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill={circleColor}
                >
                    {percentage}%
                </text>
            </g>
        </svg>
    );
}

export default StatusHalfPie