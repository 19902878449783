import { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import moment from 'moment/moment';

// Data
import { FirebaseDataContext, getFilteredChartData, geoFeature, getAllPeriodsFromData } from '../Context/FirebaseDataContext';

// Graph library
import * as am4core from "@amcharts/amcharts4/core";

// Components
import Header from '../Components/Header/Header.js';
import SideBar, { clearMenuSelections } from '../Components/Sidebar/Sidebar.js'
import FloatingButtons from '../Components/floatingButton/FloatingButtons.js'

// Constants & Custom Hooks
import { graphStyles } from '../constants/graphStyle';
import workTypeDetails from "../constants/workTypeDetails"
import NoMobileView from './NoMobileView';
import { headerContent } from '../constants/appStructure';

// Page Styles
import SummaryStyle from '../displayStyles/SummaryStyle';
import MapStyle from '../displayStyles/MapStyle';
import GanttLayout from '../displayStyles/GanttLayout';

// Pages
import ProjectReview from '../pages/ProjectReview'
import ProjectForm from '../pages/ProjectForm'
import PeopleForm from '../pages/PeopleForm'
import DeleteCheck from './DeleteCheck';
import DeletePeopleCheck from './DeletePeopleCheck';
import LogOutReview from './LogOutReview';
import ProjectPage from './ProjectPage';
import TableNew from '../displayStyles/TableNew/TableNew';

// CSS
import '../Components/Sidebar/Sidebar.css'
import '../Components/GraphCardBasic/GraphCardBasic.css'
import '../App.css';

am4core.addLicense(graphStyles.accessToken);

const Dashboard = () => {

    const params = useParams();

    // CONTEXT DATA
    const data = useContext(FirebaseDataContext)

    const newData = data.projectData;
    const workTypeList = data.workTypeList
    const yearsList = data.yearsList
    const basePeriodsList = data.periodList

    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/);

    useEffect(() => {
        newData.filter((item) => {
            const workType = (item?.workType ?? "other")
            return Object.keys(workTypeDetails).includes(workType)
        }).map((item) => {
            item.color = am4core.color(item.color ?? "white")
            return item;
        });

    }, [])

    const displayStyles = ["Summary", "Table", "Map", "Timeline"]

    const defaultFormValues = {
        tickedWorkTypeNames: [],
        selectedRoute: "",
        selectedStatus: "",
        selectedYear: "",
        selectedPeriod: "",
        periodList: basePeriodsList
    }

    const [tickedWorkTypeNames, setTickedWorkTypeNames] = useState([])

    const [selectedStatus, setSelectedStatus] = useState(defaultFormValues.selectedStatus);
    const [selectedYear, setSelectedYear] = useState(defaultFormValues.selectedYear)

    const [selectedPeriod, setSelectedPeriod] = useState(defaultFormValues.selectedPeriod)


    const [displayStyle, setDisplayStyle] = useState(params["*"])
    const currentDisplayStyle = displayStyle;


    const sidebarIsWide = () => document.getElementsByClassName("side-bar-container").length > 0;

    // Filters data to be used in the app based on sidebar filters
    const filteredData = getFilteredChartData(newData, tickedWorkTypeNames, selectedYear, selectedStatus, selectedPeriod)

    const [periodList, setPeriodList] = useState(defaultFormValues.periodList)

    useEffect(() => {

        setPeriodList(getAllPeriodsFromData(filteredData))

    }, [selectedYear])



    // Converts the combined filter to feature
    const filteredFeatureData = geoFeature(filteredData)


    const [startTime, setStartTime] = useState(new Date(2022, 10, 1, 24, 0, 0, 0).getTime())
    const [endTime, setEndTime] = useState(new Date(2028, 5, 1, 24, 0, 0, 0).getTime())



    useEffect(() => {
        if (filteredData.length > 0) {

            setStartTime(moment(filteredData[0]["Commissioning Date"]).subtract(3, 'months').format('YYYY-MM-DD'));
            setEndTime(moment(filteredData[filteredData.length - 1]["Commissioning Date"]).add(1, 'months').format('YYYY-MM-DD'));

        }
    }, [filteredData])

    const [titleAddition, setTitleAddition] = useState(``)



    useEffect(() => {

        setTitleAddition(`${tickedWorkTypeNames.length === 1 ? tickedWorkTypeNames : ""}  ${selectedYear ? selectedYear : "CP6"} ${selectedPeriod ? "(P" + selectedPeriod + ")" : ""} ${selectedStatus ? selectedStatus : ""} `)
    }, [selectedYear, selectedStatus, selectedPeriod, tickedWorkTypeNames])

    let containerClassName = "side-bar-container";

    if (!sidebarIsWide()) {
        containerClassName = "side-bar-collapsed";
    }

    const hamburgerButtonHandler = () => {

        const classForBool = { true: "side-bar-collapsed", false: "side-bar-container" }
        if (!sidebarIsWide()) {
            document.getElementsByClassName("side-bar-collapsed")[0].className = classForBool[!sidebarIsWide().toString()];

        } else {
            document.getElementsByClassName("side-bar-container")[0].className = classForBool[sidebarIsWide().toString()];
            clearMenuSelections();
        }
    }

    const toggleSidebarWidth = () => {

        if (!sidebarIsWide()) {

            document.getElementsByClassName("side-bar-collapsed")[0].className = "side-bar-container";

        }
    }

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = headerContent["mobile-breakpoint"]


    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);


    return (

        <>
            {!data.loading ? <>
                <Header

                    hamburgerButtonHandler={hamburgerButtonHandler}
                    titleAddition={titleAddition}

                />

                {/* TODO */}
                <div className='main-content-container'>


                    <>

                        {urlArray[urlArray.length - 1] === "form"
                            || urlArray[urlArray.length - 2] === "projectpage"
                            || urlArray[urlArray.length - 1] === "summary"
                            || urlArray[urlArray.length - 2] === "form"
                            || urlArray[urlArray.length - 2] === "deletereview"
                            || urlArray[urlArray.length - 1] === "logoutreview" ?
                            <></>
                            :

                            <SideBar
                                toggleSidebarWidth={toggleSidebarWidth}
                                containerClassName={containerClassName}
                                displayStyles={displayStyles} setDisplayStyle={setDisplayStyle}
                                displayStyle={currentDisplayStyle}
                                selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}
                                selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod}
                                yearsList={yearsList}
                                setSelectedYear={setSelectedYear} selectedYear={selectedYear}
                                workTypeList={workTypeList} setTickedWorkTypeNames={setTickedWorkTypeNames}
                                tickedWorkTypeNames={tickedWorkTypeNames}
                                periodList={periodList}
                            >

                            </SideBar>
                        }

                    </>

                    {urlArray[urlArray.length - 1] === "form"
                        || urlArray[urlArray.length - 2] === "projectpage"

                        || urlArray[urlArray.length - 2] === "form"
                        || urlArray[urlArray.length - 2] === "deletereview"
                        || urlArray[urlArray.length - 1] === "logoutreview" ?

                        <></> : <FloatingButtons />
                    }


                    <div className="content">
                        <Routes>


                            <Route path='timeline' element={width > breakpoint ? <GanttLayout
                                data={filteredData}
                                selectedYear={selectedYear}
                                startTime={startTime}
                                endTime={endTime} /> : <NoMobileView />}
                            />

                            <Route path='map' element={width > breakpoint ? <MapStyle
                                featureFilteredData={filteredFeatureData}
                                sideBarRightTitle={" Project List"} /> : <NoMobileView />}
                            />


                            <Route path='peopleform/:id' element={<PeopleForm
                            />} />

                            <Route path='details/:id' element={<ProjectReview
                            />} />
                            <Route path='deletereview/:id' element={<DeleteCheck
                            />} />
                            <Route path='peopledeletereview/:id' element={<DeletePeopleCheck
                            />} />


                            <Route exact path='table' element={<TableNew
                                filteredData={filteredData} />} />


                            <Route path='form' element={<ProjectForm
                            />} />

                            <Route path='form/:id' element={<ProjectForm
                            />} />

                            <Route path='projectpage/:id' element={<ProjectPage
                            />} />

                            <Route path='summary' element={<SummaryStyle
                                filteredData={filteredData}
                            />} />


                            <Route path='logoutreview' element={<LogOutReview
                            />} />
                            <Route path='nomobiledisplay' element={<NoMobileView
                            />} />


                            <Route
                                path="*"
                                element={<Navigate to="/app/main/summary" replace />}
                            />
                        </Routes>



                    </div>
                </div>
            </> : <></>}




        </>
    )
}

export default Dashboard