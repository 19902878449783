import React from 'react';

import './bar.css';

const BarComponent = ({
  value1,
  value2,
  containerwidth,
  containerHeight,
  title,
  titleTextwidth
}) => {
  // Calculate the percentage of each value
  const percent1 = (value1 / (value1 + value2)) * 100;
  const percent2 = (value2 / (value1 + value2)) * 100;


  return (
    <div
      className='bar-container-main'
      style={{ height: `${containerHeight}px` }}
    >
      <div className='bar-text-container'>
        <div className='bar-text' style={{
          width: `${titleTextwidth}rem`,
        }}>{title}</div>
      </div>
      <div className='bar-container' style={{ width: `${containerwidth}%` }}>
        <div
          className='bar-completed'
          style={{
            width: `${percent1}%`,
            height: '100%',
            //   backgroundColor: compColor,
          }}
        >
          <div className='bar-completed-popup'>Complete {value1}</div>
        </div>
        <div
          className='bar-outstanding'
          style={{
            width: `${percent2}%`,
            height: '100%',
            //   backgroundColor: outstandingColor,
          }}
        >
          <div className='bar-outstanding-popup'>Not started {value2}</div>
        </div>
      </div>
    </div>
  );
};

export default BarComponent;
