import React, { useState, useRef } from 'react'

import '../../../Components/Sidebar/Sidebar.css'

export default function RadioButtonList({ listData, setSelectedItem, selectedItem, listTitle, allItemsSpecificTitle, buttonImage, toggleMenuItem,
    toggleSidebarWidth }) {
    const menuRef = useRef(null)



    const handleTitleButtonClick = () => {
        toggleMenuItem(menuRef)
        toggleSidebarWidth()
    }

    const handleRadioButtons = (event) => {
        const toggledRadioButtonItem = event.target.value;
        setSelectedItem(toggledRadioButtonItem);
        toggleMenuItem(menuRef)


    }

    return (
        <div ref={menuRef}>
            <button className={`sidebar-btn`} onClick={handleTitleButtonClick}>
                <img className='btn-icon' src={buttonImage} alt="" />
                <h4>{listTitle}</h4>
            </button>
            <div className={`list-container-hide`}>

                <div className="list-item-container" >
                    <label key={0}>
                        <input type="radio" className="orange-accent" value={""} checked={selectedItem == ""} onChange={handleRadioButtons} style={{
                            marginRight: 10
                        }}
                        >

                        </input>
                        {allItemsSpecificTitle}
                    </label>

                </div>
                {/* <div className="list-item-container" >
                    <label key={1}>
                        <input type="radio" value={""} checked={selectedItem == ""} onChange={handleRadioButtons} style={{
                            marginRight: 10
                        }}
                        >

                        </input>
                        {allItemsSplitTitle}
                    </label>

                </div> */}
                {
                    listData.map((listItemName, index) => {
                        return (
                            <div className="list-item-container" key={index}>
                                <label key={index + 2}>
                                    <input type="radio" className="orange-accent" value={listItemName} checked={selectedItem == listItemName} onChange={handleRadioButtons} style={{ marginRight: 10 }}

                                    >

                                    </input>
                                    {listItemName}
                                </label>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}
