import { useContext, useEffect, useState } from "react";
import { FirebaseDataContext } from "../Context/FirebaseDataContext";
import { UserContext } from "../Context/AuthContext";

const useCheckUserRights = () => {

    const data = useContext(FirebaseDataContext, [])
    const userData = useContext(UserContext, [])
    const [buttonStatus, setButtonStatus] = useState(true)

    useEffect(() => {
        const currentUserId = userData.user.uid
        const userWriteList = data.userMetaData
        if (userWriteList.filter(e => e.id === currentUserId).length > 0) {
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
        }
    }, []);

    return { data, userData, buttonStatus };
}


export default useCheckUserRights;