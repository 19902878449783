import React from 'react'

const Comms = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m19.1903,12.6085c-.1196,0-.2374-.0453-.328-.1359-.1812-.1812-.1812-.4748,0-.656.8499-.8499,1.3175-1.9798,1.3175-3.1804,0-1.2015-.4676-2.3305-1.3175-3.1804-.1812-.1812-.1812-.4748,0-.656.1794-.1794.473-.183.656,0,1.0257,1.0248,1.5893,2.3867,1.5893,3.8365,0,1.4489-.5636,2.8117-1.5893,3.8365-.0906.0906-.2102.1359-.328.1359Z" fill="#ff4c29" />
                    <path d="m22.2657,14.3628c-.1196,0-.2374-.0453-.328-.1359-.1812-.1812-.1812-.4748,0-.656,1.3175-1.3184,2.0442-3.0708,2.0442-4.9347s-.7267-3.6172-2.0442-4.9347c-.1812-.1812-.1812-.4748,0-.656s.4748-.1812.656,0c1.4933,1.4933,2.316,3.4785,2.316,5.5907s-.8227,4.0974-2.316,5.5907c-.0906.0906-.2084.1359-.328.1359Z" fill="#ff4c29" />
                    <path d="m13.9983,11.4859c-1.5712,0-2.8488-1.2785-2.8488-2.8497s1.2776-2.8497,2.8488-2.8497,2.8506,1.2785,2.8506,2.8497-1.2794,2.8497-2.8506,2.8497Zm0-4.7716c-1.0583,0-1.9209.8617-1.9209,1.9219s.8626,1.9219,1.9209,1.9219c1.0601,0,1.9228-.8617,1.9228-1.9219s-.8626-1.9219-1.9228-1.9219Z" fill="#ff4c29" />
                    <path d="m8.8063,12.4019c-.1196,0-.2374-.0453-.328-.1359-2.1149-2.1158-2.1149-5.5581,0-7.6738.1812-.1812.4748-.1812.656,0s.1812.4748,0,.656c-1.7524,1.7542-1.7524,4.6076,0,6.3618.1812.1812.1812.4748,0,.656-.0906.0906-.2102.1359-.328.1359Z" fill="#ff4c29" />
                    <path d="m5.7328,14.1562c-.1196,0-.2374-.0453-.328-.1359-3.0826-3.0826-3.0826-8.0997,0-11.1823.1812-.1812.4748-.1812.656,0s.1812.4748,0,.656c-2.7219,2.721-2.7219,7.1492,0,9.8702.1812.1812.1812.4748,0,.656-.0906.0906-.2084.1359-.328.1359Z" fill="#ff4c29" />
                    <path d="m13.9983,21.4811c-.2555,0-.4639-.2075-.4639-.4639v-9.5422c0-.2564.2084-.4639.4639-.4639s.4639.2075.4639.4639v9.5422c0,.2564-.2084.4639-.4639.4639Z" fill="#ff4c29" />
                    <path d="m16.2727,25.2977h-4.5468c-.4947,0-.946-.2519-1.2069-.6732-.2591-.4204-.2827-.9369-.0616-1.3791l1.2178-2.4356c.0779-.1568.2392-.2564.415-.2564h3.8165c.1758,0,.3371.0988.415.2564l1.2196,2.4356c.2211.4422.1975.9587-.0616,1.3791-.261.4213-.7122.6732-1.2069.6732Zm-3.8963-3.8165l-1.0891,2.1792c-.1105.2202-.0217.4068.0217.4766s.1703.2329.4168.2329h4.5468c.2465,0,.3733-.1631.4168-.2329s.1323-.2564.0217-.4766l-1.091-2.1792h-3.2439Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Comms