import React from 'react'
import SummaryProjectQty from '../../displayStyles/dashboardComponents/SummaryProjectQty'
import StatsDetailProjectInfo from '../../displayStyles/dashboardComponents/StatsDetailProjectInfo'
import '../../CssGeneral/dashboard.css'

const StatsCardDouble = ({ cardType, title, btnDisplayBool, data }) => {
    const btnDisplay = btnDisplayBool

    function projectTotalsCalculator(data) {
        return {
            projectTotal: data.length,
            projectCompleted: data.filter((item) => item.Status === "Complete").length,
            projectPercentageComplete: Math.round(data.filter((item) => item.Status === "Complete").length / data.length * 100),
            projectOutstanding: data.filter((item) => item.Status === "Outstanding").length,
        }
    }


    return (
        <div className={`grid-item card ${cardType}`}>
            <div className='card-top-row'>
                <div className=' card-title-container'>
                    <h3 className='card-title-stats'>{title}</h3>
                </div>

            </div>
            <div className={`card-content-row`}>
                <div className='card-comp-left'><SummaryProjectQty
                    latestqty={projectTotalsCalculator(data).projectTotal}
                    title={"Sites"} />
                </div>
                <div className='card-comp-right'><StatsDetailProjectInfo
                    percentage={projectTotalsCalculator(data).projectPercentageComplete}
                />
                </div>

            </div>
        </div>

    )
}

export default StatsCardDouble