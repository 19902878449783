import React from 'react'
import { Link } from 'react-router-dom'

import Telephone from '../../../assets/UIcons/Contact_Phone.svg'
import Email from '../../../assets/UIcons/Contact_Email.svg'
import Edit from '../../../assets/BtnIcon/BI-_Btn-Edit.svg'

import '../../dashboardComponents/dashboardContent.css'

import useCheckUserRights from '../../../hooks/useCheckUserRights'

const PeopleItemMain = ({ index, person }) => {
    const { buttonStatus } = useCheckUserRights()

    return (
        <div className='contact-container' key={index + "person"}>
            <div className='name-box-left'>
                {person.image ?
                    <div className='avatar-box'>
                        <img className="avatar-ki-sum" src={person.image} alt="img" />
                    </div> :
                    <div className='avatar-box'>

                    </div>
                }
                <div className='text-container'>
                    {person.name ? <div className='text-name'>{person.name} </div> :
                        <div className='text-name'>No name provided </div>}
                    {person.role ? <div className='text-details-general  '>{person.role} ({person.company})</div> :
                        <div className='text-details-general '>No role provided</div>}

                </div>
            </div>

            <div className='contact-details-container-phone contact-row'>
                <img src={Telephone} className='contact-icon-small' alt="img" />
                {person.number ? <div className='text-details-general '>{person.number}</div> : <div className='text-details-general'>No details</div>
                }
            </div>
            <div className='contact-details-container-email contact-row'>
                <img src={Email} className='contact-icon-small' alt="img" />
                {person.email ? <div className='text-details-general '>{person.email}
                </div> :
                    <div className='text-details-general '>No details
                    </div>}
            </div>
            {buttonStatus ? <><Link to={`/app/main/peopleform/${person.id}`} className="contact-edit-box tablet-display-off">
                <img className='contact-edit-image tablet-display-off' src={Edit} alt="img" />
            </Link>
            </> : <></>}
        </div>)
}

export default PeopleItemMain