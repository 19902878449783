import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../Context/AuthContext';

import Login from '../assets/graphics/LoginGraphics.svg'

import './pages.css'

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, user } = UserAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signIn(email, password);
      navigate('/app/main/summary');
    } catch (e) {
      setError(e.message);
      if (e.code === "auth/wrong-password") {
        alert("Incorrect password");
      }
      else if (e.code === "auth/user-not-found") {
        alert("Incorrect username");
      }
      else if (e.code === "auth/internal-error" || e.code === "auth/invalid-email") {
        alert("You havent completed all the details correctly");
      }

      else {
        alert(e.message)
      }
    }
  };



  return (
    <div className='log-page-main-container'>
      <img className="log-graphic" src={Login} alt="" />

      <div>
        <div className='log-title'>Sign in to the<span className='log-color'> <br></br> Kent CP6 <br></br> </span>Integrated Works App </div>

      </div>
      <form onSubmit={handleSubmit}>
        <div className='log-input-containter'>
          <label className='log-label'>Email Address</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className='log-input'
            type='email'
          />
        </div>
        <div className='log-input-containter'>
          <label className='log-label'>Password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className='log-input'
            type='password'
          />
        </div>
        <div className='log-btn-container'>
          <button className='log-btn'>
            Log In
          </button>
        </div>

        {/* <p className='log-text'>
          Don't have an account yet?
          <Link to='/signup' className='underline'>
            Sign up.
          </Link>
        </p> */}

      </form>
    </div>
  );
};

export default Signin;
