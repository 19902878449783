import React from 'react'

const Milestones = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m10.7779,15.0941c-1.2588,0-2.282-1.0241-2.282-2.2829,0-1.2579,1.0232-2.282,2.282-2.282s2.2837,1.0241,2.2837,2.282c0,1.2588-1.0249,2.2829-2.2837,2.2829Zm0-3.6844c-.7721,0-1.4015.6285-1.4015,1.4015s.6294,1.4024,1.4015,1.4024c.7738,0,1.4032-.6294,1.4032-1.4024s-.6294-1.4015-1.4032-1.4015Z" fill="#ff4c29" />
                    <path d="m18.1484,18.7785c-1.2588,0-2.2837-1.0241-2.2837-2.282,0-1.2588,1.0249-2.2829,2.2837-2.2829s2.282,1.0241,2.282,2.2829c0,1.2579-1.0232,2.282-2.282,2.282Zm0-3.6844c-.7738,0-1.4032.6294-1.4032,1.4024s.6294,1.4015,1.4032,1.4015c.7721,0,1.4015-.6285,1.4015-1.4015s-.6294-1.4024-1.4015-1.4024Z" fill="#ff4c29" />
                    <rect x="17.2909" y="11.1001" width="8.126" height=".8806" transform="translate(-.3286 22.4509) rotate(-55.0996)" fill="#ff4c29" />
                    <rect x="14.0225" y="12.3756" width=".8813" height="4.5567" transform="translate(-5.1225 21.0037) rotate(-63.3382)" fill="#ff4c29" />
                    <rect x="1.5406" y="17.6958" width="9.9555" height=".8809" transform="translate(-11.7157 11.8966) rotate(-51.3402)" fill="#ff4c29" />
                    <polygon points="20.8775 22.4638 2.9689 22.4638 2.9689 5.7864 3.8494 5.7864 3.8494 21.5833 20.8775 21.5833 20.8775 22.4638" fill="#ff4c29" />
                    <polygon points="24.1707 11.997 23.3556 8.74 20.0968 9.5542 19.8835 8.6996 23.9953 7.6721 25.0236 11.7838 24.1707 11.997" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Milestones