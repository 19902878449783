import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

import { FirebaseDataContext } from '../Context/FirebaseDataContext';
import { UserContext } from '../Context/AuthContext'

import { db } from '../firebase'
import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'

import { routes, statusOptions, years, period, volumeUnit, volumeKVL, subCategory, yearsWithBlank, periodWithBlank } from '../constants/FormSelectors'
import { addUpdateLog } from '../constants/UpdateLog'
import workTypeDetails from "../constants/workTypeDetails";
import './form.css'

import DbCard from '../Components/cards/DbCard'
import FormMap from '../Components/FormMap/FormMap';

export default function ProjectForm() {

    const userData = useContext(UserContext)
    const data = useContext(FirebaseDataContext)

    const { latestPeriod, latestYear } = data.railDateInfo

    let logInUser = userData.user.email
    const capialiseFirstLetter = (word) => {
        return word.charAt(0).toUpperCase()
            + word.slice(1)
    }

    const latestURL = window.location.href
    const urlArray = latestURL.split(/[/]/);
    const urlLast = urlArray[urlArray.length - 1]


    const userNameSplit = logInUser.split(/[@.]/);
    const userName = `${capialiseFirstLetter(userNameSplit[0])} ${capialiseFirstLetter(userNameSplit[1])}`

    const navigate = useNavigate()
    const params = useParams();
    const selectedItemId = params.id;
    const workTypes = Object.keys(workTypeDetails)

    const selectCurrentItem = (dataSet) => {
        let output = [];
        dataSet.forEach((item) => {
            if (item.id === selectedItemId) {
                output.push(item)
            }
        })
        return output
    }

    const currentItemName = selectedItemId ? selectCurrentItem(data.projectData)[0].projectName : ""

    const currentItemData = selectCurrentItem(data.projectData)[0]

    const lng = selectedItemId ? currentItemData.lon : 0.33048416459169516
    const lat = selectedItemId ? currentItemData.lat : 51.11573759034991
    const zoom = selectedItemId ? 16 : 7

    let defaultFormData = {}
    // IE CREATE 
    if (!selectedItemId || selectedItemId === undefined) {
        //   DETAILS
        defaultFormData["projectName"] = "Project Name"
        defaultFormData["OPNumber"] = ""
        defaultFormData["workType"] = workTypes[1]
        defaultFormData["subCategory"] = subCategory[0]
        defaultFormData["Product Type"] = ""
        defaultFormData["Mileage"] = ""
        defaultFormData["ELR"] = ""
        defaultFormData["Signal Box"] = ""
        defaultFormData["Status"] = "Outstanding"
        defaultFormData["route"] = "Kent"
        // PROJECT TEAM
        defaultFormData["routeDeliveryDirector"] = ""
        defaultFormData["programmeManager"] = ""
        defaultFormData["principalPlanner"] = ""

        // VOLUMES
        defaultFormData["Volume KVL"] = ""
        defaultFormData["volumes"] = 0
        defaultFormData["volumesUnit"] = ""

        // DATES
        defaultFormData["Actual_Commissioning Year"] = ""
        defaultFormData["Actual_Commissioning Period"] = ""
        defaultFormData["Actual_Commissioning Week"] = ""

        defaultFormData["Forecast_Commissioning Year"] = ""
        defaultFormData["Forecast_Commissioning Period"] = ""
        defaultFormData["Forecast_Commissioning Week"] = ""

        defaultFormData["Commissioning Year"] = "2023/24"
        defaultFormData["Commissioning Period"] = "13"
        defaultFormData["Commissioning Week"] = ""

        defaultFormData["comments"] = ""
        defaultFormData["dateComment"] = ""

        defaultFormData["lon"] = 0.33048416459169516
        defaultFormData["lat"] = 51.11573759034991
        defaultFormData["dateCreated"] = new Date()
        defaultFormData["createdBy"] = logInUser
    }
    // IE UPDATE
    if (selectedItemId) {
        defaultFormData["dateUpdated"] = new Date()
        defaultFormData["updatedBy"] = logInUser
    }

    const [formData, setFormData] = useState(defaultFormData);

    // FORM FUNCTIONS

    const handleFormInputChange = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            [name]: value
        }))
    }

    const handleStatusChange = (e) => {

        const value = e.target.value

        if (value === "Outstanding") {
            setFormData((curr) => ({
                ...curr,
                Status: value,
                "Actual_Commissioning Year": "",
                "Actual_Commissioning Period": ""
            }))

        } else if (value === "Complete") {
            setFormData((curr) => ({
                ...curr,
                Status: value,
                "Actual_Commissioning Year": latestYear,
                "Actual_Commissioning Period": latestPeriod
            }))

        }
    }

    const handleFormNumberInput = (e) => {
        const { name, value } = e.target
        setFormData((curr) => ({
            ...curr,
            [name]: Number(value)
        }))
    }

    const handleLongLatInputChange = ({ target }) => {
        const { name, value } = target;

        setFormData((curr) => ({
            ...curr,
            [name]: Number(value)
        }))
    }

    const handleLngLatMapBox = (lngLat) => {

        setFormData((curr) => ({
            ...curr,
            lon: lngLat.lng,
            lat: lngLat.lat

        }))
    }




    // DATABASE FUNCTIONS

    useEffect(() => {

        if (params.id) {
            const docRef = doc(db, 'Workbank', selectedItemId)
            getDoc(docRef).then(snapshot => {
                setFormData({ ...snapshot.data(), id: snapshot.id })
            })
        }
    }, [])

    const updateDataOnFireBase = async () => {
        const collectionRef = doc(db, "Workbank", selectedItemId)
        await setDoc(collectionRef, formData);
        const log = {
            name: logInUser,
            // date: new Date(),
            title: "Project updated",
            update: `${userName} updated ${formData.projectName}`
        }
        addUpdateLog(log)
        data.updateWorkBank({ ...formData, id: selectedItemId }, log)
        navigate('/app/main/table')
    }

    const CreateDataEntryToFireBase = async () => {
        const res = await addDoc(collection(db, "Workbank"), formData);
        const log = {
            name: logInUser,
            // date: new Date(),
            title: "New Project",
            update: `${userName} added ${formData.projectName}`,
        }
        addUpdateLog(log)
        data.createWorkBank({ ...formData, id: res.id }, log)
        navigate('/app/main/table')
    }

    const handleBackButton = () => {
        navigate('/app/main/table')
    }


    // CONDITIONAL RENDERING/FUNCTIONS
    let cardTitle = selectedItemId ? `Edit ${currentItemName}` : "New Project"
    let mainBtnTitle = selectedItemId ? "Update" : "Add"
    let btnFunction = selectedItemId ? updateDataOnFireBase : CreateDataEntryToFireBase

    const controlActualState = (status) => {
        switch (status) {
            case "Outstanding":
                return <></>
                break;

            case "Complete":
                return <>
                    <div className='summary-text-fs1 form-highlight-title font-600'>If you set the Commissioning Status to complete and dont populate Actual dates it will default to the current period!</div>
                    <div className='form-boxes-container'>
                        <div className="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Actual Commissioning Year</p>
                                <select className='input-box' name={"Actual_Commissioning Year"} value={formData["Actual_Commissioning Year"]} onChange={handleFormInputChange}>
                                    {years.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                </select>
                                <br />
                            </label>
                        </div>
                        <div className="input-container">
                            <label className='label-container'>
                                <p className='label-title'>Actual Commissioning Period</p>
                                <select className='input-box' name={"Actual_Commissioning Period"} value={formData["Actual_Commissioning Period"]} onChange={handleFormInputChange}>
                                    {period.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                </select>
                                <br />
                            </label>
                        </div>
                    </div>
                </>
                break;
            default:
                break;
        }

    }


    return (
        <>
            <div className="dashboard-container">
                <DbCard
                    cardType={' card graph-card-wide  card-height-min'}
                    icon={""}
                    title={`${cardTitle}`}
                    cardContent={""}
                    btnText={"Go back"}
                    mainBtnText={`${mainBtnTitle} Project`}
                    btnDisplayBool={true}
                    btnMainDisplayBool={true}
                    OnClickFuncMainBtn={btnFunction}
                    OnClickFunc={handleBackButton}>

                    <>

                        <div className='form-sub-title'>Project Details</div>
                        <div className='summary-text-fs1'>Make sure you fill in all the details</div>

                        <div className='form-boxes-container'>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Project Name</p>
                                    <input className='input-box wide-input' type="text" value={formData["projectName"]} name={"projectName"} onChange={handleFormInputChange} maxLength={30} /><br />
                                </label>
                            </div>

                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>OP Number</p>
                                    <input className='input-box' type="text" value={formData["OPNumber"]} name={"OPNumber"} onChange={handleFormInputChange} /><br />
                                </label>
                            </div>

                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Work Type</p>
                                    <select className='input-box' name={"workType"} value={formData["workType"]} onChange={handleFormInputChange}>
                                        {workTypes.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Sub Category</p>
                                    <select className='input-box' name={"subCategory"} value={formData["subCategory"]} onChange={handleFormInputChange}>
                                        {subCategory.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>

                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Route</p>
                                    <select className='input-box' name={"route"} value={formData["route"]} onChange={handleFormInputChange}>
                                        {routes.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>

                        </div>

                        <div className='form-sub-title'>Volumes</div>
                        <div className='summary-text-fs1'>Make sure you fill in all the details</div>

                        <div className='form-boxes-container'>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Volume KVL</p>
                                    <select className='input-box' name={"Volume KVL"} value={formData["Volume KVL"]} onChange={handleFormInputChange}>
                                        {volumeKVL.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Volume</p>
                                    <input className='input-box wide-input' type="number" value={formData["volumes"]} name={"volumes"} onChange={handleFormNumberInput} min={0} /><br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Volume Unit</p>
                                    <select className='input-box' name={"volumesUnit"} value={formData["volumesUnit"]} onChange={handleFormInputChange}>
                                        {volumeUnit.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>

                        <div className='form-sub-title'>Timeline</div>
                        <div className='summary-text-fs1 font-600'>Changing the Commissioning status to complete will set the actual dates to the current period. If this is not correct reset below!</div>
                        <div className='form-boxes-container'>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title form-highlight-title '>Commissioning Status</p>
                                    <select className='input-box' name={"Status"} value={formData["Status"]} onChange={handleStatusChange}>
                                        {statusOptions.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>
                        {controlActualState(formData["Status"])}


                        <div className='summary-text-fs1 form-highlight-title font-600'>If you dont populate Planned dates it will default to the end of the current year as TBC!</div>
                        <div className='form-boxes-container'>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Planned Commissioning Year</p>
                                    <select className='input-box' name={"Commissioning Year"} value={formData["Commissioning Year"]} onChange={handleFormInputChange}>
                                        {years.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Planned Commissioning Period</p>
                                    <select className='input-box' name={"Commissioning Period"} value={formData["Commissioning Period"]} onChange={handleFormInputChange}>
                                        {period.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                    </select>
                                    <br />
                                </label>
                            </div>
                        </div>

                        {urlLast === selectedItemId ? <>

                            <div className='form-boxes-container'>
                                <div className="input-container">
                                    <label className='label-container'>
                                        <p className='label-title'>Reforecast Commissioning Year</p>
                                        <select className='input-box' name={"Forecast_Commissioning Year"} value={formData["Forecast_Commissioning Year"]} onChange={handleFormInputChange}>
                                            {yearsWithBlank.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                        </select>
                                        <br />
                                    </label>
                                </div>
                                <div className="input-container">
                                    <label className='label-container'>
                                        <p className='label-title'>Reforecast Commissioning Period</p>
                                        <select className='input-box' name={"Forecast_Commissioning Period"} value={formData["Forecast_Commissioning Period"]} onChange={handleFormInputChange}>
                                            {periodWithBlank.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                        </select>
                                        <br />
                                    </label>
                                </div>
                            </div>
                        </> : <> </>}

                        <div className='form-sub-title'>Team Details</div>
                        <div className='summary-text-fs1'>Make sure you fill in all the details</div>
                        <div className='form-boxes-container'>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Route Delivery Director
                                    </p>
                                    <input className='input-box' type="text" value={formData["routeDeliveryDirector"]} name={"routeDeliveryDirector"} onChange={handleFormInputChange} maxLength={40} /><br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Programme Manager</p>
                                    <input className='input-box' type="text" value={formData["programmeManager"]} name={"programmeManager"} onChange={handleFormInputChange} maxLength={40} /><br />
                                </label>
                            </div>
                            <div className="input-container">
                                <label className='label-container'>
                                    <p className='label-title'>Principal Planner</p>
                                    <input className='input-box' type="text" value={formData["principalPlanner"]} name={"principalPlanner"} onChange={handleFormInputChange} maxLength={40} /><br />
                                </label>
                            </div>
                        </div>

                        <div className='form-sub-title'>Location</div>
                        <div className='summary-text-fs1'>Click on the map to choose a location</div>
                        <div className='form-boxes-container' id="form-mapbox">
                            <div class="input-container-map">

                                <FormMap lng={lng} lat={lat} zoom={zoom} handleLngLatMapBox={handleLngLatMapBox}></FormMap>
                                <div className='form-group-column-container'>
                                    {/* <label className='label-container'>
                                        <p className='label-title label-pad-left'>Longitude</p>
                                        <input className='input-box' type="number" value={formData["lon"]} name={'lon'} placeholder={"Edit Longitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label>
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Latitude</p>
                                        <input className='input-box' type="number" value={formData["lat"]} name={'lat'} placeholder={"Enter latitude..."} onChange={handleLongLatInputChange} /><br />
                                    </label> */}
                                    {/* 
                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Primary ELR</p>
                                        <input className='input-box wide-input' type="text" value={formData["ELR"]} name={"ELR"} onChange={handleFormInputChange} maxLength={5} /><br />
                                    </label>

                                    <label className='label-container'>
                                        <p className='label-title label-pad-left'>Mileage</p>
                                        <input className='input-box wide-input' type="text" value={formData["Mileage"]} name={"Mileage"} onChange={handleFormInputChange} maxLength={5} /><br />
                                    </label> */}

                                </div>
                            </div>
                        </div>
                    </>
                </DbCard>
            </div >
        </>
    )
}
