export const routes = ["Kent", "Sussex", "Wessex"]

export const statusOptions = ["Outstanding", "Complete"]

export const years = ["2022/23", "2023/24", "2024/25", "2025/26", "2026/27", "2027/28", "2028/29"]

export const period = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

export const yearsWithBlank = ["", "2022/23", "2023/24", "2024/25", "2025/26", "2026/27", "2027/28", "2028/29"]

export const periodWithBlank = ["", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

export const possessionType = ["Blockade", "52hr"]

export const subCategory = [
    "",
    "Points Heating",
    "NSCD",
    "RTU Replacement",
    "HV Cable",
    "Non Traction Switchgear",
    "LV Switchgear Renewal",
    "Transformers",
    "OLE Structure Renewal",
    "HV Switchgear Renewal",
    "Transformer Renewal",
    "Domestic Rewiring"
]

export const volumeKVL = [
    "Non Reportable Volume", "FS Electrical (re-wiring & lighting) (sq m)", "FS Platforms (sq m)", "FS Buildings (sq m)", "EW New Build - Ditch/Channel (m)", "Track Renewal - Pipe (m)", "Underbridge - Preventative (sq m)", "FS Canopies (sq m)", "Overbridge - Preventative (sq m)", "Overbridge - Repair (sq m)", "Underbridge - Strengthen (sq m)", "Underbridge - Replace (sq m)", "MS Other Fabric (sq m)", "EW New Build - Pipe (m)", "Underbridge - Repair (sq m)", "EW Renewal - Ditch/Channel (m)", "EW Refurb - Pipe (m)", "FS Footbridges (sq m)", "Underbridge - Waterproofing (sq m)", "EW Refurb - Ditch/Channel (m)", "Track Refurb - Pipe (m)", "Res New Build - Pipe (m)", "Tunnel - Repair (sq m)", "Track Renewal - Chamber (#)", "Track Refurb - Chamber (#)", "Points Heating (Point Ends)", "Embankments Alert/Alarm System (#)", "Soil Cutting Refurb (#)", "Rock Cutting Renew (#)", "Soil Cutting Maintain (#)", "Soil Cutting Alert/Alarm System (#)", "DC-SCADA-RTU", "Embankments Renew (#)", "DC-HV Cables", "DC-LV Switchgear Renewal", "Soil Cutting Renew (#)", "Rock Cutting Refurb (#)", "OHL Structure Renewal", "Embankments GIA System (#)", "Cat FEN3 Fencing Renewal Cat3 (km)", "DC-HV Switchgear Renewal", "AC Transformer", "FS Lifts & Escalators (#)", "Embankments Refurb (#)", "Embankments Maintain (#)", "DC-LV Cables", "AC-SCADA-RTU", "Auxilary Transformer", "Underbridge - Replace (#)", "Underbridge - Preventative (#)", "Underbridge - Repair (#)", "Rock Cutting Maintain (#)", "Tunnel - Repair (#)", "Depot Plant (#)", "LMD Electrical (re-wiring & lighting) (#)", "Overbridge - Repair (#)", "Overbridge - Preventative (#)", "Carriage Renew Washer", "Wheel Lathe Renewal", "Underbridge - Strengthen (#)", "Underbridge - Waterproofing (#)", "FS Electrical (re-wiring & lighting) (#)"
]

export const volumeUnit = ["", "m", "km", "m2"]

export const southernELR = [
    "TBC",
    "AAV"
    , "ACR"
    , "AGW"
    , "AHG"
    , "AJB"
    , "APL"
    , "ATH"
    , "ATL"
    , "BAA"
    , "BAE"
    , "BBD9"
    , "BBJ"
    , "BBR"
    , "BEX"
    , "BJN"
    , "BKE"
    , "BLI"
    , "BLP"
    , "BME"
    , "BMJ"
    , "BML"
    , "BNG"
    , "BPJ"
    , "BPW"
    , "BSF"
    , "BSP"
    , "BTC"
    , "BTH"
    , "BTL"
    , "CAT"
    , "CBM"
    , "CJA"
    , "CJL"
    , "CKL"
    , "CLA"
    , "CLJ"
    , "CRA"
    , "CSM"
    , "CSW"
    , "ECR"
    , "ELL"
    , "ETF"
    , "EYD"
    , "FDM"
    , "FFH"
    , "FJJ"
    , "FJL"
    , "FJS"
    , "FLL"
    , "FTL "
    , "FUR"
    , "GTW"
    , "HAG"
    , "HAM"
    , "HDR"
    , "HGG1"
    , "HGP"
    , "HHH"
    , "HHT"
    , "HJW"
    , "HOU"
    , "HSE"
    , "HTG"
    , "KJE"
    , "LAV"
    , "LBC"
    , "LBW"
    , "LCH"
    , "LEE"
    , "LEJ"
    , "LLL"
    , "LOC"
    , "LTC"
    , "LTH"
    , "LUD"
    , "LVT"
    , "MIS"
    , "MPC"
    , "MSW"
    , "NBB"
    , "NCS"
    , "NFE"
    , "NGL"
    , "NHB "
    , "NKE"
    , "NKL"
    , "NMS"
    , "NSA"
    , "NTL"
    , "NYD"
    , "OJS"
    , "PAA"
    , "PAS"
    , "PAT"
    , "PPH"
    , "PPW"
    , "PSF"
    , "PWS"
    , "RDG"
    , "RED"
    , "REY"
    , "RNJ"
    , "RNS"
    , "RPE"
    , "RSJ"
    , "RTJ"
    , "RTT"
    , "RVC"
    , "SAL"
    , "SBJ"
    , "SCC"
    , "SCP"
    , "SCU1"
    , "SDP"
    , "SEJ"
    , "SEV"
    , "SHF"
    , "SLC"
    , "SLJ"
    , "SMS"
    , "SNS"
    , "SOY"
    , "SSC"
    , "STS"
    , "SWE"
    , "TAT"
    , "TBH"
    , "TLP"
    , "TML"
    , "TRL"
    , "TSJ"
    , "TTF"
    , "TTH"
    , "VIR"
    , "VTB"
    , "VWW"
    , "WCS"
    , "WEY"
    , "WJB"
    , "WKG"
    , "WLL"
    , "WMB"
    , "WMS"
    , "WPH"
    , "WPK"
    , "WTH"
    , "XTD"
    , "YJP"

]


