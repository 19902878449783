import React, { useContext, useState, useEffect } from 'react'

import '../../CssGeneral/dashboard.css'
import '../../CssGeneral/dashboardContent.css'
import '../../displayStyles/SummaryStyle.css'


const SummaryProjectQty = ({ latestqty, title }) => {


    return (
        <div className='summ-top-summary-container'>
            <div className='summ-top-summary-box'>
                <div className="summ-top-summary-qty">{latestqty}</div>
            </div>
            <div className='summ-top-summary-text'>{title}</div>
        </div>
    )
}

export default SummaryProjectQty