import React, { useEffect, useState } from 'react'
import workTypeDetails from "../constants/workTypeDetails";
import { db } from '../firebase'
import { setDoc, getDoc, doc, collection, addDoc } from 'firebase/firestore'

const ProjectReview = () => {

    const selectedItemId = window.location.pathname.split('/')[4];

    return (

        <>
            <div>ProjectReview</div>

            <button>Edit</button>
            <button>Delete</button>
        </>
    )
}

export default ProjectReview