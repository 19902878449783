import React, { useContext, useEffect, useState } from 'react'

import { UserContext } from '../../Context/AuthContext'
import { FirebaseDataContext } from '../../Context/FirebaseDataContext';

import '../../CssGeneral/dashboardContent.css'

import ReadBtn from '../../assets/BtnIcon/BI-_Btn-Review.svg'

import CompleteIcon from "../../assets/graphIcons/MileStoneCompleteCircle.svg"
import ReforecastArrow from "../../assets/graphIcons/ReforecastArrow.svg"
import Blank from "../../assets/graphIcons/Blank.svg"

import { useNavigate, Link } from 'react-router-dom'


import './table.css'

const TableNewContent = ({ filteredData, searchQuery, searchParam }) => {

    const navigate = useNavigate()

    const data = useContext(FirebaseDataContext)
    const userData = useContext(UserContext)
    const [buttonStatus, setButtonStatus] = useState(true)

    const [filteredDataCopy, setFilteredDataCopy] = useState(filteredData)

    // Table Header Filters
    const [disciplineOrder, setDisciplineOrder] = useState('ASC')
    const [nameOrder, setNameOrder] = useState('ASC')
    const [subCatOrder, setSubCatOrder] = useState('ASC')
    const [contractOrder, setContractOrder] = useState('ASC')
    const [ownerOrder, setOwnerOrder] = useState('ASC')
    const [periodOrder, setPeriodOrder] = useState('ASC')
    const [statusOrder, setStatusOrder] = useState('ASC')
    const [commissioning, setCommissioning] = useState('ASC')


    useEffect(() => {
        if (searchQuery) {
            setFilteredDataCopy(filteredData.filter((item) => item[searchParam.key].toString().toLowerCase().includes(searchQuery.toLowerCase())))
        } else {
            setFilteredDataCopy(filteredData)
        }


    }, [filteredData, searchQuery, searchParam])

    const sortColumn = (order, setOrder, category) => {
        if (order === "ASC") {
            let data = [...filteredDataCopy]
            data.sort((a, b) => a[category] > b[category] ? -1 : 1)
            setFilteredDataCopy(data)

        } else {
            let data = [...filteredDataCopy]
            data.sort((a, b) => b[category] > a[category] ? -1 : 1)
            setFilteredDataCopy(data)
        }
        setOrder(order === "ASC" ? "DSC" : "ASC")
    }

    const statusIconDisplay = (comment) => {

        switch (comment) {
            case "Actual":
                return CompleteIcon
                break;
            case "Forecast":
                return ReforecastArrow
                break;
            case "Planned":
                return Blank
                break;
            default:
                break;
        }

    }


    return (
        <>
            <div className='detailsTable-main-container'>
                <div className='detailsTable-top-text-box summary-text-fs09'>

                    <div className='detailsTable-Icon-container  detailTableHeader ' onClick={() => sortColumn(disciplineOrder, setDisciplineOrder, "bullet")}>Work Type</div>

                    <div className='detailsTable-project-container detailTableHeader' onClick={() => sortColumn(nameOrder, setNameOrder, "projectName")}>Project Name</div>

                    <div className='detailsTable-company-container  detailTableHeader ' onClick={() => sortColumn(subCatOrder, setSubCatOrder, "subCategory")}>Subcatory</div>

                    <div className='detailsTable-contract-container  detailTableHeader' onClick={() => sortColumn(contractOrder, setContractOrder, "OPNumber")}>OP Number</div>

                    <div className='detailsTable-company-container  detailTableHeader' onClick={() => sortColumn(ownerOrder, setOwnerOrder, "programmeManager")}>Owner</div>

                    <div className='detailsTable-company-container  detailTableHeader' onClick={() => sortColumn(periodOrder, setPeriodOrder, "Commissioning Period")}>Period</div>

                    <div className='detailsTable-comm-date-container  detailTableHeader ' onClick={() => sortColumn(commissioning, setCommissioning, "Commissioning Date")}>Year</div>

                    <div className='detailsTable-status-container  detailTableHeader ' onClick={() => sortColumn(statusOrder, setStatusOrder, "Status")}>Status</div>

                    {buttonStatus ? <div className='detailsTable-info-container issue-text-header-center detailTableHeader  '>Info</div> : <></>}

                </div>
                {filteredDataCopy.map((item, index) => {
                    return (
                        <div className='detailsTable-sum-container summary-text-fs09' key={index + "actionissues"}>

                            <div className=' detailsTable-Icon-container'>
                                <img className='table-img' src={item.bullet} alt="bullet" />
                            </div>

                            <div className='detailsTable-project-container'>
                                <div className=''>{item.projectName
                                }
                                </div>

                            </div>
                            <div className='detailsTable-contract-container'>
                                <div className=''>{item["subCategory"]
                                }
                                </div>

                            </div>
                            <div className='detailsTable-contract-container'>
                                <div className=''>{item["OPNumber"]
                                }
                                </div>

                            </div>

                            <div className='detailsTable-company-container'>
                                {item["programmeManager"] ? <div className=' '>
                                    <div className=''>{item["programmeManager"]
                                    }</div>
                                </div> : <></>}
                            </div>
                            <div className='detailsTable-company-container'>
                                {item["Latest_Commissioning Period"] ? <div className=' '>
                                    <div className=''>{item["Latest_Commissioning Period"]
                                    }</div>

                                </div> : <></>}
                                {item["dateStatusComment"] === "Forecast" ? <div className='dateComment'>
                                    <div className=''>Reforecast</div>
                                </div> : <></>}


                            </div>



                            <div className='detailsTable-comm-date-container'>
                                {item["Latest_Commissioning Year"] ? <div className=''>
                                    <div className=''>{(item["Latest_Commissioning Year"])
                                    }</div>
                                </div> : <></>}
                                {item["dateComment"] ? <div className='dateComment'>
                                    <div className=''>{item["dateComment"]
                                    }</div>
                                </div> : <></>}
                            </div>

                            <div className='detailsTable-status-container'>

                                <div className='comp-icon-container'>
                                    <img src={statusIconDisplay(item["dateStatusComment"])} alt="" className='comp-icon-table' />
                                </div>

                            </div>



                            <Link to={'/app/main/projectpage/' + item.id}
                                className='detailsTable-info-container'>
                                <img className='btn-img' src={ReadBtn} alt="btn-read" />
                            </Link>

                            {/* <Link to={'/app/main/form/' + item.id} className='detailsTable-edit-status' >
                                <img className='btn-img' src={EditBtn} alt="btn-read" />
                            </Link> */}




                            {/* <Link to={'/app/main/deletereview/' + item.id}
                                className='detailsTable-edit-status'>
                                <img className='btn-img' src={DeleteBtn} alt="btn-read" />
                            </Link> */}






                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default TableNewContent