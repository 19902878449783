// SVG
import ANoDiscipline from "../assets/workTypeIcons/svg/__Icon-ANoDiscv2.svg"
import Buildings from "../assets/workTypeIcons/svg/__Icon-Buildingsv2.svg"
import Drainage from "../assets/workTypeIcons/svg/__Icon-Drainagev2.svg"
import Earthworks from "../assets/workTypeIcons/svg/__Icon-Earthworksv2.svg"
import Signalling from "../assets/workTypeIcons/svg/__Icon-Signallingv2.svg"
import Structures from "../assets/workTypeIcons/svg/__Icon-Structuresv2.svg"
import Track from "../assets/workTypeIcons/svg/__Icon-Trackv2.svg"
import Multi from "../assets/workTypeIcons/svg/__Icon-Multiv2.svg"
import LevelCrossing from "../assets/workTypeIcons/svg/__Icon-LevelCrossingv2.svg"
import TrackSnC from "../assets/workTypeIcons/svg/__Icon-SnCv2.svg"
import Fencing from "../assets/workTypeIcons/svg/__Icon-Fencingv2 .svg"
import Access from "../assets/workTypeIcons/svg/__Icon-Accessv2.svg"
import EnP from "../assets/workTypeIcons/svg/__Icon-EnPv2_EnPv2.svg"

// PNG

import ANoDisciplineMap from "../assets/workTypeIcons/png/__Icon-ANoDiscv2.png"
import BuildingsMap from "../assets/workTypeIcons/png/__Icon-Buildingsv2.png"
import DrainageMap from "../assets/workTypeIcons/png/__Icon-Drainagev2.png"
import EarthworksMap from "../assets/workTypeIcons/png/__Icon-Earthworksv2.png"
import SignallingMap from "../assets/workTypeIcons/png/__Icon-Signallingv2.png"
import StructuresMap from "../assets/workTypeIcons/png/__Icon-Structuresv2.png"
import TrackMap from "../assets/workTypeIcons/png/__Icon-Trackv2.png"
import MultiMap from "../assets/workTypeIcons/png/__Icon-Multiv2.png"
import LevelCrossingMap from "../assets/workTypeIcons/png/__Icon-LevelCrossingv2.png"
import TrackSnCMap from "../assets/workTypeIcons/png/__Icon-SnCv2.png"
import FencingMap from "../assets/workTypeIcons/png/__Icon-Fencingv2 .png"
import AccessMap from "../assets/workTypeIcons/png/__Icon-Accessv2.png"
import EnPMap from "../assets/workTypeIcons/png/__Icon-EnPv2_EnPv2_EnPv2.png"

const details = {
    "Discipline TBC": {
        color: "#C6C6C6",
        bullet: ANoDiscipline,
        mapIcon: ANoDisciplineMap
    },
    "Access Points": {
        color: "#89bf49",
        bullet: Access,
        mapIcon: AccessMap
    },
    "Buildings": {
        color: "#ec6710",
        bullet: Buildings,
        mapIcon: BuildingsMap
    },
    "Fencing": {
        color: "#004D6F",
        bullet: Fencing,
        mapIcon: FencingMap
    },
    "Drainage": {
        color: "#3bb198",
        bullet: Drainage,
        mapIcon: DrainageMap
    },

    "Earthworks": {
        color: "#89bf49",
        bullet: Earthworks,
        mapIcon: EarthworksMap
    },
    "Geotechnical": {
        color: "#89bf49",
        bullet: Earthworks,
        mapIcon: EarthworksMap
    },

    "Signalling": {
        color: "#24aae2",
        bullet: Signalling,
        mapIcon: SignallingMap
    },
    "Level Crossings": {
        color: "#24aae2",
        bullet: LevelCrossing,
        mapIcon: LevelCrossingMap
    },
    "Structures": {
        color: "#e9427d",
        bullet: Structures,
        mapIcon: StructuresMap
    },

    "E&P": {
        color: "#24aae2",
        bullet: EnP,
        mapIcon: EnPMap
    },

    "Track": {
        color: "#e9427d",
        bullet: Track,
        mapIcon: TrackMap,
    },

    "Track-S&C": {
        color: "#e9427d",
        bullet: TrackSnC,
        mapIcon: TrackSnCMap,
    },
    "Multi-Discipline": {
        color: "#EC6710",
        bullet: Multi,
        mapIcon: MultiMap,
    },
    "Enhancements": {
        color: "#941D81",
        bullet: Multi,
        mapIcon: MultiMap,
    }
}

export default details;