import React from 'react'

const Logistics = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m9.0352,8.9917c-.659,0-1.272-.2569-1.7256-.7219-.4571-.4464-.714-1.0594-.714-1.7176v-3.5654c0-.2507.2037-.4535.4535-.4535s.4535.2029.4535.4535v3.5654c0,.4119.1594.7937.4482,1.0763.2905.2967.6714.4562,1.0842.4562.8451,0,1.5325-.6874,1.5325-1.5325v-3.5654c0-.2507.2037-.4535.4535-.4535s.4535.2029.4535.4535v3.5654c0,1.3455-1.0949,2.4395-2.4395,2.4395Z" fill="#ff4c29" />
                    <path d="m9.0352,12.9637c-2.9887,0-5.4194-2.4307-5.4194-5.4185,0-2.1463,1.2756-4.0978,3.2491-4.9729.2285-.101.4978,0,.5988.2312.101.2285-.0018.4961-.2303.5979-1.6476.7299-2.7106,2.3563-2.7106,4.1438,0,2.4874,2.025,4.5114,4.5123,4.5114s4.5105-2.0241,4.5105-4.5114c0-1.7876-1.063-3.4139-2.7088-4.1438-.2285-.1019-.3313-.3694-.2303-.5988.101-.2303.3703-.3313.5988-.2303,1.9718.8752,3.2474,2.8275,3.2474,4.9729,0,2.9878-2.4307,5.4185-5.4176,5.4185Z" fill="#ff4c29" />
                    <path d="m9.0352,25.8735c-1.3447,0-2.4395-1.094-2.4395-2.4395v-11.3304c0-.2507.2037-.4535.4535-.4535s.4535.2029.4535.4535v11.3304c0,.8451.6874,1.5325,1.5325,1.5325s1.5325-.6874,1.5325-1.5325v-11.3304c0-.2507.2037-.4535.4535-.4535s.4535.2029.4535.4535v11.3304c0,1.3455-1.0949,2.4395-2.4395,2.4395Z" fill="#ff4c29" />
                    <path d="m9.0352,24.3836c-.5244,0-.9514-.4261-.9514-.9496,0-.5244.427-.9505.9514-.9505s.9496.4261.9496.9505c0,.5235-.4252.9496-.9496.9496Zm0-.993l.0425.0434c0-.0239-.0195-.0434-.0425-.0434Z" fill="#ff4c29" />
                    <path d="m20.9511,25.8735c-1.3447,0-2.4395-1.094-2.4395-2.4395v-7.4913h-.5403c-.2498,0-.4535-.2029-.4535-.4535v-1.986c0-.2507.2037-.4535.4535-.4535h5.9597c.2498,0,.4535.2029.4535.4535v1.986c0,.2507-.2037.4535-.4535.4535h-.5403v7.4913c0,1.3455-1.0949,2.4395-2.4395,2.4395Zm-2.5263-10.8379h.5403c.2498,0,.4535.2029.4535.4535v7.9448c0,.8451.6874,1.5325,1.5325,1.5325s1.5325-.6874,1.5325-1.5325v-7.9448c0-.2507.2037-.4535.4535-.4535h.5403v-1.0789h-5.0527v1.0789Z" fill="#ff4c29" />
                    <path d="m20.9511,13.9567c-.2498,0-.4535-.2029-.4535-.4535v-5.958c0-.2507.2037-.4535.4535-.4535s.4535.2029.4535.4535v5.958c0,.2507-.2037.4535-.4535.4535Z" fill="#ff4c29" />
                    <path d="m21.945,7.9987h-1.9878c-.1718,0-.3295-.0974-.4057-.2507l-.9921-1.986c-.0531-.1081-.062-.2321-.0248-.3464l.9921-2.979c.062-.1851.2356-.31.4305-.31h1.9878c.1949,0,.3685.1249.4305.31l.9921,2.979c.0372.1143.0283.2383-.0248.3464l-.9921,1.986c-.0762.1532-.2339.2507-.4057.2507Zm-1.7078-.9071h1.4279l.7831-1.567-.8309-2.4909h-1.3323l-.8309,2.4909.7831,1.567Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Logistics