import React from 'react'

const Safety = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m14.4592,25.0172c-.0987,0-.1973-.0314-.2798-.0951l-5.268-4.0515c-2.3237-2.2134-3.6358-5.3326-3.6358-8.589v-6.42c0-.5735.3435-1.0955.8556-1.2986,5.3676-2.1349,10.6257-2.1067,15.6274.0848.5246.1081.9659.6758.9659,1.3228v6.6559c0,3.0735-1.13,5.964-3.1829,8.1392l-4.7819,4.1393c-.0861.0744-.1928.1121-.3004.1121Zm-.4933-21.1097c-2.4672,0-4.9676.504-7.4957,1.5094-.1623.0646-.2762.2475-.2762.4448v6.42c0,3.0066,1.208,5.8832,3.3138,7.8926l4.9344,3.7914,4.4653-3.8622c1.8295-1.943,2.8986-4.6797,2.8986-7.4769v-6.6559c0-.2202-.139-.4112-.3309-.4538-2.4627-1.0744-4.9685-1.6094-7.5092-1.6094Z" fill="#ff4c29" />
                    <path d="m14,15.8193c-.1211,0-.2386-.048-.3247-.1345l-2.7551-2.7551c-.1794-.1794-.1794-.4699,0-.6493s.4699-.1794.6493,0l2.3587,2.3591,3.3631-5.0443c.1417-.2117.4287-.2673.6368-.1274.2108.1408.2682.4255.1274.6368l-3.6734,5.5102c-.0762.1143-.2.1888-.3372.2022-.0143.0013-.0296.0022-.0448.0022Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Safety