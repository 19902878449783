import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import './MapStyle.css'

// COMPONENT TO DISPLAY ON MAP
import { mapIconDisplayed } from '../Components/MapComponents/mapIconDisplayed'

// COMPONENTS
import SideBarRight from '../Components/MapComponents/SideBarRight/SideBarRight'

// REACT LIBRARY
import { useResizeDetector } from 'react-resize-detector';

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

// import classes from "./Map.module.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { mapStyles } from '../constants/mapStyles'
import { FirebaseDataContext } from '../Context/FirebaseDataContext';

import { mapInfoToDisplay } from '../constants/tableFilters'


mapboxgl.accessToken = mapStyles.accessToken;
var map = undefined;

const MapBox = props => {

    const data = useContext(FirebaseDataContext)
    const featureData = props.featureFilteredData;


    const { width, ref } = useResizeDetector();
    useEffect(() => {
        if (map && map.loaded() && map.isStyleLoaded())
            map.resize()
    }, [width])

    const mapContainer = useRef(null);
    const [mapIsLoaded, setMapIsLoaded] = useState(false);
    const [mapStyleChangeIncrement, setMapStyleChangeIncrement] = useState(0);
    const [displayTestToggle, setDisplayTestToggle] = useState(true)
    const [lng, setLng] = useState(0.4621);
    const [lat, setLat] = useState(51.1605);
    const [zoom, setZoom] = useState(8.5
    );

    useEffect(() => {
        // This will cause an error if the path is changed too early in the lifecycle, because the map wil not have initialized.


        if (mapIsLoaded) {


            if (map.isStyleLoaded()) {


                console.log(map.getStyle().layers.filter((layer) => layer.metadata && layer.metadata.pathLayer));
                map.getStyle().layers.filter((layer) => layer.metadata && layer.metadata.pathLayer).forEach((layer) => {
                    const filteredUids = props.featureFilteredData.features.map((feature) => {
                        return feature.id;
                    });


                    layer.metadata.relatedUids.forEach(id => {

                        if (filteredUids.includes(id)) {
                            map.setLayoutProperty(layer.id, "visibility", "visible");

                        } else {
                            map.setLayoutProperty(layer.id, "visibility", "none");
                        }
                    })
                    // console.log(layer.metadata.relatedUids, props.featureFilteredData, "map layers!")

                })
            }
        }

    }, [mapIsLoaded, mapStyleChangeIncrement, props.featureFilteredData]
    )
    const displayMapElements = () => {

        mapIconDisplayed(map, true, createPopUp, featureData, props.filteredData)
    }
    useEffect(() => {
        if (map)
            displayMapElements()
    }, [mapIsLoaded, props.featureFilteredData])

    useEffect(() => {
        // Initialize map when component mounts
        initializeMap()

        // Clean up on unmount
        return () => map.remove();
    }, [mapStyles.mapStyle]);

    const initializeMap = () => {
        map = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyles.mapStyle,
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false,
            minZoom: 7.5,
            maxZoom: 18,

        });

        // Controls
        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });


        map.on("load", () => {
            setMapIsLoaded(true)
            displayMapElements()
        })
        // disable map rotation using right click + drag
        map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        map.touchZoomRotate.disableRotation();

        /* Assign a unique ID to each signal */
        // combinedData.features.forEach(function (element, i) {
        //     element.properties.id = i;
        // });

        // ICON POSITIONING LAYERS
        const displayMapElements = () => {

            mapIconDisplayed(map, true, createPopUp, featureData, props.filteredData)
        }

        if (displayTestToggle) {
            displayMapElements()
        }


        map.addControl(new mapboxgl.NavigationControl(), "top-left");
    }

    const getCoordinatesFromGeometry = (geometry) => {
        const getItemTypesOfArray = (array) => {
            let types = [];
            array.forEach(item => {
                const itemType = Array.isArray(item) ? "array" : typeof item
                if (!types.includes(itemType))
                    types.push(itemType)
            })
            return types;
        }
        const coordsType = getItemTypesOfArray(geometry.coordinates)[0];
        switch (coordsType) {
            case "number":
                return geometry.coordinates
                break;
            case "array":
                const coordArray = geometry.coordinates;
                return coordArray[Math.round((coordArray.length - 1) / 2)]
            default:
                break;
        }
    }
    function flyToSignal(currentFeature) {

        map.flyTo({
            center: getCoordinatesFromGeometry(currentFeature.geometry),
            zoom: 17
        });
    }

    function createPopUp(currentFeature, propertyData) {

        const tableData = mapInfoToDisplay

        const base = currentFeature.properties.Structure;

        let tableDataKeys = [];
        let baseDefault = ''

        if (currentFeature !== undefined) {
            tableDataKeys = Object.keys(tableData[0]);
        } else {
            baseDefault = `<h5>${propertyData ? `${propertyData.name}: ${propertyData.value}` : `Base: ${base}`}</h5>`
            alert();
        }



        const popUps = document.getElementsByClassName('mapboxgl-popup');
        if (popUps[0]) popUps[0].remove();

        const popup = new mapboxgl.Popup({ closeOnClick: true })
            .setLngLat(getCoordinatesFromGeometry(currentFeature.geometry))
            .setHTML(`
            <h3>${currentFeature.properties.projectName}</h3>
            <div class="pop-title">
            <img  src=${currentFeature.properties.bullet} alt="bullet" > 
            <h4>${currentFeature.properties.workType}</h4>
            </div>
            ${tableData.map((item, index) => `<h5><b class="pop-sub-title">${item.displayedTitle}: </b> ${currentFeature.properties[item.keyProperty]}</h5>`).join("")}`

            )
            .addTo(map);
    }

    const toggleLayerVisibilityById = (clickedLayer, buttonElement) => {

        var visibility = map.getLayoutProperty(clickedLayer, "visibility");
        if (visibility === "visible") {
            map.setLayoutProperty(clickedLayer, "visibility", "none");
            buttonElement.className = "";
        } else {
            buttonElement.className = "active";
            map.setLayoutProperty(clickedLayer, "visibility", "visible");
        }
    }
    const displayProjectList = props.displayProjectList



    return (

        <div className='right-container-main'>

            <div className="map-main-container" ref={ref}>

                {/* KEEP FOR MAP BUILD******************** */}
                {/* <pre id="info"></pre> */}

                <div className="coord-bar">
                    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                </div>

                <div ref={mapContainer} className="map-container" />

            </div>
            <SideBarRight
                sidebarRightDisplayToggle
                fixMapSize={() => map.resize()} featureFilteredData={props.featureFilteredData} flyToSignal={flyToSignal} createPopUp={createPopUp}
                sideBarRightTitle={props.sideBarRightTitle}
            />
        </div>


    );
}

export default MapBox
