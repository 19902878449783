import React from 'react'

const Programme = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m13.6174,9.4082H5.1991c-.2115,0-.3826-.1711-.3826-.3826v-3.8265c0-.2115.1711-.3826.3826-.3826h8.4183c.2115,0,.3826.1711.3826.3826v3.8265c0,.2115-.1711.3826-.3826.3826Zm-8.0356-.7653h7.653v-3.0612h-7.653v3.0612Z" fill="#ff4c29" />
                    <path d="m17.8265,16.2959H5.1991c-.2115,0-.3826-.1711-.3826-.3826v-3.8265c0-.2115.1711-.3826.3826-.3826h12.6274c.2115,0,.3826.1711.3826.3826v3.8265c0,.2115-.1711.3826-.3826.3826Zm-12.2448-.7653h11.8621v-3.0612H5.5817v3.0612Z" fill="#ff4c29" />
                    <path d="m22.8009,23.1836H5.1991c-.2115,0-.3826-.1711-.3826-.3826v-3.8265c0-.2115.1711-.3826.3826-.3826h17.6018c.2115,0,.3826.1711.3826.3826v3.8265c0,.2115-.1711.3826-.3826.3826Zm-17.2192-.7653h16.8365v-3.0612H5.5817v3.0612Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Programme