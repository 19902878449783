import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserAuth } from '../Context/AuthContext';
import { FirebaseDataContext, FirebaseDataContextProvider } from '../Context/FirebaseDataContext';
import DataLoadedPage from './DataLoadedPage';

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();


  if (!user) {
    return <Navigate to='/' />;
  }
  //return children;
  return <>
    <FirebaseDataContextProvider>
      <Outlet></Outlet>
    </FirebaseDataContextProvider>
  </>;
};

export default ProtectedRoute;
