import React from 'react'

const AccessCalendar = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <g>
                        <path d="m23.3656,24.3132H4.6328c-.7686,0-1.3925-.6249-1.3925-1.3925V6.8633c0-.7677.6239-1.3925,1.3925-1.3925h18.7329c.7686,0,1.3945.6249,1.3945,1.3925v16.0573c0,.7677-.6259,1.3925-1.3945,1.3925ZM4.6328,6.4721c-.2151,0-.3912.175-.3912.3912v16.0573c0,.2161.176.3912.3912.3912h18.7329c.2132,0,.3931-.179.3931-.3912V6.8633c0-.2122-.1799-.3912-.3931-.3912H4.6328Z" fill="#ff4c29" />
                        <path d="m9.094,8.2558c-.2758,0-.5007-.2239-.5007-.5007v-3.5674c0-.2767.2249-.5007.5007-.5007s.5007.2239.5007.5007v3.5674c0,.2767-.2249.5007-.5007.5007Z" fill="#ff4c29" />
                        <path d="m18.9064,8.2558c-.2758,0-.5007-.2239-.5007-.5007v-3.5674c0-.2767.2249-.5007.5007-.5007s.5007.2239.5007.5007v3.5674c0,.2767-.2249.5007-.5007.5007Z" fill="#ff4c29" />
                        <path d="m24.2595,10.9324H3.7409c-.2758,0-.5007-.2239-.5007-.5007s.2249-.5007.5007-.5007h20.5185c.2758,0,.5007.2239.5007.5007s-.2249.5007-.5007.5007Z" fill="#ff4c29" />
                    </g>
                    <path d="m11.5251,19.6827h-4.4593c-.2758,0-.5007-.2239-.5007-.5007v-4.4603c0-.2767.2249-.5007.5007-.5007h4.4593c.2758,0,.5007.2239.5007.5007v4.4603c0,.2767-.2249.5007-.5007.5007Zm-3.9586-1.0014h3.4579v-3.4589h-3.4579v3.4589Z" fill="#ff4c29" />
                    <path d="m21.136,19.6827h-4.4593c-.2758,0-.5007-.2239-.5007-.5007v-4.4603c0-.2767.2249-.5007.5007-.5007h4.4593c.2758,0,.5007.2239.5007.5007v4.4603c0,.2767-.2249.5007-.5007.5007Zm-3.9586-1.0014h3.4579v-3.4589h-3.4579v3.4589Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default AccessCalendar