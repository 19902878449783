import React, { useContext, useEffect, useState } from 'react'

import { UserContext } from '../../Context/AuthContext'
import { btnWriteStatusComponentVisibility, FirebaseDataContext } from '../../Context/FirebaseDataContext';

import '../../CssGeneral/dashboard.css'

import { useNavigate } from 'react-router-dom'

import { cardIcons2 } from '../../constants/appStructure'
import TableNewContent from './TableNewContent';
import DbCardTable from '../../Components/cards/DbCardTable';


const TableNew = ({ filteredData }) => {

    const navigate = useNavigate()

    const data = useContext(FirebaseDataContext)
    const userData = useContext(UserContext)
    const [buttonStatus, setButtonStatus] = useState(true)

    // refresh


    useEffect(() => {
        btnWriteStatusComponentVisibility(userData, data, setButtonStatus)
    }, [])

    const searchParams = [{ name: "Project Name", key: "projectName" }, { name: "OP number", key: "OPNumber" }]

    const [searchQuery, setSearchQuery] = useState("")
    const [searchParam, setSearchParam] = useState(searchParams[0])

    const createRedirect = () => {
        navigate(`/app/main/form`)
    }

    const backRedirect = () => {
        navigate(`/app/main/summary`)
    }

    const onSearch = (e) => {

        setSearchQuery(e.target.value)
    }

    const onSearchParam = (e) => {
        setSearchQuery("")
        setSearchParam(searchParams[parseInt(e.target.value)])
    }

    return (
        <div className="dashboard-container">
            <DbCardTable
                cardType={'graph-card-wide card-height-min card-static'}
                icon={cardIcons2.actions}
                title={"Project Details"}
                topRowDisplayBool={true}
                cardContent={""}
                btnText={"Back"}
                mainBtnText={"Add"}
                btnDisplayBool={true}
                btnMainDisplayBool={buttonStatus}
                OnClickFuncMainBtn={createRedirect}
                OnClickFunc={backRedirect}
                onSearch={onSearch}
                filterDisplayBool={true}
                onSearchParam={onSearchParam}
                searchParams={searchParams}
                activeSearchParam={searchParam}
                searchQuery={searchQuery}
            >
                {filteredData.length > 0 ? <TableNewContent
                    filteredData={filteredData} searchQuery={searchQuery} searchParam={searchParam} /> : <div className="no-data-container summary-text-fs1p1 ">No data based on the selected filters!</div>}

            </DbCardTable>
        </div >
    )
}

export default TableNew