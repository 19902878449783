import React from 'react'

const Performance = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m7.1123,16.7551c.1348,0,.2686-.0592.3594-.1722l3.3857-4.2335,4.2334,3.3869c.1016.0807.2246.1152.3574.095.127-.0197.2402-.0919.3115-.1991l5.5098-8.2652c.1416-.2112.084-.496-.127-.6368-.208-.139-.4951-.0847-.6367.1274l-5.2324,7.8486-4.2002-3.3609c-.1963-.1574-.4854-.126-.6465.0718l-3.6729,4.5918c-.1582.1982-.127.4869.0713.6457.0859.0677.1855.1005.2871.1005Z" fill="#ff4c29" />
                    <path d="m24.5615,3.898H3.4395c-.2539,0-.46.2054-.46.4592s.2061.4592.46.4592h.459v13.3162c0,.7596.6182,1.3775,1.377,1.3775h6.3916l-3.0762,3.8456c-.1582.1982-.127.487.0713.6457.085.0677.1855.1005.2871.1005.1348,0,.2686-.0592.3584-.1722l3.5361-4.4196h2.3125l3.5361,4.4196c.0908.113.2246.1722.3584.1722.1006,0,.2021-.0327.2871-.1005.1982-.1587.2295-.4475.0723-.6457l-3.0771-3.8456h6.3916c.7598,0,1.3779-.6179,1.3779-1.3775V4.8164h.459c.2539,0,.459-.2054.459-.4592s-.2051-.4592-.459-.4592Zm-1.3779,14.2346c0,.2534-.2061.4592-.459.4592H5.2754c-.2529,0-.459-.2058-.459-.4592V4.8164h18.3672v13.3162Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Performance