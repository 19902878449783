import React from 'react'

const Location = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <path d="m13.9686,26.1304l-6.8174-11.797c-1.034-1.7895-1.4129-3.8973-1.0662-5.9361.3866-2.2842,1.8071-4.2486,3.7968-5.2542,2.7997-1.4144,5.8264-1.35,8.3116.178,2.41,1.4827,3.8475,4.0546,3.8475,6.8803,0,.2071-.0199.4487-.0552.7026-.158,1.172-.5676,2.3364-1.2165,3.4586l-6.8006,11.7679Zm.0107-23.1876c-1.238,0-2.5067.3083-3.7293.9258-1.7611.8898-3.0191,2.634-3.3642,4.6651h0c-.3145,1.8516.0307,3.7669.9711,5.3923l6.1118,10.5782,6.0949-10.5483c.5968-1.0309.9711-2.094,1.1153-3.1617.0291-.2148.0491-.418.0491-.5929,0-2.5412-1.2932-4.8538-3.4609-6.1869-1.1598-.7133-2.4545-1.0716-3.7876-1.0716Z" fill="#ff4c29" />
                    <path d="m13.691,11.6934c-1.2334,0-2.2367-1.0033-2.2367-2.2367s1.0033-2.2367,2.2367-2.2367,2.2367,1.0033,2.2367,2.2367-1.0033,2.2367-2.2367,2.2367Zm0-3.6603c-.7854,0-1.4236.6389-1.4236,1.4236s.6382,1.4236,1.4236,1.4236,1.4236-.6389,1.4236-1.4236-.6382-1.4236-1.4236-1.4236Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default Location