import { useState, useEffect } from 'react';
import Graph from "./Graph";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";



export default function GanttGraph(prop) {

    const [dateRange, setDateRange] = useState({ startTime: "", endTime: "" })


    // chart and ID are both coming from the graph.js
    const GanttGraphStyle = (chart, id, setHeight) => {
        am4core.options.autoSetClassName = true;

        const startTime = prop.startTime
        const endTime = prop.endTime
        let graph = am4core.create(id, am4charts.XYChart);
        graph.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        graph.data = prop.data
        graph.maskBullets = false;
        graph.paddingLeft = 0;



        // Set cell size in pixels
        let cellSize = 50;
        graph.events.on("datavalidated", function (ev) {

            // Get objects of interest
            let graph = ev.target;
            let categoryAxis = graph.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let adjustHeight = graph.data.length * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            let targetHeight = graph.pixelHeight + adjustHeight;

            // Set it on chart's container
            graph.svgContainer.htmlElement.style.height = targetHeight + "px";
        });


        var categoryAxis = graph.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "projectName";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeWidth = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.cellStartLocation = 0;
        categoryAxis.renderer.cellEndLocation = 1;
        categoryAxis.extraMin = 1;
        // categoryAxis.renderer.grid.template.disabled = true;
        // categoryAxis.renderer.line.strokeOpacity = 0.2;
        categoryAxis.id = "myCategoryAxis";

        var dateAxis = graph.xAxes.push(new am4charts.DateAxis());
        dateAxis.dateFormatter = new am4core.DateFormatter();
        dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
        dateAxis.renderer.minGridDistance = 50;
        // dateAxis.baseInterval = { count: 30, timeUnit: "day" };
        dateAxis.baseInterval = { count: 1, timeUnit: "month" };
        dateAxis.min = new Date(startTime).getTime();
        dateAxis.max = new Date(endTime).getTime();
        // dateAxis.strictMinMax = true;
        // dateAxis.extraMin = findMinimumDate(graph.data).getTime();
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.renderer.opposite = true;
        dateAxis.renderer.grid.template.strokeWidth = 0;


        var series1 = graph.series.push(new am4charts.ColumnSeries());
        series1.columns.template.tooltipText = "[#fff font-size: 20px font-weight:600] Commissioning Details \n[/] [#fff font-size: 18px font-weight:500] Date:[#fff font-size: 18px font-weight:300] {Commissioning Year} - P{Commissioning Period} ";
        series1.columns.template.tooltipX = am4core.percent(100);
        series1.columns.template.tooltipY = am4core.percent(50);
        series1.dataFields.openDateX = "fromDate";
        series1.dataFields.dateX = "Commissioning Date";
        series1.dataFields.categoryY = "projectName";
        series1.columns.template.strokeOpacity = 1;
        series1.columns.template.fillOpacity = 0.2;
        series1.columns.template.propertyFields.fill = "red";
        series1.columns.template.strokeOpacity = 0;
        // series1.columns.template.height = am4core.percent(100);
        series1.tooltip.background.cornerRadius = 20;
        series1.tooltip.background.strokeOpacity = 0;
        series1.tooltip.pointerOrientation = "horizontal";
        series1.tooltip.label.minWidth = 40;
        series1.tooltip.label.minHeight = 40;
        series1.tooltip.label.textAlign = "middle";
        series1.tooltip.label.TextValign = "middle";
        series1.tooltip.label.paddingLeft = "30"
        series1.tooltip.label.paddingRight = "30"
        series1.tooltip.label.paddingTop = "10"
        series1.tooltip.label.paddingBottom = "10"


        var columnTemplate = series1.columns.template;
        columnTemplate.propertyFields.fill = "color";
        columnTemplate.height = 15;
        columnTemplate = series1.columns.template;

        columnTemplate.column.cornerRadius(60, 60, 60, 60);

        columnTemplate.adapter.add("fill", (href, target) => {
            return target.dataItem.dataContext.color
        })

        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4core.color("#142127");
        // series1.tooltip.dx = 35;

        var bullet = columnTemplate.createChild(am4charts.CircleBullet);
        bullet.circle.radius = 0.05;
        // bullet.valign = "center";
        bullet.align = "right";
        bullet.isMeasured = true;
        bullet.mouseEnabled = false;
        bullet.verticalCenter = "center";
        bullet.valign = "middle"

        var bulletIcon = bullet.createChild(am4core.Image)
        bulletIcon.width = 45;
        bulletIcon.height = 45;

        bulletIcon.verticalCenter = "middle";
        bulletIcon.horizontalCenter = "middle"
        bulletIcon.adapter.add("href", (href, target) => {
            if (target.dataItem.dataContext.bullet)
                return target.dataItem.dataContext.bullet;

        })

        // graph.scrollbarX = new am4core.Scrollbar();
        // graph.scrollbarY = new am4core.Scrollbar();
        // graph.scrollbarX.id = "myScrollbar"

        graph.current = graph;
        return graph;
    }

    return (
        <Graph applyStyle={GanttGraphStyle} data={prop.data} graphRefresh={prop.graphRefresh} />
    )
}