
import _ from "lodash";
var mapLoaded = false;



const visibleByRelatedPathname = (relatedPathnames, pathname) => {

    return relatedPathnames.includes(pathname) ? "visible" : "none"
}
function addLayerElements(configData) {
    const { data, tableValues, source, imageSource, imageName, map, isVisible, createPopUp, popupProperty1Key, minZoom, maxZoom, iconSize, relatedPathNames, rotation, text, textOffset, paint, type, currentPathName, filteredData, isGroupVisible } = configData
        ;
    const sources = Object.keys(map.getStyle().sources)

    if (sources.includes(source)) {
        map.removeLayer(source)

        map.removeSource(source)

    }

    map.addSource(source, {
        'type': 'geojson',
        'data': data,
        buffer: 0,
    });

    map.loadImage(
        imageSource,
        (error, image) => {
            if (error) throw error;

            map.addImage(imageName, image);
            map.addLayer({
                id: source,
                type: type,
                source: source,
                "minzoom": minZoom,
                "maxzoom": maxZoom,
                // For ones not using map symbol use an array instead
                // of relatedPathNames
                metadata: { pathLayer: true, relatedPathNames, relatedUids: data.features.map((feature) => feature.id) },
                layout: {
                    visibility: "visible",

                    "icon-image": imageName,
                    "icon-size": iconSize,
                    'text-offset': ['get', 'text-offset'],
                    // "text-field": ['get', 'Name'],
                    'icon-rotate': rotation,
                    // 'icon-rotate': iconRotation,
                    'text-anchor': 'top',
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true,
                    "text-field": text,
                    'text-offset': textOffset,
                },
                paint: paint
            });

            map.on("click", source, (event) => {

                const pathName = window.location.pathname;
                // const dataTest = findPathnameData(pathName);
                let propertyValue = event.features[0].properties[popupProperty1Key];

                let propertyName = popupProperty1Key ? (popupProperty1Key[0].toUpperCase() + popupProperty1Key.slice(1)) : "Base";
                const propsArgument = { name: propertyName, value: propertyValue }

                // let popUpTableData = {}
                // let tablePopUpKeyTitles = data.tableData.filter((tableKey, index) => tableKey.popUpDisplay)
                // tablePopUpKeyTitles.forEach((tableKeyTitleData, index) => {


                //     popUpTableData[tableKeyTitleData.Title] = event.features[0]["properties"][tableKeyTitleData.property]
                // })

                createPopUp(event.features[0], popupProperty1Key ? propsArgument : undefined);
                // This data passed into create popup needs to be channeled into state further up the component hierarchy, this will allow both mapsymbol and sidebarright to use the same createpopup function for the datas group. Dont forget you can store the popups themselves (along with the markers) in state.
            })
        });
}
export const MapSymbol = (configData) => {
    const { data, source, imageSource, imageName, map, isVisible, createPopUp, minZoom, maxZoom, iconSize, rotation, text, textOffset, paint, type } = configData

    map.on('load', () => addLayerElements(configData))

    if (map.loaded() && map.isStyleLoaded()) {
        addLayerElements(configData)
    }

}
