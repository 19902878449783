

// Map styles for the different mapbox view styles
export const mapStyles = {
    accessToken: process.env.REACT_APP_MAPBOX_API,
    mapStyle: process.env.REACT_APP_MAPBOX_STYLE,

}






