import React from 'react'

const MilestoneStatus = ({ fill }) => {
    return (
        <svg id="CardHeaders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <g id="Content">
                <g>
                    <rect x="12.4087" y="11.7908" width="6.7409" height=".8589" transform="translate(-4.0194 14.7368) rotate(-45)" fill="#ff4c29" />
                    <path d="m21.201,10.2665h-3.468v-3.4671l4.6726-4.6726v3.4671h3.468l-4.6726,4.6726Zm-2.6089-.859h2.2533l2.9546-2.9546h-2.2533v-2.2524l-2.9546,2.9546v2.2524Z" fill="#ff4c29" />
                    <path d="m13.3959,25.52c-6.0182,0-10.9156-4.8974-10.9156-10.9165S7.3777,3.687,13.3959,3.687v.859c-5.5451,0-10.0566,4.5115-10.0566,10.0574s4.5115,10.0574,10.0566,10.0574,10.0583-4.5115,10.0583-10.0574h.859c0,6.019-4.8974,10.9165-10.9173,10.9165Z" fill="#ff4c29" />
                    <path d="m13.3959,25.52c-1.4731,0-2.9026-.2886-4.2498-.8582-1.3003-.5503-2.4663-1.3372-3.468-2.3397-1.0033-1.0025-1.7902-2.1694-2.3405-3.4696-.5688-1.3473-.8573-2.7767-.8573-4.249s.2886-2.9017.8573-4.249c.5503-1.3003,1.3372-2.4672,2.3405-3.4696,1.0016-1.0025,2.1677-1.7894,3.468-2.3397,2.6945-1.1392,5.8051-1.1392,8.4996,0l-.3356.7919c-2.4798-1.0503-5.3488-1.0503-7.8285,0-1.1979.5059-2.2734,1.2315-3.1962,2.1551-.9245.9245-1.6509,1.9991-2.1559,3.1962-.5251,1.2407-.7902,2.5578-.7902,3.9151s.2651,2.6744.7902,3.9151c.505,1.1971,1.2315,2.2717,2.1559,3.1962.9228.9236,1.9982,1.6493,3.1962,2.1551,2.4798,1.0503,5.3488,1.0503,7.8285,0,1.1963-.505,2.2717-1.2298,3.1978-2.1551.9245-.9253,1.6493-2.0008,2.1543-3.1962.5251-1.2399.7919-2.5569.7919-3.9151s-.2668-2.6752-.7919-3.9143l.7919-.3356c.5704,1.3456.859,2.7759.859,4.2498s-.2886,2.9042-.859,4.2498c-.5486,1.2978-1.3355,2.4655-2.3388,3.4688-1.005,1.0042-2.1727,1.791-3.4696,2.3397-1.3473.5696-2.7767.8582-4.2498.8582Z" fill="#ff4c29" />
                    <path d="m13.3959,20.2761c-.7651,0-1.5066-.1502-2.208-.4455-.6745-.2852-1.2818-.6946-1.8036-1.2156-.5218-.5235-.9295-1.13-1.2147-1.8036-.2953-.7013-.4463-1.4446-.4463-2.208s.151-1.5066.4463-2.208c.2852-.6736.6929-1.2801,1.2147-1.8028.5218-.5218,1.1291-.9312,1.8036-1.2164,1.4026-.5906,3.015-.5906,4.4176,0l-.3356.7919c-1.1879-.5008-2.5586-.5025-3.7465,0-.5738.2416-1.0889.5889-1.5318,1.031-.4429.4438-.7886.9589-1.0302,1.5301-.2517.5956-.3792,1.2256-.3792,1.8741s.1275,1.2785.3792,1.8741c.2416.5713.5872,1.0864,1.0302,1.531.4429.4413.958.7886,1.5318,1.0302,1.1879.5025,2.5586.5008,3.7465,0,.5721-.2408,1.0855-.5881,1.5301-1.031.4429-.4429.7902-.958,1.0318-1.5301.25-.5923.3775-1.2231.3775-1.8741s-.1275-1.2818-.3775-1.8741l.7919-.3339c.2953.6988.4446,1.4412.4446,2.208s-.1493,1.5092-.4446,2.208c-.2852.6753-.6946,1.2818-1.2164,1.8036-.5235.5218-1.1291.9312-1.8019,1.2156-.7013.2953-1.4446.4455-2.2096.4455Z" fill="#ff4c29" />
                </g>
            </g>
        </svg>
    )
}

export default MilestoneStatus