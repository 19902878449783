import React from "react";
import { useState, useRef, useEffect } from 'react';



import { mapStyles } from '../../constants/mapStyles'

// import '../../pageStyles/MapBox/Map.css'

import '../../pages/form.css'

// MAPBOX LIBRARY
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";


const FormMap = (props) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(props.lng);
    const [lat, setLat] = useState(props.lat);
    const coordinates = [lng, lat];
    const [zoom, setZoom] = useState(props.zoom);

    let marker = null;

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyles.mapStyle,
            center: coordinates,
            zoom: zoom,
            preserveDrawingBuffer: true

        });

        marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)

        map.current.on("click", ({ lngLat }) => {
            const { lng, lat } = lngLat;
            if (marker != null) {
                marker.remove();
            }
            marker = new mapboxgl.Marker().setLngLat({ lng, lat }).addTo(map.current)
            props.handleLngLatMapBox(lngLat)
        })
    }, []);

    // useEffect(() => {
    //     if (map.current) {
    //         // map.current.flyTo({
    //         //     center: coordinates,
    //         //     zoom: 16
    //         // })
    //         //createPopUp()
    //     }
    //     setLng(coordinates[0])
    //     setLat(coordinates[1])


    // }, [map.current]);

    return (
        <div className="map-main-small" >
            <div ref={mapContainer} className="map-container-small" />
        </div>
    );

}

export default FormMap;