import React, { useEffect, useState, useRef } from 'react'

import '../../../Components/Sidebar/Sidebar.css'

export default function CheckboxList({ listData, tickedItems, setTickedItems, listTitle, buttonImage, toggleMenuItem, toggleSidebarWidth }) {

    // CONTROLS BUTTON DISPLAY
    const menuRef = useRef(null);




    const handleTitleButtonClick = () => {
        toggleMenuItem(menuRef)
        toggleSidebarWidth()
    }
    // CONTROLS CHECK BOXES
    const handleCheckboxes = event => {
        let updatedTickedItems = [...tickedItems]
        const tickedItem = event.target.value;
        const isItemTicked = tickedItems.includes(tickedItem)
        if (!isItemTicked) {
            updatedTickedItems.push(tickedItem)
        } else {
            const tickedItemIndex = updatedTickedItems.indexOf(tickedItem);
            updatedTickedItems.splice(tickedItemIndex, 1)
        }
        setTickedItems(updatedTickedItems);
    }

    const checkAllItems = () => {
        let updatedTickedItems = [...tickedItems];
        listData.forEach((item) => {
            updatedTickedItems.push(item.name)
        });
        setTickedItems(updatedTickedItems)
    }
    const uncheckAllItems = () => setTickedItems([]);

    useEffect(() => {
        checkAllItems();
    }, [])

    return (
        <div ref={menuRef}>
            <button className={`sidebar-btn`} onClick={handleTitleButtonClick}> <img className='btn-icon' src={buttonImage} alt="" /> <h4>{listTitle}</h4>
            </button>
            <div className={`list-container-hide`}>
                {
                    listData.map((listItemName, index) => {
                        return (

                            <div className="list-item-container">

                                <label key={index}>
                                    <input key={"input" + index} type="checkbox" value={listItemName.name}
                                        className="orange-accent" onChange={handleCheckboxes} checked={tickedItems.includes(listItemName.name)} style={{ marginRight: 10 }}
                                        name="checkbox"
                                    >

                                    </input>
                                    {listItemName.name}
                                </label>
                            </div>
                        )
                    })
                }
                <div className='btn-group-container'>
                    <button className='btn-check' onClick={checkAllItems}><p>Check all</p></button>
                    <button className='btn-check' onClick={uncheckAllItems}>Clear all</button>
                </div>

            </div>
        </div>
    )
}
